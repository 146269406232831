import isIP from 'validator/es/lib/isIP'
import isURL from 'validator/es/lib/isURL'
import isFQDN from 'validator/es/lib/isFQDN'
import isCidr from 'is-cidr'

import { format as dateFormat, formatISO, parseISO } from 'date-fns'
import { camelCase, isEmpty, isNil, kebabCase, lowerCase, omitBy, snakeCase, startCase, upperCase } from 'lodash'
import { format as dateFormatUtc, utcToZonedTime } from 'date-fns-tz'

// https://date-fns.org/v2.29.3/docs/format
// https://medium.com/@robertsavian/javascript-case-converters-using-lodash-4f2f964091cc

const base = 1000
// const base = 1024
export const helpersObject = {
    cleanObject: (value) => {
        // console.log('helpers cleanObject', value)
        const retVal = omitBy(value, isNil)
        // console.log('helpers cleanObject retVal', retVal)
        return retVal
    },

    formatDatetimeUtc: (value, format = 'MM/dd/yy, hh:mm a UTC') => {
        // Pp === 04/29/1453, 12:00 AM
        // MM/dd hh:mm a === 01/08 01:09 PM
        // MM/dd/yy, hh:mm a === 01/03/23, 02:02 PM
        if (!value) return null

        const datetime = parseISO(value)
        // console.log('formatDatetime datetime', datetime)
        const formatInTimeZone = (date, fmt, tz) =>
            dateFormatUtc(utcToZonedTime(date, tz),
                fmt,
                { timeZone: tz })

        const formattedTime = formatInTimeZone(datetime, 'MM/dd/yy, hh:mm a \'UTC\'', 'UTC')

        return formattedTime
    },
    formatDatetime: (value, format = 'MM/dd/yy, hh:mm a') => {
        // Pp === 04/29/1453, 12:00 AM
        // MM/dd hh:mm a === 01/08 01:09 PM
        // MM/dd/yy, hh:mm a === 01/03/23, 02:02 PM
        if (!value) return null

        const datetime = parseISO(value)
        // console.log('formatDatetime datetime', datetime)
        let retVal = dateFormat(datetime, format)
        return retVal
    },

    formatCurrency: (value) => {
        let dollars = value / 100
        return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    },
    arrayToColumns: (sourceArray, columnCount = 3) => {
        // console.log('helpers arrayToColumns sourceArray', sourceArray)
        // console.log('helpers arrayToColumns columnCount', columnCount)

        if (!sourceArray) return undefined

        const arrayLength = sourceArray.length
        // const columnCount = 3

        let columnsArray = []

        for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
            let columnArray = []
            for (let itemIndex = columnIndex; itemIndex < arrayLength; itemIndex = itemIndex + columnCount) {
                columnArray.push(sourceArray[itemIndex])
            }
            columnsArray.push(columnArray)
        }

        // console.log('arrayToColumns columnsArray', columnsArray)
        return columnsArray

    },
    colorForPercent: (value) => {
        if (!value) return ''

        let retVal
        if (value >= 90) {
            retVal = 'danger lighten-1'
        } else if (value >= 50) {
            retVal = 'primary lighten-1'
        } else if (value === 0) {
            retVal = 'danger lighten-1'
        } else {
            retVal = 'success lighten-1'
        }
        return retVal
    },
    colorForPercentFlip: (value) => {
        if (!value) return ''

        let retVal
        switch (true) {
            /*
             case 100:
             retVal = 'success lighten-1'
             break
             */
            case value <= 25:
                retVal = 'danger lighten-1'
                break
            case value <= 50:
                retVal = 'info lighten-1'
                break
            case value <= 75:
                retVal = 'orange lighten-1'
                break
            default:
                retVal = 'success lighten-1'
        }
        // console.log('colorForPercentFlip retVal', retVal)
        return retVal
    },


    todayString: (format = 'MM/dd/yy') => {
        let todayStringIso = formatISO(new Date())
        console.log('todayString todayStringIso', todayStringIso)
        let retVal = helpersObject.formatDatetime(todayStringIso, format)
        console.log('todayString retVal', retVal)

        return retVal
    },
    nowIso: () => {
        return formatISO(new Date())
    },

    _formatBitsBytes: (type, value, precision = 2) => {
        // if (!value) return value
        if (!isFinite(value)) return value

        const labels = {
            bits: [ 'bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps' ],
            bytes: [ 'Bps', 'KBps', 'MBps', 'GBps', 'TBps', 'PBps', 'EBps', 'ZBps', 'YBps' ],
        }

        if (value === 0) {
            return `0 ${ labels[type][0] }`
        } else if (value < 1) {
            return `${ value.toFixed(precision) } ${ labels[type][0] }`
        }
        const exponent = Math.floor(Math.log(value) / Math.log(base))
        const returnValue = parseFloat((value / Math.pow(base, exponent)).toFixed(precision))
        const returnLabel = labels[type][exponent]
        return `${ returnValue } ${ returnLabel }`
    },
    formatBits: (bits, precision = 2) => {
        return helpersObject._formatBitsBytes('bits', bits, precision)
    },
    formatBytes: (bytes, precision = 2) => {
        return helpersObject._formatBitsBytes('bytes', bytes, precision)
    },
    formatMegaBits: (bits, precision) => {
        if (!isFinite(bits)) return bits
        bits = bits * base * base
        return helpersObject.formatBits(bits, precision)
    },
    formatMegaBytes: (bytes, precision) => {
        if (!isFinite(bytes)) return bytes

        bytes = bytes * base * base
        return helpersObject.formatBytes(bytes, precision)
    },
    formatGigaBits: (bits, precision) => {
        if (!isFinite(bits)) return bits

        bits = bits * base * base * base
        return helpersObject.formatBits(bits, precision)
    },
    formatGigaBytes: (bytes, precision) => {
        if (!isFinite(bytes)) return bytes

        bytes = bytes * base * base * base
        return helpersObject.formatBytes(bytes, precision)
    },




    isFinite: (value) => {
        return isFinite(value)
    },
    isNaN: (value) => {
        return isNaN(value)
    },
    isEmpty: (value) => {
        return isEmpty(value)
    },

    urlCase: (value) => {
        if (!value) return ''
        value = lowerCase(value)
        value = kebabCase(value)
        return value
    },

    camelCase: (value) => {
        if (!value) return ''
        return camelCase(value)
    },
    pascalCase: (value) => {
        if (!value) return ''
        return startCase(camelCase(value)).replace(/ /g, '')
    },
    constantCase: (value) => {
        if (!value) return ''
        return upperCase(value).replace(/ /g, '_')
    },
    dotCase: (value) => {
        if (!value) return ''
        return lowerCase(value).replace(/ /g, '.')
    },
    kebabCase: (value) => {
        if (!value) return ''
        return kebabCase(value)
    },
    pathCase: (value) => {
        if (!value) return ''
        return lowerCase(value).replace(/ /g, '/')
    },
    snakeCase: (value) => {
        if (!value) return ''
        return snakeCase(value)
    },
    startCase: (value) => {
        if (!value) return ''
        return startCase(value)
    },
    // replaced by startCase from lodash
    /*
     capitalizeString: (value) => {
     if (!value) return ''

     let retVal = value.split(' ').map(
     word => word.charAt(0).toUpperCase() + word.slice(1),
     ).join(' ')
     return retVal
     },
     */
    titleCase: (value) => {
        let i, j, str, lowers, uppers
        str = value.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })

        // Certain minor words should be left lowercase unless
        // they are the first or last words in the string
        lowers = [ 'A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
            'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With' ]
        for (i = 0, j = lowers.length; i < j; i++) {
            str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
                function(txt) {
                    return txt.toLowerCase()
                })
        }

        // Certain words such as initialisms or acronyms should be left uppercase
        // uppers = ['Id', 'Tv', 'Asn']
        // for (i = 0, j = uppers.length; i < j; i++) {
        //   str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
        //     function(txt) {
        //       return txt.toUpperCase()
        //     })
        // }
        uppers = [ 'Id', 'Tv', 'Asn' ]
        for (i = 0, j = uppers.length; i < j; i++) {
            str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
                uppers[i].toUpperCase())
            return str
        }
    },

    truncate: (value, length = 24) => {
        // console.log('filters datetime', value)
        if (!value) return '-'

        if (value.length > length) {
            return value.substring(0, length) + '…'
        }
        return value
    },
    isIP: (value) => {
        if (!value) return false

        let retVal = isIP(value)
        return retVal
    },
    isIpv4Cidr: (value) => {
        if (!value) return false

        let retVal = isCidr.v4(value)
        return retVal
    },
    isURL: (value, options) => {
        if (!value) return false

        let retVal = isURL(value, options)
        return retVal
    },
    isFQDN: (value) => {
        if (!value) return false

        let retVal = isFQDN(value)
        return retVal
    },
}

export default helpersObject
