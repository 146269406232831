// import { round } from 'lodash'
// import helpers from '@/helpers/helpers.js'

export const ReportLookup = [
    {
        default: true,
        // Allowed/Blocked reasons
        uuid: '80c65bdc-d4d1-4d76-a171-31edc5f9f848',

        component: 'allowed-blocked-reasons',
        props: {
            context: 'reasons',
            discriminators: [ 'instance', 'policy' ],
        },

        report: 'allowed-blocked',
        subreport: 'reasons',
        title: 'Allowed / Blocked',
        subtitle: 'Reason Summary',
        display: 'Allowed / Blocked: Reason Summary',
        color: 'green darken-3 white--text',
        discriminators: [
            'instance',
            'policy',
        ],
    },
    {
        // Allowed/Blocked categories
        uuid: 'd2348977-1408-408e-9993-5d877c3baf52',

        component: 'allowed-blocked-categories',
        props: {
            context: 'categories',
            discriminators: [ 'instance', 'policy' ],
        },

        report: 'allowed-blocked',
        subreport: 'categories',
        title: 'Allowed / Blocked',
        subtitle: 'Category Summary',
        display: 'Allowed / Blocked: Category Summary',
        color: 'green darken-1 white--text',
        discriminators: [
            'instance',
            'policy',
        ],
    },
    {
        // Allowed/Blocked countries
        uuid: '7fccf964-8039-46af-a662-392504d15d83',

        component: 'allowed-blocked-countries',
        props: {
            context: 'countries',
            discriminators: [ 'instance', 'policy' ],
        },

        report: 'allowed-blocked',
        subreport: 'countries',
        title: 'Allowed / Blocked',
        subtitle: 'Top 10 Countries',
        display: 'Allowed / Blocked: Top 10 Countries',
        color: 'green lighten-2 white--text',
        discriminators: [
            'instance',
            'policy',
        ],
    },
    {
        // Allowed/Blocked asns
        uuid: '6a382b48-b0ed-4507-843f-f75b86329ff7',

        component: 'allowed-blocked-asns',
        props: {
            context: 'asns',
            discriminators: [ 'instance', 'policy' ],
        },

        report: 'allowed-blocked',
        subreport: 'asns',
        title: 'Allowed / Blocked',
        subtitle: 'Top 10 ASNs',
        display: 'Allowed / Blocked: Top 10 ASNs',
        color: 'green lighten-5 white--text',
        discriminators: [
            'instance',
            'policy',
        ],
    },
    {
        // Country Top Ten
        uuid: '1ceb27bc-4f58-4ef1-bd58-b88b929f5b53',

        component: 'top-10',
        props: {
            context: 'countries',
            discriminators: [ 'instance' ],
        },

        report: 'top-10',
        subreport: 'countries',
        title: 'Top 10',
        subtitle: 'Countries by Threat Category',
        display: 'Top 10: Countries by Threat Category',
        color: 'gray lighten-2 black--text',
        discriminators: [
            'instance',
            'threatCategories',
        ],
    },
    {
        // ASN Top Ten
        uuid: 'a08fe3a2-fd69-4e10-875a-b2eeee853b74',

        component: 'top-10',
        props: {
            context: 'asns',
            discriminators: [ 'instance' ],
        },

        report: 'top-10',
        subreport: 'asns',
        title: 'Top 10',
        subtitle: 'ASNs by Threat Category',
        display: 'Top 10: ASNs by Threat Category',
        color: 'slate lighten-3 white--text',
        discriminators: [
            'instance',
            'threatCategories',
        ],
    },
]

export default ReportLookup
