import Vue from 'vue'

import BSPrint from '@/components/BSPrint.vue'
import BSTableOld from '@/components/BSTable.vue'
import BSTable from '@/components/BSTableNew.vue'
import BSViewTitle from '@/components/BSViewTitle.vue'
import BSGlyphButton from '@/components/form_fields/BSGlyphButton.vue'
import BsTooltip from '@/components/BsTooltip.vue'

Vue.component('bs-print', BSPrint)

Vue.component('bs-table-old', BSTableOld)
Vue.component('bs-table', BSTable)

Vue.component('bs-view-title', BSViewTitle)
Vue.component('bs-glyph-button', BSGlyphButton)

Vue.component('bs-tooltip', BsTooltip)
