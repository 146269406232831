<!--
  - Copyright ThreatBlockr Inc 2023-2024
  - ejohnson created
  -->

<!--TODO: https://github.com/vuetifyjs/vuetify/issues/11600#issuecomment-642273351-->
<template>
  <div class="bs-table-container"
       :class="{ 'debug-new': debug }">

    <v-row v-if="debug && computedIsDevelopment">
      <v-col>
        <bs-print :value="{ localDiscriminators }" :deep="3"/>
        <bs-print :value="{ discriminators }" :deep="3"/>
        <!--<bs-print :value="{ sticky }"/>-->
      </v-col>
      <v-col>
        <!--
        <bs-print :value="{
          paginationProps: paginationProps,
          defaultPagination: defaultPagination,
          computedDefaultPagination: computedDefaultPagination,
          filteredPagination: filteredPagination,
          }" :deep="1"/>
        -->
      </v-col>
      <v-col>
        <bs-print :value="{$scopedSlots}" :deep="1"/>
        <bs-print :value="{$props}" :deep="1"/>

        <!--<bs-print :value="{$slots}" :deep="1"/>-->
        <!--crashes json pretty-->

      </v-col>
      <v-col>
        <bs-print :value="{ selectedTableItems }"/>
        <bs-print :value="{computedTableItems}" :deep="1"/>
        <!--crashes json pretty-->
      </v-col>

    </v-row>

    <v-data-table v-model="selectedTableItems"
                  ref="bsTable"
                  :items="computedTableItems"
                  :item-key="itemKey"
                  :loading="loading"
                  :headers="computedHeaders"
                  :search="computedDefaultPagination.search"
                  :show-expand="showExpand"
                  :show-select="showSelect"
                  :disable-pagination="disablePagination"
                  checkbox-color="blue"
                  expand-icon="mdi-chevron-right"
                  :class="computedTableClasses"

                  :group-by="groupBy"
                  :group-desc="groupDesc"

                  :header-props="headerProps"

                  :items-per-page.sync="computedDefaultPagination.perPage"

                  :page.sync="computedDefaultPagination.page"
                  :sort-by.sync="computedDefaultPagination.sortBy"
                  :sort-desc.sync="computedDefaultPagination.sortDesc"

                  :hide-default-footer="true"
                  :hide-default-header="true"

                  v-bind="$attrs"
                  v-on="$listeners"

                  @pagination="eventHandlerPagination"
                  @page-count="eventHandlerPageCount"


                  @update:sort-by="eventHandlerSort($event, 'sortBy')"
                  @update:sort-desc="eventHandlerSort($event, 'sortDesc')"
                  @update:options="eventHandlerOptions"
    >

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-slot:top>
        <!--==============================-->
        <!--==============================-->
        <slot v-if="showHeader === true"
              name="top">

          <div class="header-content d-flex flex-column">

            <div v-if="hasContentForHeaderTop"
                 class="header-top d-flex flex-gap-4"
                 :class="bsHeaderProps.class.header.top"
                 style="">
              <div v-if="hasContentForHeaderTopLeft"
                   class="top-left d-flex align-center flex-gap-2 flex-grow-1">
                <div v-if="title"
                     class="d-flex flex-gap-2 align-baseline">
                  <!--==============================-->
                  <!--==============================-->
                  <slot name="title">
                    <div v-if="title"
                         class="title"
                         :class="bsHeaderProps.class.title">{{ title }}
                    </div>
                  </slot>
                  <!--==============================-->
                  <!--==============================-->

                  <!--==============================-->
                  <!--==============================-->
                  <slot name="subtitle">
                    <div v-if="paginationString.length"
                         class="subtitle text-subtitle-1 gray--text nowrap">({{ paginationString }})
                    </div>
                  </slot>
                  <!--==============================-->
                  <!--==============================-->
                </div>
                <!--==============================-->
                <!--==============================-->
                <slot name="topLeft"></slot>
                <!--==============================-->
                <!--==============================-->

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators topLeft -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'topLeft'">
                  <slot v-for="(header, index) in computedDiscriminableHeaders"
                        :name="header.value">
                    <!--todo v-on="$listeners" causes selectedTableItems to become null-->
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"

                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop="">

                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.selection`)"
                                v-slot:selection="{ parent, item, index, select, selected, disabled }">
                        <slot :name="`discriminator.${header.value}.selection`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          index: number,
                          select: function,
                          selected: boolean,
                          disabled: boolean
                        }
                        -->
                      </template>
                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.item`)"
                                v-slot:item="{ parent, item, on, attrs }">
                        <slot :name="`discriminator.${header.value}.item`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          on: object // Only needed when providing your own v-list-item,
                          attrs: object // Only needed when providing your own v-list-item
                        }
                        -->
                      </template>

                    </bs-table-unique-select>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"
                                              :key="index"
                                              :disabled="loading || items.length === 0"

                                              :items="header._items || computedTableItems"
                                              :datetime-property="header.value"

                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"
                                              :clearable="true"
                                              :sticky="false"
                                              :text-field-props="{ dense: true }"


                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              v-on:click.native.stop=""/>
                  </slot>

                  <bs-discriminator-reset v-if="computedDiscriminableHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->
                <bs-filter-textfield v-if="search === 'topLeft'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

              </div>
              <div v-if="hasContentForHeaderTopRight"
                   class="top-right d-flex align-center justify-end flex-grow-1 flex-gap-4">
                <bs-filter-textfield v-if="search === 'topRight'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators topRight -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'topRight'">
                  <slot v-for="(header, index) in computedDiscriminableHeaders"
                        :name="header.value">
                    <!--todo v-on="$listeners" causes selectedTableItems to become null-->
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop="">

                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.selection`)"
                                v-slot:selection="{ parent, item, index, select, selected, disabled }">
                        <slot :name="`discriminator.${header.value}.selection`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          index: number,
                          select: function,
                          selected: boolean,
                          disabled: boolean
                        }
                        -->
                      </template>
                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.item`)"
                                v-slot:item="{ parent, item, on, attrs }">
                        <slot :name="`discriminator.${header.value}.item`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          on: object // Only needed when providing your own v-list-item,
                          attrs: object // Only needed when providing your own v-list-item
                        }
                        -->
                      </template>

                    </bs-table-unique-select>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"

                                              :disabled="loading || items.length === 0"
                                              :items="header._items || computedTableItems"
                                              :datetime-property="header.value"

                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"
                                              :clearable="true"
                                              :sticky="false"
                                              :text-field-props="{ dense: true }"


                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              v-on:click.native.stop=""/>
                  </slot>

                  <bs-discriminator-reset v-if="computedDiscriminableHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->

                <!--==============================-->
                <!--==============================-->
                <slot name="topRight"></slot>
                <!--==============================-->
                <!--==============================-->
              </div>
            </div>

            <div v-if="hasContentForHeaderBottom"
                 class="header-bottom d-flex flex-gap-4"
                 :class="bsHeaderProps.class.header.bottom"
                 style="">
              <div v-if="hasContentForHeaderBottomLeft"
                   class="bottom-left d-flex flex-gap-4 align-center flex-grow-1">
                <!--==============================-->
                <!--==============================-->
                <slot name="bottomLeft"></slot>
                <!--==============================-->
                <!--==============================-->

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators bottomLeft -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'bottomLeft'">
                  <slot v-for="(header, index) in computedDiscriminableHeaders"
                        :name="header.value">

                    <!--todo v-on="$listeners" causes selectedTableItems to become null-->
                    <!--v-bind="$attrs"-->

                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop="">

                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.selection`)"
                                v-slot:selection="{ parent, item, index, select, selected, disabled }">
                        <slot :name="`discriminator.${header.value}.selection`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          index: number,
                          select: function,
                          selected: boolean,
                          disabled: boolean
                        }
                        -->
                      </template>
                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.item`)"
                                v-slot:item="{ parent, item, on, attrs }">
                        <slot :name="`discriminator.${header.value}.item`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          on: object // Only needed when providing your own v-list-item,
                          attrs: object // Only needed when providing your own v-list-item
                        }
                        -->
                      </template>

                    </bs-table-unique-select>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"

                                              :disabled="loading || items.length === 0"
                                              :items="header._items || computedTableItems"
                                              :datetime-property="header.value"

                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"
                                              :clearable="true"
                                              :sticky="false"
                                              :text-field-props="{ dense: true }"


                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              v-on:click.native.stop=""/>

                  </slot>

                  <bs-discriminator-reset v-if="computedDiscriminableHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->
                <bs-filter-textfield v-if="search === 'bottomLeft'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""/>

              </div>
              <div v-if="hasContentForHeaderBottomRight"
                   class="bottom-right d-flex align-center justify-end flex-grow-1 flex-gap-4">
                <bs-filter-textfield v-if="search === 'bottomRight'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators bottomRight -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'bottomRight'">
                  <slot v-for="(header, index) in computedDiscriminableHeaders"
                        :name="header.value">
                    <!--todo v-on="$listeners" causes selectedTableItems to become null-->
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop="">

                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.selection`)"
                                v-slot:selection="{ parent, item, index, select, selected, disabled }">
                        <slot :name="`discriminator.${header.value}.selection`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          index: number,
                          select: function,
                          selected: boolean,
                          disabled: boolean
                        }
                        -->
                      </template>
                      <template v-if="hasContentForSlotName(`discriminator.${header.value}.item`)"
                                v-slot:item="{ parent, item, on, attrs }">
                        <slot :name="`discriminator.${header.value}.item`" :item="item"/>
                        <!--
                        {
                          parent: VueComponent,
                          item: object,
                          on: object // Only needed when providing your own v-list-item,
                          attrs: object // Only needed when providing your own v-list-item
                        }
                        -->
                      </template>

                    </bs-table-unique-select>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"

                                              :disabled="loading || items.length === 0"
                                              :items="header._items || computedTableItems"
                                              :datetime-property="header.value"

                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"
                                              :clearable="true"
                                              :sticky="false"
                                              :text-field-props="{ dense: true }"


                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              v-on:click.native.stop=""/>
                  </slot>

                  <bs-discriminator-reset v-if="computedDiscriminableHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->

                <!--==============================-->
                <!--==============================-->
                <slot name="bottomRight"></slot>
                <!--==============================-->
                <!--==============================-->
              </div>
            </div>
          </div>

        </slot>
        <!--==============================-->
        <!--==============================-->
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-if="disablePagination === false"
                v-slot:footer>
        <div class="bs-table-footer d-flex align-center pa-1"
             style="border-top: 3px double rgba(0, 0, 0, 12%);">

          <bs-pagination v-show="items.length > 0"
                         v-bind.sync="computedDefaultPagination"
                         :filtered-total="filteredPagination.itemsLength"
                         :loading="loading"
                         :all-item="paginationProps.allItem"
                         :per-page-options="paginationProps.perPageOptions"
                         :debug="false"
                         class="nowrap ml-auto hide"
                         hide-first-last
                         pagination-style="buttons"
                         @changed:paginationString="setPaginationString"/>
        </div>
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <!-- Pass through named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot"/>

      <!-- Pass through scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="slotData">
        <slot :name="slot" v-bind="slotData"/>
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-slot:progress>
        <slot name="progress"></slot>
      </template>

      <template v-slot:loading>
        <slot name="loading">
          <v-card class="pa-6" elevation="0">
            <v-card-title class="text-h5 justify-center gray--text">
              {{ loadingString }}
            </v-card-title>
          </v-card>
        </slot>
      </template>

      <template v-slot:no-data>
        <bs-table-no-data v-bind="bsTableNoDataProps"/>
      </template>

      <template v-slot:no-results>
        <v-card class="pa-6" elevation="0">
          <v-card-title class="text-h5 justify-center grey--text">No Matching Results</v-card-title>
        </v-card>
      </template>
      <!--=========================================================================================-->
      <!--=========================================================================================-->
      <!-- https://v2.vuetifyjs.com/en/api/v-data-table/#slots-header -->

      <!--
      <template v-for="(header, i) in headers"
          v-slot:[`header.${header.value}`]="{ }">
      -->

      <template v-slot:header="{ props }">

        <thead>

        <tr v-if="superHeaders"
            class="header-row super-header-row">
          <th v-for="(header, index) in superHeaders"
              :key="`${ header.text }-${ index }-super-headers`"
              :class="header.class"
              :colspan="header.colspan">
            <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>

          </th>
        </tr>
        <tr class="header-row" :class="{ disabled: loading || items < 1 }">
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->

          <th v-if="showExpand" class="sorted min"></th>


          <th v-if="computedShowSelect"
              class="min">
            <v-simple-checkbox v-if="computedShowSelectAll"
                               v-model="selectAllCheckboxes"
                               :disabled="loading"
                               :indeterminate="isIndeterminate"
                               color="blue"
                               @click="btnSelectAll"
            />
          </th>

          <template v-for="(header, index) in computedHeaders">

            <th v-if="header.value === 'actions' && hasContentForSlotTableActions === true"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              <slot name="tableActions"/>
            </th>

            <th v-else-if="header.value === 'tableActionHeader'"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              <slot name="tableActions"/>
            </th>

            <th v-else-if="header.value === 'actions' && toggleableColumns === true"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              <bs-table-header-toggle v-model="computedEnabledColumns" :headers="headers"/>
            </th>

            <th v-else-if="header.value === 'tableToggleColumnHeader'"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              <bs-table-header-toggle v-model="computedEnabledColumns" :headers="headers"/>
            </th>


            <th v-else
                :key="`${header.value}-header`"
                :class="[
                    ...header.class,
                    header.sortable ? 'sort-header' : null,
                    header.groupable ? 'group-header' : null,
                  ]"
                @click="header.sortable ? setSortColumn(header) : null">

              <div class="header-wrapper d-flex align-center">

                <div class="sort-wrapper d-flex flex-grow-1">
                  <!--optional header icon-->
                  <!--header title-->
                  <div class="d-flex align-center">
                    <v-icon v-if="header.icon">{{ header.icon }}</v-icon>
                    <div v-else
                         class="header-text">
                      {{ header.text }}
                    </div>
                  </div>

                  <!--sort icon-->
                  <div v-if="header.sortable"
                       class="d-flex align-center justify-center mr-auto"
                       style="height: 1.5rem; width: 1.5rem;">
                    <v-icon class="header-sort-icon"
                            :size="18"
                            style="">
                      {{ computedSortIcon }}
                    </v-icon>
                  </div>

                </div>

                <!--group icon-->
                <div v-if="header.groupable"
                     class="group-wrapper d-flex align-center justify-center"
                     style="height: 2rem;
                            width: 2rem;
                            border-left: 1px solid rgba(0, 0, 0, 0.1);
                            border-right: 1px solid rgba(0, 0, 0, 0.1);">

                  <bs-tooltip top>
                    <template v-slot:activator="{ on, attrs }">

                      <div class="d-flex" style="">
                        <v-icon :key="`group-by-icon-${ index }`"
                                class="header-group-icon"
                                :size="24"
                                v-bind="attrs"
                                @click="setGroupByColumn(header)"
                                v-on="on"
                                v-text="computedGroupByIcon"/>
                      </div>
                    </template>

                    <div>{{ computedGroupByTooltip }}</div>
                  </bs-tooltip>

                </div>

              </div>

            </th>

          </template>

        </tr>

        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->
        <tr v-if="autoDiscriminators === 'header'"
            class="header-discriminator-row">
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->
          <th v-if="showExpand" class="min"></th>
          <template v-for="(header, index) in computedHeaders">
            <th v-if="header.value === 'discriminatorActionHeader'"
                :key="`${header.value}-discriminator-reset`"
                :class="header.class" style="min-width: 28px;">
              <slot name="discriminatorActions">

                <bs-discriminator-reset :discriminators="discriminators"
                                        :search.sync="computedDefaultPagination.search"
                                        :icon="true"
                                        v-on:click.native.stop=""
                                        @search:reset="computedDefaultPagination.search = null">
                </bs-discriminator-reset>

              </slot>
            </th>
            <th v-else
                :key="`${header.value}`"
                :class="header.class"
                class=""
                style="">
              <slot v-if="header.discriminable" :name="header.value">
                <!--todo v-on="$listeners" causes selectedTableItems to become null-->
                <bs-table-unique-select v-if="header.filterType === 'select' && isHeaderDiscriminable(header)"
                                        v-model="localDiscriminators[header.value]"
                                        :key="index"
                                        :disabled="loading || items.length === 0"
                                        :header="header"
                                        :items="computedTableItems"
                                        :loading="loading"
                                        :multiple="header.filterType === 'selectMultiple'"
                                        :menu-props="{ offsetY: true }"
                                        :property="header.value"
                                        class="header-discriminator header-select bs-table-unique-select"
                                        :sticky="sticky"

                                        :generic-placeholder="true"
                                        :outlined="false"
                                        :solo="true"
                                        :flat="true"
                                        :dense="true"
                                        append-icon="mdi-chevron-down"

                                        hide-details
                                        preserve-order
                                        select-item
                                        single-line
                                        v-on:click.native.stop="">

                  <template v-if="hasContentForSlotName(`discriminator.${header.value}.selection`)"
                            v-slot:selection="{ parent, item, index, select, selected, disabled }">
                    <slot :name="`discriminator.${header.value}.selection`" :item="item"/>
                    <!--
                    {
                      parent: VueComponent,
                      item: object,
                      index: number,
                      select: function,
                      selected: boolean,
                      disabled: boolean
                    }
                    -->
                  </template>
                  <template v-if="hasContentForSlotName(`discriminator.${header.value}.item`)"
                            v-slot:item="{ parent, item, on, attrs }">
                    <slot :name="`discriminator.${header.value}.item`" :item="item"/>
                    <!--
                    {
                      parent: VueComponent,
                      item: object,
                      on: object // Only needed when providing your own v-list-item,
                      attrs: object // Only needed when providing your own v-list-item
                    }
                    -->
                  </template>

                </bs-table-unique-select>
                <bs-filter-textfield v-if="header.filterType === 'textfield'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     class="header-discriminator header-search"
                                     debounce
                                     append-icon="mdi-filter-outline"
                                     append-icon-alt="mdi-filter"
                                     :outlined="false"
                                     :solo="true"
                                     :flat="true"
                                     :dense="true"
                                     :label="null"
                                     placeholder="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""/>

              </slot>
            </th>
          </template>

        </tr>
        <!--end header discriminators ---------------------------------------->
        <!--end header discriminators ---------------------------------------->
        <!--end header discriminators ---------------------------------------->

        </thead>
      </template>
      <!--=========================================================================================-->
      <!--=========================================================================================-->
    </v-data-table>


  </div>

</template>

<script>
  import PermissionsMixin from '@/mixins/PermissionsMixin.js'
  import BSPagination from '@/components/BSPagination.vue'
  import BSTableNoData from '@/components/BSTableNoData.vue'
  import BSFilterTextfield from '@/components/BSFilterTextfield.vue'
  import BSDiscriminatorReset from '@/components/BSDiscriminatorReset.vue'

  import BSTableUniqueSelect from '@/components/select/BaseSelect/Unique/BSTableUniqueSelect.vue'
  import BSDatetimeRangePicker from '@/views/unexpectedBlocks/BSDatetimeRangePicker.vue'
  import BSDatetimeRangePickerMixin from '@/mixins/BSDatetimeRangePickerMixin.js'
  import BsTableHeaderToggle from '@/components/BsTableHeaderToggle.vue'

  export default {
    name: 'BSTable',

    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation

      /*
       value: {
       type: Array,
       default(rawProps) {
       return []
       },
       },
       */
      value: {
        // default: [],
        default: undefined,
      },

      showExpand: {
        type: Boolean,
        default: false,
      },
      showSelect: {
        type: Boolean,
        default: false,
      },

      itemKey: {
        type: String,
        default: 'uuid',
      },


      showHeader: {
        type: Boolean,
        default: true,
      },


      title: {
        type: String,
      },
      subtitle: {
        type: String,
      },
      search: {
        type: String,
        default: 'bottomRight',
        validator(value) {
          // The value must match one of these strings
          return [
            'topRight',
            'topLeft',
            'bottomRight',
            'bottomLeft',
            'header',
            'none',
          ].includes(value)
        },
      },
      autoDiscriminators: {
        type: String,
        default: 'bottomLeft',
        validator(value) {
          // The value must match one of these strings
          return [
            'topRight',
            'topLeft',
            'bottomRight',
            'bottomLeft',
            'header',
            'none',
          ].includes(value)
        },
      },


      bsHeaderProps: {
        type: Object,
        default(rawProps) {
          return {
            class: {
              title: 'text-h5',
              header: {
                top: 'pa-4',
                bottom: 'pa-4',
              },
            },
          }
        },
      },
      bsTableNoDataProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      bsTableSearchProps: {
        type: Object,
        default(rawProps) {
          return {
            class: '',
          }
        },
      },

      loadingString: {
        type: String,
        default: 'Loading...',
      },

      loading: {
        type: Boolean,
        default: false,
      },

      debug: {
        type: Boolean,
        default: false,
      },

      roleHeaders: {
        type: Boolean,
        default: true,
      },

      highlightSort: {
        type: Boolean,
        default: true,
      },
      rowHover: {
        type: Boolean,
        default: true,
      },
      altRows: {
        type: Boolean,
        default: false,
      },
      altRowsBlue: {
        type: Boolean,
        default: false,
      },
      altRowsGraphite: {
        type: Boolean,
        default: false,
      },

      items: {
        type: Array,
        default(rawProps) {
          return []
        },
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },

      /////////////////////////////////////////////
      /////////////////////////////////////////////

      // start table props =====================================================
      // start table props =====================================================
      // start table props =====================================================
      superHeaders: {
        type: Array,
      },
      discriminators: {
        type: Object,
        default: null,
      },
      sticky: {
        type: Boolean,
        default: true,
      },

      toggleableColumns: {
        type: Boolean,
        default: false,
      },

      enabledColumnValues: {
        type: Array,
        default(rawProps) {
          return []
        },
      },
      enabledColumns: {
        type: Array,
        default(rawProps) {
          return []
        },
      },

      showSelectAll: {
        type: Boolean,
        default: true,
      },

      sortBy: {
        type: [ String, Array ],
      },
      sortDesc: {
        type: [ Boolean, Array ],
      },
      groupBy: {
        type: String,
      },
      _groupBy: {
        type: String,
      },
      groupDesc: {
        type: Boolean,
        default: false,
      },


      // end table props =====================================================
      // end table props =====================================================
      // end table props =====================================================


      // start pagination props =====================================================
      // start pagination props =====================================================
      // start pagination props =====================================================
      paginationProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      disablePagination: {
        type: Boolean,
        default: false,
      },

      // end pagination props =====================================================
      // end pagination props =====================================================
      // end pagination props =====================================================

    },
    components: {
      BsTableHeaderToggle,
      'bs-pagination': BSPagination,
      'bs-table-no-data': BSTableNoData,
      'bs-filter-textfield': BSFilterTextfield,
      'bs-discriminator-reset': BSDiscriminatorReset,
      'bs-table-unique-select': BSTableUniqueSelect,
      'bs-datetime-range-picker': BSDatetimeRangePicker,
    },
    mixins: [
      PermissionsMixin,
      BSDatetimeRangePickerMixin,
    ],
    data() {
      return {

        isIndeterminate: false,
        selectAllCheckboxes: false,
        defaultPagination: {
          page: 1,
          perPage: 20,
          pages: 0,
          total: this.items.length,
          search: null,
          sortDesc: false,
          sortBy: null,
        },
        paginationString: '',
        filteredPagination: {},

        headerProps: {
          sortIcon: 'mdi-chevron-up-circle',
        },
        footerProps: {
          itemsPerPageOptions: [
            10, 20, 50, 100, -1,
          ],
        },
      }
    },
    watch: {
      items: {
        deep: false,
        immediate: false,
        handler(newValue, oldValue) {
          // console.log('watch items oldValue', oldValue)
          // console.log('watch items newValue', newValue)
          // console.log('watch items computedDefaultPagination', this.computedDefaultPagination)
          this.computedDefaultPagination.total = this.items.length
        },
      },
      selectedTableItems: {
        deep: false,
        handler(newValue, oldValue) {
          console.log('watch selectedTableItems oldValue', oldValue)
          console.log('watch selectedTableItems newValue', newValue)
          if (this.selectedTableItems.length === 0) {
            // console.log('selectAll NONE SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = false
          } else if (this.computedTableItemsSelectable.length === this.selectedTableItems.length) {
            // console.log('selectAll ALL SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = true
          } else {
            // console.log('selectAll INDETERMINATE')
            this.isIndeterminate = true
            this.selectAllCheckboxes = false
          }
        },
      },
    },
    computed: {
      computedEnabledColumns: {
        get() {
          console.log('computedEnabledColumns get this.value', this.enabledColumns)
          return this.enabledColumns
        },
        set(value) {
          console.log('computedEnabledColumns set value', value)
          // this.$emit('input', value)
          this.$emit('update:enabled-columns', value)
        },
      },

      computedHeaders: function() {
        let headers = this.headers
        console.log('computedHeaders', headers)
        console.log('computedHeaders toggleableColumns', this.toggleableColumns)
        console.log('computedHeaders computedHasActionColumn', this.computedHasActionColumn)

        const hasActionHeader = this.computedHasActionColumn
        const hasActionHeaderSlotContent = this.hasContentForSlotTableActions
        const hasToggleableColumns = this.toggleableColumns

        // add tableActionHeader to end for user defined action slot
        if (hasActionHeaderSlotContent === true && hasActionHeader === false) {
          headers = [
            ...headers,
            {
              text: '',
              value: 'tableActionHeader',
              sortable: false,
              filterable: false,
              discriminable: false,
              align: 'start',
              class: [
                'pa-0',
                'px-2',
                'actions',
                'min',
                'tableActionHeader',
              ],
              cellClass: [],
            },
          ]
        }

        // add tableToggleColumnHeader to end for toggleableColumns
        if (hasToggleableColumns === true && hasActionHeader === false) {
          // if (this.toggleableColumns === true) {
          headers = [
            ...headers,
            {
              text: '',
              value: 'tableToggleColumnHeader',
              sortable: false,
              filterable: false,
              discriminable: false,
              // align: 'start',
              class: [
                'actions',
                'table-toggle-columns-header',
                'min',
                'pa-0',
                'px-2',
              ],
              cellClass: [],
            },
          ]
        }

        if (this.autoDiscriminators === 'header') {
          headers = [
            ...headers,
            {
              text: '',
              value: 'discriminatorActionHeader',
              sortable: false,
              filterable: false,
              discriminable: false,
              align: 'start',
              class: [
                'pa-0',
                // 'px-2',
                'actions',
                'min',
                'discriminatorActionHeader',
              ],
              cellClass: [],
            },
          ]
        }

        console.log('computedHeaders injected', headers)

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // set provided header class to an array if it isnt already
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = headers.map(header => {
          if (!header.class) {
            header.class = []
          } else if (typeof header.class == 'string') {
            header.class = [ header.class ]
          }
          return header
        })
        /*
         headers = headers.map(header => {
         if (typeof header.class == 'string') {
         header.class = [ header.class ]
         }
         return header
         })
         */
        // console.log('computedHeaders headers typeof', headers)

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = headers.filter(header => {
          return header.display !== false
        })
        // console.log('computedHeaders headers header.display', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start remove headers for role if preferred
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.roleHeaders) {
          headers = this.headersForAccess(headers)
        }
        // console.log('computedHeaders headersForAccess', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end remove headers for role if preferred
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start remove header for groupBy
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.groupBy) {
          // console.log('computedHeaders groupBy', this.groupBy)
          headers = headers.filter(header => {
            const groupByProp = this.groupBy
            return header.value !== groupByProp
          })
        }
        // console.log('computedHeaders headersForAccess', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end remove headers for role if preferred
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start remove header for toggleableColumns
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.toggleableColumns) {
          let disabledHeaders
          let toggleableHeaders = this.computedToggleableHeaders
          let enabledColumns = this.enabledColumns

          disabledHeaders = toggleableHeaders.filter(object => {
            return enabledColumns.includes(object.value) === false
          })

          headers = headers.filter(header => {
            return disabledHeaders.includes(header) === false
          })
        }
        /*
         if (this.toggleableColumns) {
         let disabledHeaders
         let toggleableHeaders = this.computedToggleableHeaders
         let enabledColumns = this.enabledColumns
         disabledHeaders = toggleableHeaders.filter(object => !enabledColumns.includes(object))

         headers = headers.filter(header => {
         return disabledHeaders.includes(header) === false
         })
         }
         */
        // console.log('computedHeaders headersForAccess', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end remove headers for toggleableColumns
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // console.log('computedHeaders headers start', headers)

        headers = headers.map((header) => {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          // start set header classes
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          let textAlign = `text-${ header.align || 'start' }`
          let filterable = header.filterable
          let discriminable = header.discriminable
          let sortable = header.sortable
          let groupable = header.groupable

          header.class = [ ...header.class, textAlign ]

          if (filterable) {
            header.class = [ ...header.class, 'filterable' ]
          }
          if (discriminable) {
            header.class = [ ...header.class, 'discriminable' ]
          }
          if (sortable) {
            header.class = [ ...header.class, 'sortable' ]
          }
          if (groupable) {
            header.class = [ ...header.class, 'groupable' ]
          }
          // set cell classes
          // header.cellClass = [ ...header.cellClass, textAlign ]

          return header
        })

        // console.log('computedHeaders headers after', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = this.sortableHeaders(headers)
        // console.log('computedHeaders highlightSort', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        // console.log('computedHeaders headers', headers)
        // console.log('computedHeaders //////////////////')

        return headers
      },
      computedTableItems: function() {
        // console.log('computedTableItems')
        let itemsComputed = this.items
        // console.log('computedTableItems itemsComputed', itemsComputed)

        this.computedDiscriminableHeaders.forEach((header) => {
          // console.log('computedTableItems computedFilterHeaders header', header)
          if (header._filter === undefined) {
            // console.log('computedTableItems _filter undefined', header.value)

            let discriminatorValue = this.discriminators[header.value]
            // console.log('computedTableItems _filter undefined discriminatorValue', discriminatorValue, header.value)

            if (header.filterType === 'select') {
              // console.log('computedTableItems _filter select computedTableItems discriminatorValue', discriminatorValue)
              // console.log('_filter select computedFilterHeaders header.value', header.value)
              itemsComputed = itemsComputed.filter((item) => {
                let headerValue = item[header.value]
                // console.log('_filter select computedTableItems headerValue', headerValue)
                // console.log('computedTableItems _filter undefined select item[header.value]', header.value, discriminatorValue, item[header.value])

                return (
                    // !discriminatorValue ||
                    discriminatorValue === null ||
                    discriminatorValue === undefined ||
                    item[header.value] === discriminatorValue
                )
              })
              // console.log('computedTableItems itemsComputed', itemsComputed)
              // console.log('computedTableItems _filter undefined select itemsComputed', itemsComputed, header.value)

            } else if (header.filterType === 'dateRange') {
              let headerValue = header.value
              // console.log('computedTableItems _filter dateRange headerValue', headerValue)
              // console.log('computedTableItems _filter dateRange discriminatorValue', discriminatorValue)
              itemsComputed = itemsComputed.filter((item) => {
                let itemValue = item[headerValue]
                // console.log('computedTableItems _filter dateRange itemValue', itemValue)
                let isInRange = this.datetimeInRange(itemValue, discriminatorValue)
                // console.log('computedTableItems _filter dateRange isInRange', isInRange)
                let shouldFilter = (
                    discriminatorValue === null ||
                    discriminatorValue === undefined ||
                    isInRange
                )
                /*
                 let shouldFilter = (
                 discriminatorValue === null ||
                 discriminatorValue === undefined ||
                 !itemValue ||
                 isInRange
                 )
                 */
                // console.log('computedTableItems _filter dateRange shouldFilter', shouldFilter)
                // console.log('computedTableItems _filter dateRange //////////////////////////////////')
                return shouldFilter
              })
              // console.log('computedTableItems _filter dateRange itemsComputed', itemsComputed, header.value)
            }
          } else {
            // console.log('computedTableItems _filter header._filter', header.value)
            itemsComputed = itemsComputed.filter(header._filter)
            // console.log('computedTableItems _filter header._filter itemsComputed', itemsComputed, header.value)

          }
        })

        // console.log('computedTableItems itemsComputed', itemsComputed)
        // console.log('computedTableItems //////////////////')

        return itemsComputed
      },
      /*
       computedDatetimeModel: {
       get() {
       // console.log('computedDatetimeModel get')
       let datetimeArray = null
       // ub specific
       const startDatetime = this.localDiscriminators.startDatetime
       const endDatetime = this.localDiscriminators.endDatetime
       // const startDatetime = this.computedEntriesDiscriminators.startDatetime
       // const endDatetime = this.computedEntriesDiscriminators.endDatetime
       // console.log('computedDatetimeModel startDatetime', startDatetime)
       // console.log('computedDatetimeModel endDatetime', endDatetime)
       // ub specific
       let startDatetimeObject = parseISO(startDatetime)
       let endDatetimeObject = parseISO(endDatetime)
       // console.log('localValue startDatetimeObject', startDatetimeObject)
       // console.log('localValue endDatetimeObject', endDatetimeObject)
       if (isValid(startDatetimeObject) && isValid(endDatetimeObject)) {
       datetimeArray = [
       startDatetimeObject,
       endDatetimeObject,
       ]
       }

       return datetimeArray
       },
       set(datetimeArray) {
       // console.log('computedDatetimeModel setdatetimeArray', datetimeArray)
       let startDatetime = datetimeArray[0]
       let endDatetime = datetimeArray[1]
       let startDatetimeIso = startDatetime ? formatISO(startDatetime) : null
       let endDatetimeIso = endDatetime ? formatISO(endDatetime) : null
       // console.log('localValue set update:startDatetime', startDatetimeIso)
       // console.log('localValue set update:endDatetime', endDatetimeIso)

       // ub specific
       this.localDiscriminators.startDatetime = startDatetimeIso
       this.localDiscriminators.endDatetime = endDatetimeIso
       // this.computedEntriesDiscriminators.startDatetime = startDatetimeIso
       // this.computedEntriesDiscriminators.endDatetime = endDatetimeIso
       // ub specific
       },
       },
       */

      computedDefaultPagination: {
        get() {
          console.log('computedDefaultPagination')
          const defaultPagination = this.defaultPagination
          // console.log('computedDefaultPagination get defaultPagination', defaultPagination)
          return defaultPagination
        },
        set(value) {
          // console.log('computedDefaultPagination set value', value)
          this.defaultPagination = value
        },
      },
      computedShowSelect: function() {
        console.log('computedShowSelect')
        // let retVal
        // console.log('computedShowSelect', this.$attrs)
        // const showSelectProp = get(this.$attrs, 'show-select')
        return this.showSelect
      },
      computedShowSelectAll: function() {
        console.log('computedShowSelectAll')
        // let retVal
        // console.log('computedShowSelectAll', this.$attrs)
        // const showSelectAllProp = get(this.$attrs, 'show-select-all')
        // return (showSelectAllProp === true)
        return this.showSelectAll
      },
      selectedTableItems: {
        get() {
          console.log('selectedTableItems get this.value', this.value)
          return this.value
        },
        set(value) {
          console.log('selectedTableItems set value', value)
          this.$emit('input', value)
        },
      },
      computedTableItemsSelectable: function() {
        console.log('computedTableItemsSelectable')
        let itemsComputed = this.computedTableItems
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)
        itemsComputed = itemsComputed.filter((item) => {
          return item.isSelectable === true
        })
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)
        return itemsComputed
      },

      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localDiscriminators: {
        get() {
          console.log('localDiscriminators')
          return this.discriminators
        },
        set(value) {
          // this.$emit('input', value)
          // console.log('localDiscriminators value', value)
        },
      },
      computedEnabledColumnValues: {
        get() {
          console.log('computedEnabledColumnValues get this.enabledColumnValues', this.enabledColumnValues)
          return this.enabledColumnValues
        },
        set(value) {
          console.log('computedEnabledColumnValues set value', value)
          this.$emit('update:enabled-column-values', value)
        },
      },

      computedHasActionColumn: function() {
        console.log('computedHasActionColumn')
        let retVal = this.headers.find((object) => object.value === 'actions')
        // console.log('computedHasActionColumn', retVal)
        return retVal !== undefined
      },

      // headers of type
      // headers of type
      // headers of type
      computedToggleableHeaders: function() {
        console.log('computedToggleableHeaders')
        let headers = this.headers
        headers = headers.filter(header => {
          const isHeaderToggleable = header.toggleable === true
          return isHeaderToggleable
        })
        console.log('computedToggleableHeaders', headers)

        return headers
      },
      computedDiscriminableHeaders: function() {
        console.log('computedDiscriminableHeaders')
        let headers = this.computedHeaders
        headers = headers.filter(header => {
          const isHeaderDiscriminable = this.isHeaderDiscriminable(header)
          return isHeaderDiscriminable
        })
        return headers
      },
      computedFilterableHeaders: function() {
        console.log('computedFilterableHeaders')
        let headers = this.computedHeaders
        headers = headers.filter(header => {
          const text = header.text
          const isHeaderFilterable = this.isHeaderFilterable(header)
          return isHeaderFilterable
        })
        return headers
      },
      // headers of type
      // headers of type
      // headers of type

      computedTableClasses: function() {
        console.log('computedTableClasses')
        // highlightSort
        // roleHeaders
        // rowHover
        // altRows

        let classes = {
          'bs-table': true,
          'bs-highlight-sort': this.highlightSort,
          'bs-role-headers': this.roleHeaders,
          'bs-row-hover': this.rowHover,
          'bs-alt-rows': this.altRows || this.altRowsGraphite || this.altRowsBlue,
          'bs-alt-rows-graphite': this.altRowsGraphite,
          'bs-alt-rows-blue': this.altRowsBlue,
        }

        return classes
      },
      computedFilterString: function() {
        console.log('computedFilterString')
        let retVal
        const allItemCount = this.items.length // localize
        if (allItemCount === 0) return ''
        const filteredItemCount = this.filteredPagination.itemsLength
        const isFiltered = filteredItemCount !== null && allItemCount !== filteredItemCount

        if (isFiltered) {
          retVal = `${ filteredItemCount } filtered. ${ allItemCount } total`
        } else {
          retVal = `${ allItemCount } total`
        }
        return retVal
      },
      hasContentForHeaderTop: function() {
        console.log('hasContentForHeaderTop')
        let retVal
        retVal = this.hasContentForHeaderTopLeft || this.hasContentForHeaderTopRight
        return retVal
      },
      hasContentForHeaderBottom: function() {
        console.log('hasContentForHeaderBottom')
        let retVal
        retVal = this.hasContentForHeaderBottomLeft || this.hasContentForHeaderBottomRight
        return retVal
      },
      hasContentForHeaderTopLeft: function() {
        console.log('hasContentForHeaderTopLeft')
        let retVal
        retVal = this.hasContentForSlotName('topLeft') || this.title
        return retVal
      },
      hasContentForHeaderTopRight: function() {
        console.log('hasContentForHeaderTopRight')
        let retVal
        retVal = this.hasContentForSlotName('topRight')
        // retVal = this.hasContentForSlotName('topRight') || this.search === 'topRight'
        return retVal
      },
      hasContentForHeaderBottomLeft: function() {
        console.log('hasContentForHeaderBottomLeft')
        let retVal
        retVal = this.hasContentForSlotName('bottomLeft')
        return retVal
      },
      hasContentForHeaderBottomRight: function() {
        console.log('hasContentForHeaderBottomRight')
        let retVal
        retVal = this.hasContentForSlotName('bottomRight')
        // retVal = this.hasContentForSlotName('bottomRight') || this.search === 'bottomRight'
        return retVal
      },

      hasContentForSlotTableActions: function() {
        let slot = 'tableActions'

        let userSlotContent = !!this.$slots[slot]
        let userScopedSlotContent = !!this.$scopedSlots[slot]
        let userContent = userSlotContent || userScopedSlotContent

        return userContent
      },

      computedAttrs: function() {
        console.log('computedAttrs')

        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        return attrs
      },
      computedSortIcon: function() {
        console.log('computedSortIcon')
        let icon

        let sortDesc = this.computedDefaultPagination.sortDesc

        icon = '$sortSolid'
        if (sortDesc === false) {
          icon = 'mdi-chevron-up-circle'
        } else if (sortDesc === true) {
          icon = 'mdi-chevron-down-circle'
        }

        return icon
      },
      computedGroupByIcon: function() {
        console.log('computedGroupByIcon')
        const groupBy = this._groupBy
        console.log('computedGroupByIcon groupBy', groupBy)

        let icon

        if (groupBy) {
          // icon = 'mdi-unfold-more-horizontal'
          icon = 'mdi-arrow-expand-vertical'
        } else {
          // icon = 'mdi-unfold-less-horizontal'
          icon = 'mdi-format-vertical-align-center'
          // icon = 'mdi-arrow-collapse-vertical'
        }

        return icon
      },
      computedGroupByTooltip: function() {
        console.log('computedGroupByTooltip')
        const groupBy = this._groupBy
        console.log('computedGroupByTooltip groupBy', groupBy)

        let retVal

        if (groupBy) {
          retVal = 'Ungroup'
        } else {
          retVal = 'Group'
        }

        return retVal
      },
    },

    methods: {
      hasContentForSlotName: function(slot, context = null) {
        // console.log('hasContentForSlotName slot', slot)
        // console.log('hasContentForSlotName context', context)
        // console.log('hasContentForSlotName this.$slots', this.$slots)
        // console.log('hasContentForSlotName this.$slots[slot]', this.$slots[slot])
        let userSlotContent = !!this.$slots[slot]
        // console.log('hasContentForSlotName userSlotContent', userSlotContent)
        let userScopedSlotContent = !!this.$scopedSlots[slot]
        // console.log('hasContentForSlotName userScopedSlotContent', userScopedSlotContent)
        let userContent = userSlotContent || userScopedSlotContent
        // console.log('hasContentForSlotName userContent', userContent)
        let search = this.search
        // console.log('hasContentForSlotName search', search)
        let hasSearch = search !== 'none' && search === slot
        // console.log('hasContentForSlotName hasSearch', hasSearch)
        let autoDiscriminators = this.autoDiscriminators
        // console.log('hasContentForSlotName autoDiscriminators', autoDiscriminators)
        let hasAutoDiscriminators = autoDiscriminators !== 'none' && this.autoDiscriminators === slot && this.computedDiscriminableHeaders.length
        // console.log('hasContentForSlotName hasAutoDiscriminators', hasAutoDiscriminators)
        let retVal = userContent || hasSearch || hasAutoDiscriminators
        // console.log('hasContentForSlotName retVal', retVal)
        // console.log('hasContentForSlotName ////////////////////////')
        return retVal
      },

      setPaginationString: function(paginationString) {
        console.log('setPaginationString paginationString', paginationString)
        this.$emit('changed:paginationString', paginationString)
        this.paginationString = paginationString
      },
      btnSelectAll: function() {
        console.log('btnSelectAll')
        console.log('btnSelectAll this.selectedTableItems', this.selectedTableItems)
        if (this.selectAllCheckboxes === true) {
          console.log('btnSelectAll newValue true')
          this.selectedTableItems = this.computedTableItemsSelectable
        } else {
          this.selectedTableItems = []
        }
        console.log('btnSelectAll this.selectedTableItems', this.selectedTableItems)

        this.$emit('click:select-all', this.selectAllCheckboxes)
      },
      isHeaderDiscriminable: function(header) {
        console.log('isHeaderDiscriminable')
        const discriminatorTypes = [ 'select', 'selectMultiple', 'dateRange', 'textfield' ]
        const isHeaderDiscriminable = header.discriminable
        const isTypeDiscriminable = discriminatorTypes.includes(header.filterType)
        const isDiscriminable = isTypeDiscriminable && isHeaderDiscriminable

        return isDiscriminable
      },
      isHeaderFilterable: function(header) {
        console.log('isHeaderFilterable')
        const discriminatorTypes = [ 'select', 'selectMultiple', 'dateRange', 'textfield' ]
        const isHeaderFilterable = header.filterable
        const isTypeFilterable = discriminatorTypes.includes(header.filterType)
        const isFilterable = isTypeFilterable && isHeaderFilterable

        return isFilterable
      },

      sortableHeaders: function(headers) {
        console.log('sortableHeaders', headers)
        return headers.map((header) => {
          let sortBy = this.computedDefaultPagination.sortBy
          let sortDesc = this.computedDefaultPagination.sortDesc

          let classSet = new Set(header.class)
          let cellClassSet = new Set(header.cellClass)

          classSet.delete('sorted')
          cellClassSet.delete('sorted')

          classSet.delete('ascending')
          classSet.delete('descending')

          cellClassSet.delete('ascending')
          cellClassSet.delete('ascending')

          if (sortBy === header.value) {
            classSet.add('sorted')
            cellClassSet.add('sorted')

            classSet.add(sortDesc)
            cellClassSet.add(sortDesc)
          }

          header.class = [ ...classSet ]
          header.cellClass = [ ...cellClassSet ]

          return header
        })
      },
      headersForAccess: function(headers) {
        console.log('headersForAccess')

        return headers.filter((object) => {
          // console.log('0 HeadersForAccess:', object.value)

          let retVal = true

          let hasRole = Object.hasOwn(object, 'role')
          let hasScope = Object.hasOwn(object, 'scope')
          let hasAccess = Object.hasOwn(object, 'access')

          if (hasRole || hasScope || hasAccess) {
            if (hasRole) {
              // console.log('1 HeadersForAccess role specified:', object.value, object.role, hasRole)
              retVal = this.userHasRequiredRole(object.role)
            } else if (hasScope || hasAccess) {
              // console.log('2 HeadersForAccess scope specified:', object.value, object.scope, hasScope)
              // console.log('2 HeadersForAccess access specified:', object.value, object.access, hasAccess)
              if (hasScope && hasAccess) {
                retVal = this.userHasRequiredScopeAndAccess(
                    object.scope,
                    object.access,
                )
              } else if (hasScope) {
                retVal = this.userHasRequiredScope(object.scope)
              } else if (hasAccess) {
                retVal = this.userHasRequiredAccess(object.access)
              }
            }
          } else {
            // header does not specify perms
          }
          return retVal
        })

        // return headers.filter(object => {
        //   let retVal = true
        //   let hasRole = Object.hasOwn(object, 'roles')
        //   if (hasRole) {
        //     retVal = this.userHasRequiredRole(object.roles)
        //   }
        //   return retVal
        // })
      },
      setSortColumn: function(header) {
        console.log('setSortColumn header', header)
        // console.log('setSortColumn value', header.value)
        let oldSortColumn = this.computedDefaultPagination.sortBy
        let newSortColumn = header.value

        let oldSortDirection = this.computedDefaultPagination.sortDesc
        let newSortDirection = !oldSortDirection

        /*
         if (oldSortDirection === false) {
         newSortDirection = true
         } else if (oldSortDirection === true) {
         newSortDirection = false
         }
         */

        if (oldSortColumn === newSortColumn) {
          this.computedDefaultPagination.sortDesc = newSortDirection
        } else {
          this.computedDefaultPagination.sortBy = newSortColumn
        }
      },
      getSortIcon: function(header) {
        console.log('getSortIcon')

        let icon
        let sortDesc = this.computedDefaultPagination.sortDesc

        icon = '$sortSolid'
        if (sortDesc === false) {
          icon = 'mdi-chevron-up-circle'
        } else if (sortDesc === true) {
          icon = 'mdi-chevron-down-circle'
        }
        /*
         if (header.sortable === true) {
         icon = '$sortSolid'
         if (header.class.includes('sorted')) {
         if (sortDesc === false) {
         icon = 'mdi-chevron-up-circle'
         } else if (sortDesc === true) {
         icon = 'mdi-chevron-down-circle'
         }
         }
         }
         */
        return icon
      },
      getGroupByIcon: function(header) {
        const groupBy = this._groupBy
        const headerValue = header.value

        console.log('getGroupByIcon')
        console.log('getGroupByIcon groupBy', groupBy)
        console.log('getGroupByIcon headerValue', headerValue)

        let icon

        if (groupBy === headerValue) {
          icon = 'mdi-arrow-expand-vertical'
        } else {
          icon = 'mdi-format-vertical-align-center'
          // icon = 'mdi-arrow-collapse-vertical'
        }
        return icon
      },
      getGroupByTooltip: function(header) {
        const groupBy = this._groupBy
        const headerValue = header.value

        console.log('getGroupByTooltip')
        // console.log('getGroupByTooltip groupBy', groupBy)
        // console.log('getGroupByTooltip headerValue', headerValue)

        let retVal

        if (groupBy === headerValue) {
          retVal = 'Ungroup'
        } else {
          retVal = 'Group'
        }
        return retVal
      },
      setGroupByColumn: function(header) {
        console.log('setGroupByColumn header', header)
        console.log('setGroupByColumn value', header.value)
        console.log('setGroupByColumn _groupBy', this._groupBy)
        let retVal
        if (header.value === this._groupBy) {
          retVal = null
        } else {
          retVal = header.value
        }
        console.log('setGroupByColumn update:_groupBy', retVal)
        // this._groupBy = retVal
        this.$emit('update:_groupBy', retVal)
        // this.$emit('groupBy:change', header.value)
      },
      setGroupBySort: function() {
        console.log('setGroupBySort', this.groupDesc)
        this.$emit('groupDesc:change', this.groupDesc)
      },
      eventHandlerPageCount: function(event) {
        console.log('eventHandlerPageCount event', event)
        this.computedDefaultPagination.pages = event
      },
      eventHandlerOptions: function(event) {
        console.log('eventHandlerOptions event', event)
      },
      eventHandlerPagination: function(event) {
        console.log('eventHandlerPagination event', event)
        this.filteredPagination = event
        this.$emit('filter:changed', this.computedFilterString)
      },
      eventHandlerSort($event, context) {
        console.log('eventHandlerSort $event', $event)
        console.log('eventHandlerSort context', context)
        if (context === 'sortBy') {
          this.computedDefaultPagination.sortBy = $event
        } else if (context === 'sortDesc') {
          let retVal
          if ($event === true) {
            retVal = true
          } else if ($event === false) {
            retVal = false
          }
          this.computedDefaultPagination.sortDesc = retVal
        }
      },
    },
    beforeCreate() {},
    created() {
      // console.log('created hasContentForSlotName discriminator.lists.item', this.hasContentForSlotName('discriminator.lists.item'))
      // console.log('created hasContentForSlotName discriminator.lists.selection', this.hasContentForSlotName('discriminator.lists.selection'))
      // console.log('created hasContentForSlotName shouldbefalse', this.hasContentForSlotName('shouldbefalse'))
    },
    beforeMount() {},
    mounted() {
      console.log('mounted')
      if (this.sortBy) {
        console.log('mounted sortBy', this.sortBy)
        this.defaultPagination.sortBy = this.sortBy
      }
      if (this.sortDesc) {
        console.log('mounted sortDesc', this.sortDesc)
        this.defaultPagination.sortDesc = this.sortDesc
      }
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .header-content {
    .header-top {
      .top-left {
        .title {
        }
        .subtitle {
          line-height: 1rem;
        }
      }
    }
  }
</style>
