<!--
  - Copyright ThreatBlockr Inc 2022-2024
  - ejohnson created 11/9/22
  -->
<template>
  <v-menu bottom
          left
          offset-y
          nudge-bottom="6px"
          transition="slide-y-transition"
          min-width="12rem"
          v-bind="$attrs"
          v-on="$listeners">

    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"/>
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"/>
    </template>

  </v-menu>

</template>

<script>

  export default {
    name: 'BSActionMenu',
    props: {
      /*
       type: {
       type: String,
       default: null,
       },
       */
      /*
       indentSingles: {
       type: Boolean,
       default: false,
       },
       */
      /*
       multiple: {
       type: Boolean,
       default: false,
       },
       */

      // Object with a default value
      /*
       items: {
       type: Array,
       // Object or array defaults must be returned from
       // a factory function. The function receives the raw
       // props received by the component as the argument.
       required: true,
       default(rawProps) {
       return []
       },
       },
       */

      /*
       showLinks: {
       type: Boolean,
       default: false,
       },
       */
      /*
       size: {
       type: String,
       default: 'normal',
       },
       */
      /*
       large: {
       type: Boolean,
       default: false,
       },
       small: {
       type: Boolean,
       default: false,
       },

       xLarge: {
       type: Boolean,
       default: false,
       },
       xSmall: {
       type: Boolean,
       default: false,
       },
       */
    },
    components: {
      // 'bs-glyph-button': BSGlyphButton,
    },
    mixins: [],
    data() {
      return {
        // disclosed: false,
      }
    },
    watch: {},
    computed: {
      /*
       fontSizeForSizeProp: function() {
       console.log('fontSizeForSizeProp', this.size)

       let retVal
       const sizeLookup = {
       xLarge: '1.75rem',
       large: '1.5rem',
       normal: '1.25rem',
       small: '1.0rem',
       xSmall: '0.85rem',
       }
       /!*
       const sizeLookup = {
       xLarge: '1.75rem',
       large: '1.5rem',
       normal: '1.25rem',
       small: '1.0rem',
       xSmall: '0.75rem',
       }
       *!/
       retVal = sizeLookup[this.size]

       console.log('fontSizeForSizeProp retVal', retVal)

       return retVal
       },
       */
      /*
       iconSizeForSizeProp: function() {
       // console.log('iconSizeForSizeProp', this.size)

       let retVal
       /!*
       const sizeLookup = {
       xLarge: '1.75rem',
       large: '1.5rem',
       normal: '1.25rem',
       small: '1.0rem',
       xSmall: '.85rem',
       }
       *!/
       const sizeLookup = {
       xLarge: '2.0rem',
       large: '1.75rem',
       normal: '1.5rem',
       small: '1.25rem',
       xSmall: '1.0rem',
       }
       retVal = sizeLookup[this.size]

       // console.log('iconSizeForSizeProp retVal', retVal)

       return retVal
       },
       */
      /*
       computedSizeAttrs: function() {
       // console.log('computedSizeAttrs')
       const computedSizeAttrs = {
       large: this.large,
       small: this.small,
       xLarge: this.xLarge,
       xSmall: this.xSmall,
       }

       let computedSizeAttr = Object.keys(computedSizeAttrs).filter(computedSizeAttr => computedSizeAttrs[computedSizeAttr])
       console.log('computedSizeAttrs', computedSizeAttr)

       let retVal
       if (computedSizeAttr.length) {
       retVal = {
       ...computedSizeAttrs,
       [computedSizeAttr]: true,
       }
       } else {
       retVal = {
       ...computedSizeAttrs,
       }
       }

       console.log('computedSizeAttrs retVal', retVal)

       return retVal
       },
       */
      /*
       computedDisclosureIconSize: function() {
       console.log('computedDisclosureIconSize')

       // let pref = this.computedSizeAttrs.find(object => )
       let pref = pickBy(this.computedSizeAttrs, true)
       console.log('computedDisclosureIconSize pref', pref)

       return 20
       },
       */
      /*
       computedIconForDisclosed: function() {
       console.log('computedIconForDisclosed')
       return this.disclosed ? 'mdi-chevron-down-circle' : 'mdi-chevron-right-circle'
       },
       */
      /*
       computedFirstItem: function() {
       // console.log('computedFirstItem')
       let retVal = {}
       if (this.items.length) {
       const firstItem = this.items[0]
       if (firstItem) {
       retVal = firstItem
       }
       }
       return retVal

       },
       */
      /*
       computedItemsRemaining: function() {
       // console.log('computedItemsRemaining')
       let hasTitle = this.slotHasContent('title')
       let retVal = this.items
       if (!hasTitle) {
       retVal = retVal.slice(1)
       }
       return retVal
       },
       */
      /*
       computedItemsRemainingCount: function() {
       // console.log('computedItemsRemainingCount')

       return this.computedItemsRemaining.length
       },
       */
    },
    methods: {
      /*
       slotHasContent: function(slot) {
       let userContent = !!this.$slots[slot]
       // let search = this.search === slot
       // let autoDiscriminators = this.autoDiscriminators === slot
       return userContent
       // return userContent || search || autoDiscriminators
       },
       */
      /*
       toggleDisclosure: function() {
       console.log('toggleDisclosure')
       this.disclosed = !this.disclosed
       },
       */
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .bs-disclosure {
    .v-icon {
      // color: #0F78F7;
      color: rgba(0, 0, 0, 0.16);
      &.active {
        color: #0F78F7;
      }
      &:hover {
        color: rgba(15, 120, 247, 0.64);
        // color: rgba(0, 0, 0, 0.64);
        // cursor: pointer;
      }
      &:focus {
        &:after {
          opacity: 0;
        }
      }

    }
  }
</style>
