<!--
  - Copyright ThreatBlockr Inc 2023-2024
  - ejohnson created 1/7/23
  -->
<template>
  <!--<div class="title-block d-flex flex-column fill-width divider-bottom-dotted">-->
  <div class="title-block d-flex flex-column justify-center fill-width"
       :class="{
      'divider-bottom': showTitleDivider,
      'divider-dotted': showTitleDivider && titleDividerStyle === 'dotted',
      'divider-dashed': showTitleDivider && titleDividerStyle === 'dashed',
      'divider-double': showTitleDivider && titleDividerStyle === 'double',

       }">
    <!--<div class="title-block d-flex flex-grow-1">-->


    <template v-if="card">
      <div class="d-flex align-center fill-width" style=""
           :class="computedClasses.paddingClass">
        <div class="d-flex align-center flex-gap-2 flex-grow-1"
             style="">

          <template v-if="hasPrependSlot">
            <slot name="prepend"/>
          </template>

          <div :class="computedClasses.titleClass">
            <slot name="title">
              {{ title }}
            </slot>
          </div>

          <!--
          <div :class="computedClasses.titleClass">
            {{ title }}
          </div>
          -->

          <div v-if="count"
               class="align-self-end text-subtitle-1 gray--text nowrap">({{ count | localeString }})
          </div>
          <div v-if="subtitle"
               class="align-self-end text-subtitle-1 gray--text nowrap">{{ subtitle }}
          </div>
          <div v-if="hasTopLeftSlot"
               class="d-flex align-center flex-grow-1">
            <slot name="topLeft"/>
          </div>

        </div>

        <div v-if="hasTopRightSlot" class="d-flex align-center flex-grow-1 justify-end">
          <slot name="topRight"/>
        </div>
      </div>
      <div v-if="hasBottomSlots" class="d-flex align-center mt-4">
        <div v-if="hasBottomLeftSlot" class="d-flex align-center">
          <slot name="bottomLeft"/>
        </div>
        <div v-if="hasBottomRightSlot" class="d-flex align-center flex-grow-1 justify-end">
          <slot name="bottomRight"/>
        </div>
      </div>

      <!--
      <v-divider v-if="showTitleDivider"
                 :class="computedClasses.dividerClass"
      />
      -->
      <!--
      <v-divider v-if="showTitleDivider"
                 class="dotted pb-0"
      />
      -->
    </template>

    <template v-else>
      <div class="d-flex align-center pb-8">
        <div class="text-h4">
          {{ title }}

          <div v-if="count"
               class="align-self-baseline text-subtitle-1 ml-2 gray--text nowrap">({{ count | localeString }})
          </div>

          <div v-if="subtitle"
               class="align-self-baseline text-subtitle-1 ml-2 gray--text nowrap">{{ subtitle }}
          </div>

        </div>

        <div class="mr-auto d-flex">
          <slot name="left">

          </slot>
        </div>

        <div class="ml-auto d-flex">
          <slot name="right">

          </slot>
        </div>

      </div>

    </template>

    <div v-if="debug">
      <bs-print :value="{showTitleDivider}"/>
      <bs-print :value="{computedClasses}"/>

    </div>

  </div>

</template>

<script>
  export default {
    name: 'BSViewTitle',
    props: {
      title: {
        type: String,
        required: true,
      },
      classes: {
        type: Object,
        default(rawProps) {
          return null
        },
      },
      titleClass: {
        type: String,
        default: 'text-h5',
      },
      paddingClass: {
        type: String,
        default: 'px-4 py-4',
      },
      count: {
        type: [ Number, String ],
        required: false,
      },
      subtitle: {
        type: String,
        required: false,
      },
      card: {
        type: Boolean,
        default: true,
      },
      debug: {
        type: Boolean,
        default: false,
      },
      showTitleDivider: {
        type: Boolean,
        default: false,
      },
      titleDividerStyle: {
        type: String,
        default: 'dotted',
      },
    },
    components: {},
    mixins: [],
    data() {
      return {}
    },
    watch: {},
    computed: {
      computedClasses: function() {
        // console.log('computedClasses')
        let classes = this.classes
        const defaultClasses = {
          titleClass: 'text-h5',
          paddingClass: 'px-4 py-4',
          dividerClass: 'dotted',
        }
        let retVal = {
          ...defaultClasses,
          ...classes,
          dividerClass: this.titleDividerStyle,
        }
        // let retVal = Object.assign(defaultClasses, classes)
        // console.log('computedClasses retVal', retVal)

        return retVal
      },
      hasPrependSlot: function() {
        return this.$slots['prepend']
      },
      hasTopLeftSlot: function() {
        return this.$slots['topLeft']
      },
      hasTopRightSlot: function() {
        return this.$slots['topRight']
      },
      hasBottomLeftSlot: function() {
        return this.$slots['bottomLeft']
      },
      hasBottomRightSlot: function() {
        return this.$slots['bottomRight']
      },
      hasBottomSlots() {
        return this.hasBottomLeftSlot || this.hasBottomRightSlot
      },
    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .title-block {
    &.divider-bottom {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.06);

      &.divider-dotted {
        border-bottom-style: dotted;
      }
      &.divider-dashed {
        border-bottom-style: dotted;
      }
      &.divider-double {
        border-bottom-width: 3px;
        border-bottom-style: double;
      }
    }
  }
  .divider-bottom-dotted {
    // border-bottom: 3px dotted red;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.06);
  }
  .divider-bottom-dashed {
    // border-bottom: 3px dashed red;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
  }
  .divider-bottom-double {
    // border-bottom: 3px double red;
    border-bottom: 3px double rgba(0, 0, 0, 0.06);
  }


  .theme--light.v-divider.dotted {
    border-style: dotted;
    // border-color: red;
    border-color: rgba(0, 0, 0, 0.06);
  }
  .theme--light.v-divider.double {
    border-style: double;
    border-color: rgba(0, 0, 0, 0.06);
    // border-color: red;
    border-top-width: 3px;
  }
</style>
