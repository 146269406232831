import Vue from 'vue'
import helpers from '@/helpers/helpers.js'

const directivesContext = require.context(
    '.',
    false,
    /v-[\w-]+\.js$/
)
// const directivesContext = require.context('./', false, /\.js$/)

//https://blog.sylo.space/auto-import-vue-directives-from-directory/

function importAllDirectives(context) {
    const directives = {}

    context.keys().forEach(fileName => {
        // Get the component config
        const componentConfig = context(fileName)
        // Get the PascalCase version of the component name
        let tempName = fileName
            .replace(/^\.\/v-/, '')
            .replace(/\.\w+$/, '')
        const componentName = helpers.pascalCase(tempName)

        // console.log('directives fileName', fileName)
        // console.log('directives componentName', componentName)
        // console.log('directives componentConfig', componentConfig)

        // Globally register the component
        directives[componentName] = componentConfig.default || componentConfig
    })
    // console.log('directives directives', directives)
    return directives
}

/*
 function importAllDirectives(context) {
 const directives = {}

 context.keys().forEach(fileName => {
 if (key === './index.js') return

 const directiveName = key.match(/([\w-]+)\.js$/)[1]
 const directive = context(key).default

 directives[directiveName] = directive
 })

 return directives
 }
 */

const directives = importAllDirectives(directivesContext)

Object.entries(directives).forEach(([ directiveName, DirectiveOptions ]) => {
    Vue.directive(directiveName, DirectiveOptions)
})

/*
 const registerDirectives = () => {
 /!*
 Object.keys(directives).forEach((directiveName) => {
 console.log('directives registerDirectives directiveName', directiveName)
 console.log('directives registerDirectives directives[directiveName]', directives[directiveName])
 Vue.directive(directiveName, directives[directiveName])
 })
 *!/

 Object.entries(directives).forEach(([ directiveName, DirectiveOptions ]) => {
 Vue.directive(directiveName, DirectiveOptions)
 })

 }
 */

// export default registerDirectives
