// import axios from "axios"
// import router from "@/router"
// import vuex from "@/store/vuex"

// this.$store.state['freemium/upgradeDialogIsVisible']
const state = () => ({
    upgradeDialogIsVisible: false,
})

// this.$store.getters['freemium/upgradeDialogIsVisible']
const getters = {
    upgradeDialogIsVisible: (state, getters, rootState, rootGetters) => {
        // console.log('VUEX auth getters jwt')
        return state.upgradeDialogIsVisible
    },
}

// $store.dispatch('freemium/authRedirect')
const actions = {}

// this.$store.commit('freemium/SET_UPGRADE_DIALOG_IS_VISIBLE', true)
const mutations = {
    TOGGLE_UPGRADE_DIALOG_IS_VISIBLE: (state, payload) => {
        console.log('TOGGLE_UPGRADE_DIALOG_IS_VISIBLE', state.upgradeDialogIsVisible)
        state.upgradeDialogIsVisible = !state.upgradeDialogIsVisible
    },
    HIDE_UPGRADE_DIALOG: (state, payload) => {
        console.log('HIDE_UPGRADE_DIALOG', state.upgradeDialogIsVisible)

        state.upgradeDialogIsVisible = false
    },
    SHOW_UPGRADE_DIALOG: (state, payload) => {
        console.log('SHOW_UPGRADE_DIALOG', state.upgradeDialogIsVisible)

        state.upgradeDialogIsVisible = true
    },
    SET_UPGRADE_DIALOG_IS_VISIBLE: (state, payload) => {
        console.log('SET_UPGRADE_DIALOG_IS_VISIBLE payload', payload)

        state.upgradeDialogIsVisible = payload
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
