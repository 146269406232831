import { get, has, set } from 'lodash'

const state = () => ({
    preferences: JSON.parse(localStorage.getItem('preferences')) || {},
})

// this.$store.getters['preferences/preferenceAtPath']('pagination.perPage')
const getters = {
    preferences: (state, getters, rootState, rootGetters) => {
        // console.log('VUEX preferences getters preferences')
        return state.preferences
    },
    preferenceAtPath: (state, getters, rootState, rootGetters) => (path) => {
        // console.log('VUEX preferences getters preferenceAtPath path', path)
        let preferences = state.preferences
        // console.log('VUEX preferences getters preferenceAtPath preferences', preferences)
        // console.log('VUEX preferences getters preferenceAtPath path', path)


        let preference = get(preferences, path)
        // let preference = get(preferences, path, null)


        // console.log('VUEX preferences getters preferenceAtPath preference', preference)
        // console.log('VUEX preferences getters preferenceAtPath preference typeof!!!!!!!!!!!!!!!!!!!!!!!!!!!!', typeof preference)
        return preference
    },
    hasPreferenceAtPath: (state, getters, rootState, rootGetters) => (path) => {
        // console.log('VUEX preferences getters preferenceAtPath')
        let preferences = state.preferences
        // console.log('VUEX preferences getters preferenceAtPath preferences', preferences)
        // console.log('VUEX preferences getters preferenceAtPath path', path)
        let hasPreference = has(preferences, path)
        // console.log('VUEX preferences getters preferenceAtPath preference', preference)
        // console.log('VUEX preferences getters preferenceAtPath preference typeof!!!!!!!!!!!!!!!!!!!!!!!!!!!!', typeof preference)
        return hasPreference
    },
}

// $store.dispatch('preferences/setPreferenceAtPath')
const actions = {}

// this.$store.commit('preferences/SET_PREFERENCE_AT_PATH', payload)
const mutations = {
    SET_PREFERENCE_AT_PATH: (state, payload) => {
        console.log('VUEX preferences mutations SET_PREFERENCE_AT_PATH payload', payload)
        let preferences = JSON.parse(JSON.stringify(state.preferences))
        // console.log('VUEX preferences mutations SET_PREFERENCE_AT_PATH preferences copy', preferences)
        set(preferences, payload.path, payload.preference)
        // console.log('VUEX preferences mutations SET_PREFERENCE_AT_PATH preferences modified', preferences)
        preferences = pruneObject(preferences)
        // console.log('VUEX preferences mutations SET_PREFERENCE_AT_PATH preferences pruned', preferences)
        const preferencesString = JSON.stringify(preferences)
        // console.log('VUEX preferences mutations SET_PREFERENCE_AT_PATH preferences stringify', preferencesString)

        localStorage.setItem('preferences', preferencesString)
        state.preferences = preferences
    },
    SET_PREFERENCES: (state, preferences) => {
        // console.log('VUEX preferences mutations SET_PREFERENCES preferences', preferences)
        const prunedPreferences = pruneObject(preferences)
        state.preferences = prunedPreferences
        const preferencesString = JSON.stringify(prunedPreferences)
        localStorage.setItem('preferences', preferencesString)
    },
}
const pruneObject = (object) => {
    // console.log('pruneObject', object)
    // https://stackoverflow.com/questions/52367849/remove-empty-null-values-from-nested-object-es6-clean-nested-objects
    Object.entries(object).forEach(([ key, value ]) => {
        if (value && typeof value === 'object')
            pruneObject(value)
        if (value &&
            typeof value === 'object' &&
            !Object.keys(value).length ||
            value === null ||
            value === undefined ||
            value.length === 0
        ) {
            if (Array.isArray(object)) {
                object.splice(key, 1)
            } else if (!(value instanceof Date)) {
                delete object[key]
            }
            /*
             if (Array.isArray(object))
             object.splice(key, 1)
             else if (!(value instanceof Date))
             delete object[key]
             */
        }
    })
    return object
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
