
const highchartsOptions = {
  Axis: {
    prototype: {
      allowNegativeLog: true
    }
  },
  chart: {
    animation: false,
    reflow: true,
    styledMode: true,
    style: {
      // fontFamily: 'DMSans'
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", FontAwesome'
    },
    events: {
      /*
            beforePrint: function() {
              this.oldhasUserSize = this.hasUserSize
              this.resetParams = [this.chartWidth, this.chartHeight, false]
              this.setSize(600, 400, false)
            },
      */
      /*
            afterPrint: function() {
              this.setSize.apply(this, this.resetParams)
              this.hasUserSize = this.oldhasUserSize
            }
      */
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  lang: {
    noData: 'No Data to Display'
  },
  time: {
    useUTC: false
  }
}

export { highchartsOptions }
