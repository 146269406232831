<!--
  - Copyright ThreatBlockr Inc 2024
  - ejohnson created
  -->

<script>
  import Vue from 'vue'
  import VTooltip from 'vuetify/lib/components/VTooltip/VTooltip'

  // Create Base Mixins and Define Custom Properties
  const base = Vue.extend({ mixins: [ VTooltip ] })
  export default base.extend({
    name: 'CustomTooltip',
    props: {
      closeDelay: {
        type: [ Number, String ],
        default: 0,
      },
      disabled: Boolean,
      openDelay: {
        type: [ Number, String ],
        default: 100,
      },
      openOnHover: {
        type: Boolean,
        default: true,
      },
      openOnFocus: {
        type: Boolean,
        default: true,
      },
      tag: {
        type: String,
        default: 'span',
      },
      transition: String,
    },
  })

  /*
   export default {
   name: 'BsTooltip',
   props: {
   // proppy https://vuejs.org/guide/components/props.html#prop-validation
   },
   components: {},
   mixins: [],
   data() {
   return {}
   },
   watch: {},
   computed: {},
   methods: {},
   beforeCreate() {},
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUpdate() {},
   updated() {},
   beforeDestroy() {},
   destroyed() {},
   }
   */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
