// import router from '@/router'
import axios from "axios"
import {get} from 'lodash'

// this.$store.state['iocSearch/iocSearchAddress']
const state = () => ({
  iocSearchAddress: null,
  isOpenIocSearchModal: false,

})

// this.$store.getters['iocSearch/isOpenIocSearchModal']
const getters = {
  iocSearchAddress: state => {
    return state.iocSearchAddress
  },
  isOpenIocSearchModal: state => {
    return state.isOpenIocSearchModal
  },

}

// this.$store.dispatch('iocSearch/closeIocSearchModal')
const actions = {
  setIocSearchAddress(context, iocSearchAddress) {
    context.commit('SET_IOC_SEARCH_ADDRESS', iocSearchAddress)
  },
  clearIocSearchAddress(context) {
    context.commit('SET_IOC_SEARCH_ADDRESS', null)
  },
  openIocSearchModal(context, iocSearchAddress) {
    // console.log('vuex openIocSearchModal', iocSearchAddress)
    context.commit('SET_IOC_SEARCH_ADDRESS', iocSearchAddress)
    // console.log('vuex state.iocSearchAddress', context.state.iocSearchAddress)

    context.commit('SET_IS_OPEN_IOC_SEARCH_MODAL', true)
  },
  closeIocSearchModal(context) {
    // console.log('vuex closeIocSearchModal')

    context.commit('SET_IS_OPEN_IOC_SEARCH_MODAL', false)
    context.commit('SET_IOC_SEARCH_ADDRESS', null)
    // console.log('vuex closeIocSearchModal', context.getters.iocSearchAddress)

  },
}

// this.$store.commit('iocSearch/SET_JWT', response.data)
const mutations = {
  SET_IOC_SEARCH_ADDRESS: (state, iocSearchAddress) => {
    state.iocSearchAddress = iocSearchAddress
  },
  SET_IS_OPEN_IOC_SEARCH_MODAL: (state, isOpenIocSearchModal) => {
    state.isOpenIocSearchModal = isOpenIocSearchModal
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

