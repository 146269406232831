// this.$store.state['marketplace/successMessage']

import helpers from '@/helpers/helpers.js'
import { isEqual, merge } from 'lodash'

const state = () => ({
    loading: 0,
    // preset: null,

    datetimeRange: null,

    params: {
        report: 'allowed-blocked',
        subreport: 'reasons',
        reportUuid: '80c65bdc-d4d1-4d76-a171-31edc5f9f848',
    },

    query: {
        preset: null,
        instanceUuid: null,
        policyUuid: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },

})

// this.$store.getters['iocSearch/isOpenMarketplaceSuccessModal']
const getters = {
    isLoading: (state, getters) => {
        return (state.loading > 0)
    },
    preset: (state, getters) => {
        return state.query.preset || null
    },
    datetimeRange: (state, getters) => {
        return state.datetimeRange
    },
    startDatetime: (state, getters) => {
        return state.query.startDatetime
    },
    endDatetime: (state, getters) => {
        return state.query.endDatetime
    },
    instanceUuid: (state, getters) => {
        return state.query.instanceUuid
    },
    policyUuid: (state, getters) => {
        return state.query.policyUuid
    },

    params: (state, getters) => {
        console.log('VUEX getters params', state.params)

        return state.params
    },
    query: (state, getters) => {
        // let stateQuery = state.query
        /*
         let stateQuery = {
         preset: null,
         startDatetime: state.datetimeRange[0],
         endDatetime: state.datetimeRange[1],
         instanceUuid: null,
         policyUuid: null,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         }
         */
        let query = state.query
        if (state.datetimeRange?.length === 2) {
            query = {
                ...query,
                startDatetime: state.datetimeRange[0],
                endDatetime: state.datetimeRange[1],
            }

        }
        return helpers.cleanObject(query)
    },
    route: (state, getters) => {
        console.log('VUEX getters route')

        let route = {
            name: 'reports',
            params: {
                report: state.params.report,
                subreport: state.params.subreport,
            },
            // query: query,
            query: getters.query,
            // query: getters.query,
            // query: helpers.cleanObject(query),
        }
        console.log('VUEX getters route', route)

        return route
    },

}

// $store.dispatch('report/setIsLoading', true)
const actions = {
    setIsLoading: ({ commit, state, getters, dispatch }, isLoading) => {
        console.log('VUEX report actions setIsLoading', isLoading)
        if (isLoading === true) {
            commit('LOADING_INCREMENT')
        } else {
            commit('LOADING_DECREMENT')
        }
    },
    setParameters: ({ commit, state, getters, dispatch }, parameters) => {
        console.log('VUEX report actions setParameters', parameters)
        commit('SET_PARAMETERS', parameters)
    },
}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {
    RESET: (state) => {
        console.log('VUEX mutations RESET')
        state.params = {
            report: 'allowed-blocked',
            subreport: 'reasons',
            reportUuid: '80c65bdc-d4d1-4d76-a171-31edc5f9f848',
        }
        state.query = {
            preset: null,
            // startDatetime: null,
            // endDatetime: null,
            instanceUuid: null,
            policyUuid: null,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        state.datetimeRange = null
    },
    SET_PARAMS: (state, newParams) => {
        console.log('VUEX mutations SET_PARAMS newParams', newParams)
        state.params = newParams
    },
    SET_PRESET: (state, preset) => {
        console.log('VUEX mutations SET_PRESET preset', preset)
        state.query.preset = preset
    },
    SET_REPORT_UUID: (state, value) => {
        console.log('VUEX mutations SET_REPORT_UUID value', value)
        state.params.reportUuid = value
    },
    SET_REPORT: (state, value) => {
        console.log('VUEX mutations SET_REPORT value', value)
        state.params.report = value
    },
    SET_SUBREPORT: (state, value) => {
        console.log('VUEX mutations SET_SUBREPORT value', value)
        state.params.subreport = value
    },

    SET_DATETIME_RANGE: (state, datetimeRange) => {
        console.log('VUEX mutations SET_DATETIME_RANGE', datetimeRange)
        state.datetimeRange = datetimeRange
        if (datetimeRange?.length) {
            const startDatetime = datetimeRange[0]
            const endDatetime = datetimeRange[1]
            state.query.startDatetime = startDatetime
            state.query.endDatetime = endDatetime
        }
    },
    SET_STARTDATETIME: (state, startDatetime) => {
        console.log('VUEX mutations SET_STARTDATETIME', startDatetime)
        // let datetimeRange = state.datetimeRange
        // let range = [
        //     ...state.datetimeRange,
        // ]

        state.datetimeRange[0] = startDatetime
        // const datetimeRange = state.datetimeRange

        /*
         const startDatetime = datetimeRange[0]
         const endDatetime = datetimeRange[1]
         if (datetimeRange[0]) {

         }
         */
        state.query.startDatetime = startDatetime
    },
    SET_ENDDATETIME: (state, endDatetime) => {
        console.log('VUEX mutations SET_ENDDATETIME', endDatetime)
        state.query.endDatetime = endDatetime
    },

    SET_INSTANCEUUID: (state, instanceUuid) => {
        console.log('VUEX mutations SET_INSTANCEUUID', instanceUuid)
        state.query.instanceUuid = instanceUuid
    },
    SET_POLICYUUID: (state, policyUuid) => {
        console.log('VUEX mutations SET_POLICYUUID', policyUuid)
        state.query.policyUuid = policyUuid
    },

    LOADING_INCREMENT: (state) => {
        state.loading++
    },
    LOADING_DECREMENT: (state) => {
        state.loading--
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

