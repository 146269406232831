// https://vee-validate.logaretm.com/v3/guide/rules.html#rules

// import {isIP} from 'is-ip'
import { extend } from 'vee-validate'
import * as built_in_rules from 'vee-validate/dist/rules'
import { default as localeEnJson } from 'vee-validate/dist/locale/en.json'
// import {messages} from 'vee-validate/dist/locale/en.json'
import helpers from '@/helpers/helpers.js'
import { capitalize } from 'lodash'
import branding from '/branding.config.js'
// import branding from '@/src/threatblockr-web/branding.config.js'
// import branding from '/Users/ejohnson/src/threatblockr-web/branding.config.js'








/*
 extend("min", {
 validate(value, { min }) {
 return value >= min;
 },
 params: ["min"],
 message: (fieldName, placeholders) => {
 return `please enter at least ${placeholders.min} for ${fieldName}`;
 }
 });

 _value_ === value entered.
 _rule_ === rule name that triggered the message.
 */

const custom_rules = {
    /*
     isBefore: {
     validate: (value, args) => {
     //isBefore
     return false
     },
     message: (fieldName, placeholders) => {
     return `The ${fieldName} must be before other date`
     }
     },
     */

    /*
     isAfter: {
     validate: (value, args) => {
     //isBefore
     return false
     },
     message: (fieldName, placeholders) => {
     return `The ${fieldName} must be after other date`
     }
     },
     */
    /*
     <ValidationProvider rules="required|password:@confirm" v-slot="{ errors }">

     passwordConfirm: {
     required_if: {
     target: 'password',
     },
     confirmed: {
     target: 'password',
     },
     }

     */

    /*
     extend('password', {
     params: ['target'],
     validate(value, { target }) {
     return value === target;
     },
     message: 'Password confirmation does not match'
     });
     */
    reportSelectRequired: {
        validate: (value, args) => {
            // let retVal = false
            // const hasKey = Object.hasOwn(value, 'uuid')
            return Object.hasOwn(value, 'uuid') && value.uuid !== null
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field is required`
        },
    },

    letterNumbersDashes: {
        validate: (value, args) => {
            return /^[A-Za-z0-9-]*$/.test(value)
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field may only contain letters numbers and dashes`
        },
    },

    requireAtLeast: {
        params: [ 'count' ],
        computesRequired: true,
        allowFalse: false,
        validate: (value, { count }) => {
            // console.log('requireAtLeast validate value', value)
            // console.log('requireAtLeast validate value.length', value.length)
            // console.log('requireAtLeast validate count', count)
            let retVal = {
                required: true,
                valid: (value.length >= count),
            }

            // console.log('requireAtLeast validate retVal', retVal)
            return retVal

            // return retVal
        },
        message: (fieldName, { count }) => {
            let retVal

            if (count === 1) {
                retVal = `At least ${ count } ${ fieldName } item is required`
            } else if (count > 1) {
                retVal = `At least ${ count } ${ fieldName } items are required`
            }
            return retVal
            // return `At least ${length} ${fieldName} are required. message ${message}`
        },
    },

    validSourceTypeListTypeCombo: {
        params: [ 'target', 'targetValue' ],
        validate: (value, { target, targetValue }) => {

            if (!targetValue) return true

            // no manual threat
            // no threat domain
            const comboLookup = {
                manual: [
                    'block',
                    'allow',
                    // 'threat',
                ],
                plugin: [
                    'block',
                    'allow',
                    'threat',
                ],

                block: [
                    'manual',
                    'plugin',
                ],
                allow: [
                    'manual',
                    'plugin',
                ],
                threat: [
                    // 'manual',
                    'plugin',
                ],
            }
            let lookupValues = comboLookup[targetValue]
            // console.log('validSourceTypeListTypeCombo targetValue', targetValue)
            // console.log('validSourceTypeListTypeCombo lookupValues', lookupValues)

            // let retVal = ['valueType'].includes(value)
            let retVal = lookupValues.includes(value)
            // console.log('validSourceTypeListTypeCombo retVal', retVal)

            return retVal
        },
        message: (fieldName, params) => {
            // console.log('validSourceTypeListTypeCombo pluginType message fieldName', fieldName)
            // console.log('validSourceTypeListTypeCombo pluginType message params', params)
            let messageString
            // let valueType

            const source = [
                'manual',
                'plugin',
            ]
            const listType = [
                'block',
                'allow',
                'threat',
            ]

            let paramsTarget = capitalize(params._targetValue_)
            let paramsValue = capitalize(params._value_)

            if (listType.includes(params._value_)) {
                messageString = `${ branding.webApp } does not yet support ${ paramsTarget } ${ paramsValue } Lists`
            } else if (source.includes(params._value_)) {
                messageString = `${ branding.webApp } does not yet support ${ paramsValue } ${ paramsTarget } Lists`
            } else {
                messageString = 'Unknown Error'
            }

            return messageString
        },
    },
    validListTypeIocTypeCombo: {
        params: [ 'target', 'targetValue' ],
        validate: (value, { target, targetValue }) => {

            if (!targetValue) return true

            // no manual threat
            // no threat domain
            const comboLookup = {
                block: [
                    'ip',
                    'domain',
                    'manual',
                    'plugin',
                ],
                allow: [
                    'ip',
                    'domain',
                    'manual',
                    'plugin',
                ],
                threat: [
                    'ip',
                    // 'domain',
                    // 'manual',
                    'plugin',
                ],
                ip: [
                    'block',
                    'allow',
                    'threat',
                ],
                domain: [
                    'block',
                    'allow',
                    // 'threat',
                ],
            }
            let lookupValues = comboLookup[targetValue]
            // console.log('pluginType validate lookupValue', lookupValue)

            // let retVal = ['valueType'].includes(value)
            let retVal = lookupValues.includes(value)
            return retVal
        },
        message: (fieldName, params) => {
            // console.log('pluginType message fieldName', fieldName)
            // console.log('pluginType message params', params)
            let messageString
            // let valueType

            const listType = [
                'block',
                'allow',
                'threat',
            ]
            const iocType = [
                'ip',
                'domain',
            ]

            let paramsTarget = capitalize(params._targetValue_)
            let paramsValue = capitalize(params._value_)

            if (listType.includes(params._value_)) {
                messageString = `${ branding.webApp } does not yet support ${ paramsTarget } ${ paramsValue } Lists`
            } else if (iocType.includes(params._value_)) {
                messageString = `${ branding.webApp } does not yet support ${ paramsValue } ${ paramsTarget } Lists`
            } else {
                messageString = 'Unknown Error'
            }

            return messageString
        },
    },

    validWith: {
        params: [ 'target' ],
        validate: (value, { target }) => {

            if (!target) return true

            const comboLookup = {
                block: [
                    'ip',
                    'domain',
                ],
                allow: [
                    'ip',
                    'domain',
                ],
                threat: [
                    'ip',
                    // 'domain',
                ],
                ip: [
                    'block',
                    'allow',
                    'threat',
                ],
                domain: [
                    'block',
                    'allow',
                    // 'threat',
                ],
            }
            let lookupValue = comboLookup[value]
            // console.log('pluginType validate lookupValue', lookupValue)

            // let retVal = ['valueType'].includes(value)
            let retVal = lookupValue.includes(target)
            return retVal
        },
        message: (fieldName, params) => {
            console.log('validWith pluginType message fieldName', fieldName)
            console.log('validWith pluginType message params', params)
            let messageString
            // let valueType

            const listTypes = [
                'block',
                'allow',
                'threat',
            ]
            const iocTypes = [
                'ip',
                'domain',
            ]

            let paramsTarget = capitalize(params._target_)
            let paramsValue = capitalize(params._value_)

            if (listTypes.includes(params._value_)) {
                // console.log('pluginType message listTypes')
                messageString = `${ branding.webApp } does not yet support ${ paramsTarget } ${ paramsValue } Lists`
            } else if (iocTypes.includes(params._value_)) {
                // console.log('pluginType message iocTypes')
                messageString = `${ branding.webApp } does not yet support ${ paramsValue } ${ paramsTarget } Lists`
            } else {
                // console.log('pluginType message else params.value', params._value_)
                messageString = 'Unknown Error'
            }

            return messageString
        },
    },

    ipv4OrDomain: {
        validate: (value, args) => {
            let retVal = helpers.isIP(value) || helpers.isFQDN(value)
            return retVal
        },
        message: (fieldName, params, data) => {
            return `The ${ fieldName } field must be a valid IP address or domain`
        },
    },

    url: {
        validate: (value, {
            require_protocol,
            protocols,
        }) => {
            const options = {
                require_protocol: require_protocol,
                protocols: protocols,
            }
            let retVal = helpers.isURL(value, options)
            return retVal
        },
        params: [ 'require_protocol', 'protocols' ],
        message: (fieldName, {
            require_protocol,
            protocols,
        }) => {

            return `The ${ fieldName } field must be a valid URL`
        },
    },

    mustCheck: {
        validate: value => {
            console.log('mustCheck value', value)
            return value === true
        },
        params: [ 'message' ],
        message: (fieldName, { message }) => {
            // message: (fieldName, [message]) => {
            console.log('mustCheck message', message)

            // return '{message}'
            return `${ message }`
        },
    },

    saTransactionNumber: {
        validate: (value, args) => {
            let retVal = /^.*\.\d+$/.test(value)
            return retVal
        },
        message: (fieldName, params, data) => {
            return `The ${ fieldName } field must be in the format xxxxx.n`
        },
    },

    allowedChars: {
        validate: (value, args) => {
            return /^[a-zA-Z0-9_\-., $@()=+]+$/.test(value)
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field may only contain letters numbers spaces and _-., $@()=+`
        },
    },

    port: {
        validate: (value, args) => {
            return Number(1) <= value && Number(65535) >= value
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field must be between 1 and 65535`
        },
    },

    // domain: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} field must be a valid domain.`
    //   },
    //   validate: (value, args) => {
    //     let retVal = isValidDomain(value)
    //     return retVal
    //   }
    // },

    /*
     domain: {
     validate: (value, args) => {
     let retVal = helpers.isFQDN(value)
     return retVal
     },
     message: (fieldName, placeholders) => {
     return `The ${ fieldName } field must be a valid domain`
     }
     },
     */

    domain: {
        validate: (value, args) => {
            if (value.startsWith('*.')) {
                value = value.slice(2)
                console.log('domainOrTld', value)
            }
            if (value.startsWith('.')) {
                value = value.slice(1)
                console.log('domainOrTld', value)
            }
            if (value.endsWith('.')) {
                value = value.slice(0, value.length - 1)
                console.log('domainOrTld', value)
            }

            let tldRegex = new RegExp('(?=^.{2,253}$)(^((?!-)[a-zA-Z0-9-_]{1,63}(?<!-)\\.)*[a-zA-Z_][a-zA-Z0-9-_]{1,62}(?<!-)$)')
            return tldRegex.test(value)
        },
        message: (fieldName, params, data) => {
            return `The ${ fieldName } field must be a valid domain or TLD.`
        },
    },

    ip: {
        validate: (value, args) => {
            let retVal = helpers.isIP(value)
            return retVal
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field must be a valid IP address`
        },
    },

    ipv4Cidr: {
        validate: (value, args) => {
            console.log('ipv4Cidr validate')
            let retVal = helpers.isIpv4Cidr(value)
            return retVal
        },
        message: (fieldName, params, data) => {
            console.log('ipv4Cidr message')

            return `The ${ fieldName } field must be a valid IP CIDR address`
        },
    },


    maskbits: {
        validate: (value, args) => {
            return Number(0) <= value && Number(32) >= value
        },
        message: (fieldName, placeholders) => {
            return `The ${ fieldName } field must be between 0 and 32.`
        },
    },

    array_not_empty: {
        validate(value, args) {
            return value >= args.min
        },
        params: [ 'min' ],
        message: (fieldName, placeholders) => {
            return `Add some IPs to this Network`
            // return `please enter at least ${placeholders.min} for ${fieldName}`;
        },
    },


    /*
     minSelect: {
     validate: (value, [length]) => {
     return value.length >= length
     },
     message: (fieldName, [length]) => {
     return `At least ${length} items must be selected.`
     }
     },
     */

    // pluginSelectionRequired: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} field is required`
    //   },
    //   validate: (value, args) => {
    //     let retVal = value.uuid !== ''
    //     return retVal
    //   }
    // },






    // mtu: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} field must be between 68 and 1500.`
    //   },
    //   validate: (value, args) => {
    //     return Number(68) <= value && Number(1500) >= value
    //   }
    // },


    // maskbitsIpv6: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} field must be between 0 and 128.`
    //   },
    //   validate: (value, args) => {
    //     return Number(0) <= value && Number(128) >= value
    //   }
    // },


    // userPasswordRegex: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} field may only contain lower case, upper case , numeric, and special characters`
    //   },
    //   validate: value => {
    //     let strongRegex = new RegExp('^[A-Za-z0-9!"#$%&\'()*+,\\-.\\/:;<=>?@[\\\\\\]^_{|}~\\]]+$')
    //     return strongRegex.test(value)
    //   }
    // },

    // deviceRegistrationCode: {
    //   getMessage: (fieldName, params, data) => {
    //     return `The ${fieldName} is invalid`
    //   },
    //   validate: value => {
    //     let strongRegex = new RegExp('^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}$')
    //     return strongRegex.test(value)
    //   }
    // }
}

Object.keys(built_in_rules).forEach(rule => {
    extend(rule, {
        ...built_in_rules[rule],
        message: localeEnJson.messages[rule],
        // message: messages[rule]
    })
})

Object.keys(custom_rules).forEach(rule => {
    extend(rule, custom_rules[rule])
})

// console.log('VeeValidators loaded')
