import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store/vuex'
import branding from '/branding.config.js'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    base: process.env.BASE_URL,
})

router.beforeEach((to, from, next) => {
    // console.log('beforeEach from', from)
    // console.log('beforeEach to', to.name)

    document.title = to.meta.title ? to.meta.title : branding.company
    // document.title = to.meta.title ? to.meta.title : branding.software

    const authed = store.getters['auth/authorized']
    // console.log('beforeEach store.getters[auth/authorized]', authed)


    if (authed) {
        // console.log('beforeEach user is authed')
        next()
    } else if (to.meta.requiresAuthorization === false) {
        // } else if (dmzRoutes.includes(to.name)) {
        //   console.log('beforeEach else dmzRoutes', to.name)
        //   console.log('beforeEach route doesnt require auth', to.name)
        next()
    } else {
        store.dispatch('auth/setNextRedirect', to)
            .then(r => {})
        // console.log('redirectUrl', to.fullPath)
        // console.log('beforeEach goto authRedirect')
        next({ name: 'authRedirect' })
    }

    next()
})

router.afterEach((to, from) => {
    // store.commit('flash/REMOVE_VIEWED_FLASHES')
})

export default router
