<!-- https://github.com/leezng/vue-json-pretty/tree/1.x -->
<template>
  <!--  <div>-->
  <div v-if="computedSuppressDebug === false && computedIsDevelopment">

    <pre v-if="old"
         class="debug"
         style="padding: 1rem;">{{ value }}</pre>

    <vue-json-pretty v-else
                     :data="value"
                     :deep="deep"
                     :show-length="true"
                     :show-line="false"

                     :virtual="false"

                     class="debug"/>
  </div>
  <!--
    <div v-if="computedSuppressDebug === false"
         v-dev-only>

      &lt;!&ndash;
          <vue-json-pretty :data="value"
                           class="debug"
                           v-bind="props"/>
      &ndash;&gt;

      <pre class="debug"
           style="padding: 1rem;">{{ value }}</pre>
    </div>
  -->
</template>

<script>
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'

  export default {
    name: 'bsPrint',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation
      deep: {
        type: Number,
        default: 2,
      },

      value: {
        type: [ Object, Array, String, Boolean ],
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        default(rawProps) {
          return {}
        },
      },
      old: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      'vue-json-pretty': VueJsonPretty,
    },
    mixins: [],
    data() {
      return {
        entityDisplayName: 'bsPrint',
        /*
         props: {
         deep: this.depth,
         showLength: true,
         showLine: false,
         // virtual: true,
         // height: 300,
         },
         */
      }
    },
    watch: {},
    computed: {
      computedJsonPrettyProps: function() {
        console.log('computedJsonPrettyProps')
        return {
          // deep: 1,
          // deep: this.deep,
          showLength: true,
          showLine: false,
          // virtual: true,
          // height: 300,
        }
      },
      computedAllowProd: function() {
        // console.log('computedSuppressDebug')
        return this.$allowProd
      },
      /*
       computedSuppressDebug: function() {
       // console.log('computedSuppressDebug $suppressDebug', this.$suppressDebug)
       // console.log('computedSuppressDebug $scooby', this.$scooby)
       return this.$suppressDebug
       },
       */
      /*
       computedIsDevelopment: function() {
       // console.log('computedIsDevelopment')
       return this.$environment === 'development'
       // return process.env.NODE_ENV
       },
       */

    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  :deep(.debug) {
    font-family: monospace, monospace;
    font-size: .75rem;
    margin-top: 16px;
    padding: 0;
    color: #0be6e6;
    background-color: #343a40;

    .vjs-tree-node.is-highlight,
    .vjs-tree-node:hover {
      background-color: #292d31;
    }
  }
</style>
