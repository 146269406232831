import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
// import router from '@/router'
import base from '@/store/modules/base'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import flash from '@/store/modules/flash'
import freemium from '@/store/modules/freemium'
import message from '@/store/modules/message'
import iocSearch from '@/store/modules/iocSearch'
import report from '@/store/modules/report'
import marketplace from '@/store/modules/marketplace'
import preferences from '@/store/modules/preferences'
import unexpectedBlock from '@/store/modules/unexpectedBlock'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuex = new Vuex.Store({
    modules: {
        base,
        auth,
        user,
        flash,
        freemium,
        message,
        iocSearch,
        report,
        marketplace,
        preferences,
        unexpectedBlock,
    },
    strict: debug,
})

export default vuex
