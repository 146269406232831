import axios from 'axios'
import { groupBy, set, uniqBy } from 'lodash'

const state = () => ({
    focused: false,
    hasSomeReporting: false,
    jobsRepeat: null,

    jobs: [],
    // firstJob: null,

    isJobResultsBusy: false,
    isJobsBusy: false,

    results: null,
    extendedInfo: {},
    /*
     results: {
     parameters: {},
     entries: [],
     },
     */
    flashId: null,
})

const MOCK_CALL_DELAY = 50

// const POLL_FREQUENCY = 5000
// const POLL_FREQUENCY = 10000
// const POLL_FREQUENCY = 15000
// const POLL_FREQUENCY = 25000
const POLL_FREQUENCY = 30000
// const POLL_FREQUENCY = 60000

// this.$store.getters['iocSearch/isOpenMarketplaceSuccessModal']
const getters = {
    jobResultsByEvent: (state, getters) => {
        return state.results.entries
    },
    jobResultsMappedByAddress: (state, getters) => {
        console.log('getJobResultsMappedByAddress')
        let retVal
        // retVal = groupBy(this.jobResultsGrouped, 'address')
        retVal = groupBy(state.results?.entries, 'address')
        return retVal
    },
    jobResultsMappedByAddressRollups: (state, getters) => {
        console.log('getJobResultsMappedByAddressRollups')

        let rowsGroupedByAddress = getters.jobResultsMappedByAddress
        // let rowsGroupedByAddress = this.getJobResultsMappedByAddress(rowItems)

        let itemRollupsMappedByAddress = {}
        Object.entries(rowsGroupedByAddress).forEach(([ address, rows ]) => {

            let instances = rows.map(object => {
                return object.instance
            })
            let lists = rows.map(object => {
                return object.lists
            }).flat()
            let threatInfo = rows.map(object => {
                return object.threatInfo
            }).flat()

            let uniqInstances = uniqBy(instances, 'uuid')
            let uniqLists = uniqBy(lists, 'uuid')
            let uniqThreatInfo = uniqBy(threatInfo, 'category')

            set(itemRollupsMappedByAddress, [ address, 'instance' ], uniqInstances)
            set(itemRollupsMappedByAddress, [ address, 'list' ], uniqLists)
            set(itemRollupsMappedByAddress, [ address, 'threatInfo' ], uniqThreatInfo)

        })

        return itemRollupsMappedByAddress
    },
    hasJobResults: (state, getters) => {
        // console.log('VUEX UB getters hasJobResults results', getters.results)
        return getters.results !== null
        // return getters.results
        // return getters.results?.entries.length > 0
    },
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    jobResults: (state, getters) => {
        let events = state.results.entries.map(object => {
            return object.events
        }).flat()

        return events
    },
    jobResultsGrouped: (state, getters) => {
        let jobResults = state.results.entries

        for (const group of jobResults) {
            group.instance = group.instances
            group.datetime = group.datetimeRange
            group.reason = group.reasons
            group.blockingPolicy = group.blockingPolicies
            delete group.instances
            delete group.datetimeRange
            delete group.reasons
            delete group.blockingPolicies
        }
        let retVal = jobResults.map((group, groupIndex) => {
            let events = group.events
            events = events.map((event, eventIndex) => {
                event = {
                    ...event,
                    _eventIndex: `${ groupIndex }-${ eventIndex }`,
                }
                return event
            })
            group = {
                ...group,
                _groupIndex: `${ groupIndex }`,
                events: events,
            }
            return group
        })
        console.log('VUEX UB getters jobResultsGrouped jobResults', jobResults)

        return retVal
        // return jobResults
    },
    jobResultsGroupedOld: (state, getters) => {
        console.log('======================================= START VUEX getters jobResultsGrouped')

        let retVal

        let coercedArray = []
        let jobResults = state.results?.entries
        let rowsGroupedByAddress = groupBy(jobResults, 'address')

        // let rowsGroupedByAddress = this.getJobResultsMappedByAddress(rowItems)
        console.log('VUEX getters jobResultsGrouped rowsGroupedByAddress', rowsGroupedByAddress)

        // console.log('computedTableItemsGrouped retVal', retVal)

        // coerce from event based to group based
        for (const [ address, events ] of Object.entries(rowsGroupedByAddress)) {
            console.log('VUEX getters jobResultsGrouped address', address)
            console.log('VUEX getters jobResultsGrouped events', events)

            const firstEvent = events[0]
            const lastEvent = events[events.length - 1]

            let datetimeRange = [
                firstEvent.datetime,
                lastEvent.datetime,
            ]
            let groupedObject = {
                _id: address,
                datetime: datetimeRange,
                address: address,
                destinationPort: firstEvent.destinationPort,
                ipProtocol: firstEvent.ipProtocol,
                instances: getters.jobResultsMappedByAddressRollups[address].instance,
                // instances: this.computedItemRollupsMappedByAddress[address].instance,
                lists: getters.jobResultsMappedByAddressRollups[address].list,
                // lists: this.computedItemRollupsMappedByAddress[address].list,
                threatInfo: getters.jobResultsMappedByAddressRollups[address].threatInfo,
                // threatInfo: this.computedItemRollupsMappedByAddress[address].threatInfo,

                blockingPolicies: [
                    {
                        uuid: 'dc8ad317-f067-4e65-9c69-2d039d775e01',
                        name: 'kvm-demo1 - outbound',
                        description: '',
                    },
                    {
                        uuid: '09cc3541-7b6b-4bfe-8f02-4e43c7e86307',
                        name: 'kvm-demo2 - outbound',
                        description: '',
                    },
                ],

                reason: firstEvent.reason,

                _isVisibleExtendedInfo: false,
                extendedInfo: {
                    asn: {
                        asn: firstEvent.asn.asn,
                        name: firstEvent.asn.name,
                    },
                    country: {
                        isoCode2: firstEvent.country.isoCode2,
                        name: firstEvent.country.name,
                    },
                },

                events: events,

            }
            coercedArray = [
                ...coercedArray,
                groupedObject,
            ]
        }
        console.log('VUEX getters jobResultsGrouped coercedArray', coercedArray)
        console.log('///////////////////////////////////////// END VUEX getters jobResultsGrouped')
        return coercedArray
    },
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    jobs: (state, getters) => {
        return state.jobs
    },
    firstJob: (state, getters) => {
        let firstJob = state.jobs[0]
        return firstJob
    },
    jobCount: (state, getters) => {
        return state.jobs.length
    },
    hasJobs: (state, getters) => {
        return getters.jobCount > 0
    },

    hasSomeReporting: (state, getters) => {
        // console.log('VUEX UB getters focused')
        let foo = getters.firstJob?.instances.some(object => {
            return object.status === 'COMPLETE'
        })
        return foo
    },
    focused: (state, getters) => {
        // console.log('VUEX UB getters focused')
        return state.focused
    },
    pollFrequency: (state, getters) => {
        // console.log('VUEX UB getters pollFrequency')

        // const currentRoute = router.currentRoute.name
        // console.log('VUEX UB getters pollFrequency POLL_FREQUENCY', POLL_FREQUENCY)
        // console.log('VUEX UB getters pollFrequency currentRoute', currentRoute)

        let pollFrequency = POLL_FREQUENCY
        let isFocused = state.focused
        let hasSomeReporting = getters.hasSomeReporting

        if (isFocused) {
            pollFrequency = pollFrequency / 3
        }
        if (hasSomeReporting) {
            pollFrequency = pollFrequency / 2
        }
        // console.log('VUEX UB getters isFocused', isFocused)
        // console.log('VUEX UB getters hasSomeReporting', hasSomeReporting)
        // console.log('VUEX UB getters pollFrequency', pollFrequency)

        return pollFrequency
    },
    results: (state, getters) => {
        return state.results
    },
    resultParameters: (state, getters) => {
        return state.results?.parameters
    },
    status: (state, getters) => {
        // console.log('VUEX UB getters status')
        // console.log('VUEX UB getters status.hasJobs', getters.hasJobs)
        // console.log('VUEX UB getters status.firstJob?.status', getters.firstJob?.status)
        // console.log('VUEX UB getters status.isJobResultsBusy', getters.isJobResultsBusy)
        // console.log('VUEX UB getters status.hasJobResults', getters.hasJobResults)

        let status = getters.firstJob?.status
        if (status === 'COMPLETE' && getters.hasJobResults === false) {
            status = 'FETCHING'
        }
        // console.log('VUEX UB getters status /////////////////////', status)

        return status
    },
    flashId: (state, getters) => {
        return state.flashId
    },
    hasIncompleteJob: (state, getters) => {
        let hasIncompleteJob = false
        let hasJobs = getters.hasJobs
        if (hasJobs) {
            let status = getters.firstJob.status
            hasIncompleteJob = status !== 'COMPLETE'
        }
        return hasIncompleteJob
    },
    hasFailedJob: (state, getters) => {
        // console.log('VUEX UB getters hasFailedJob')

        let hasFailedJob = false
        let hasJobs = getters.hasJobs
        if (hasJobs) {
            let status = getters.firstJob.status
            // console.log('VUEX UB getters hasFailedJob status', status)

            hasFailedJob = status === 'FAILED'
        }
        // console.log('VUEX UB getters hasFailedJob', hasFailedJob)

        return hasFailedJob
    },
    hasCompleteJob: (state, getters) => {
        return getters.firstJob?.status === 'COMPLETE'
    },
    isJobResultsBusy: (state, getters) => {
        return state.isJobResultsBusy
    },
    isJobsBusy: (state, getters) => {
        return state.isJobsBusy
    },
    jobsRepeat: (state, getters) => {
        return state.jobsRepeat
    },
    extendedInfo: (state, getters) => {
        // console.log('VUEX UB getters focused')
        return state.extendedInfo
    },
}

// $store.dispatch('auth/authRedirect')
const actions = {
    setFocused(context, focused) {
        context.commit('SET_FOCUSED', focused)
    },
    initiateLogAnalysis: ({ commit, state, getters, dispatch, rootState, rootGetters }, parameters) => {
        // console.log('VUEX UB actions initiateLogAnalysis parameters ///////////////////////////', parameters)

        commit('RESET_JOB_RESULTS')
        // dispatch('stopJobsRepeat')

        let apiEndpoint = '/jobs/instances/blocks'

        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////

        // let mock = new MockAdapter(axios, { delayResponse: MOCK_CALL_DELAY })
        /*
         let payload = {
         uuid: faker.string.uuid(),
         startDatetime: startDatetime,
         endDatetime: endDatetime,
         status: 'SCHEDULED',
         instances: instances,
         }
         */
        // console.log('VUEX UB actions initiateLogAnalysis payload', payload)
        // mock.onPost(apiEndpoint).reply(200, payload)

        // end mock stuff ///////////////////////////////////////////////////////////////////////////////
        // end mock stuff ///////////////////////////////////////////////////////////////////////////////
        // end mock stuff ///////////////////////////////////////////////////////////////////////////////

        axios.post(apiEndpoint, parameters)
            .then(response => {
                // console.log('VUEX UB actions initiateLogAnalysis axios.post', response.data)

                // set the status
                commit('SET_JOBS', [ response.data ])
                // create a repeating checker
                // dispatch('repeatJob')

                const dispatchObject = {
                    type: 'flash/addFlash',
                    flashType: 'info',
                    title: 'Analysis Scheduled',
                    progress: response.data,
                }

                /*
                 dispatch(dispatchObject, { root: true })
                 .then(result => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash then', result)
                 // commit('SET_JOB_FLASH_ID', result.id)
                 })
                 .catch(error => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash catch', error)
                 })
                 .finally(() => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash finally')
                 // mock.restore()
                 })
                 */
            })
            .catch(error => {
                // console.log('VUEX UB actions initiateLogAnalysis catch', error)
            })
            .finally(() => {
                // console.log('VUEX UB actions initiateLogAnalysis finally')
                // mock.restore()
            })

    },
    getJobs: ({ commit, state, getters, dispatch }) => {
        // console.log('VUEX UB actions getJobs')
        const isJobResultsBusy = state.isJobResultsBusy
        const isJobsBusy = state.isJobsBusy
        const hasFailedJob = getters.hasFailedJob

        // console.log('VUEX UB actions getJobs isJobsBusy', isJobsBusy)
        // console.log('VUEX UB actions getJobs isJobResultsBusy', isJobResultsBusy)
        // console.log('VUEX UB actions getJobs hasFailedJob', hasFailedJob)

        if (!isJobsBusy && !isJobResultsBusy && !hasFailedJob) {
            let apiEndpoint = `/jobs/instances/blocks`

            commit('SET_JOBS_BUSY', true)

            axios.get(apiEndpoint)
                .then(response => {
                    let responseData = response.data
                    // console.log('VUEX UB actions getJobs then response.data', JSON.stringify(responseData, null, 1))

                    commit('SET_JOBS', responseData)

                    // if has job
                    if (getters.hasJobs) {
                        // console.log('VUEX UB actions getJobs then hasJobs', getters.hasJobs)

                        dispatch({
                            type: 'flash/setStatusForFlashWithId',
                            progress: {
                                flashId: getters.firstJob.uuid,
                                status: getters.firstJob,
                            },
                        }, { root: true })
                    }

                    // if has completejob
                    console.log('VUEX UB actions getJobs hasCompleteJob', getters.hasCompleteJob)
                    if (getters.hasCompleteJob) {
                        // dispatch('stopJobsRepeat')
                        dispatch('getJobResults')
                    }
                })
                .finally(() => {
                    // console.log('VUEX UB actions getJobs finally')
                    commit('SET_JOBS_BUSY', false)
                    // mock.restore()
                })

        }
    },
    getJobsRepeat: ({ commit, state, getters, dispatch }) => {
        // console.log('VUEX UB actions getJobsRepeat')

        const job = setInterval(() => {
            dispatch('getJobs')
            // dispatch('getJobsRepeat')
        }, getters.pollFrequency)
        // console.log('vuex actions repeatJob jobJob', jobJob)
        commit('SET_JOBS_REPEAT', job)
    },

    stopJobsRepeat: ({ commit, state, getters, dispatch }) => {
        // console.log('vuex actions stopJobsRepeat', getters.jobsRepeat)
        clearTimeout(getters.jobsRepeat)
        // clearInterval(getters.jobsRepeat)
        commit('SET_JOBS_REPEAT', null)
    },
    getJobResults: ({ commit, state, getters, dispatch }) => {
        let apiEndpoint = `/jobs/instances/blocks/${ getters.firstJob.uuid }/data`

        let config = {
            params: {
                groupByAddress: true,
                // sortDirection: 'ascending',
                // sortDirection: 'descending',
            },
        }

        commit('SET_JOB_RESULTS_BUSY', true)

        axios.get(apiEndpoint, config)
            .then(response => {
                let responseData = response.data
                console.log('VUEX UB actions getJobResults then', responseData)


                /*
                 responseData.entries = responseData.entries.map(item => {
                 let lists = item.lists
                 let ghosted = lists.map(object => {
                 return {
                 ...object,
                 _ghost: object.listType === 'allow',
                 }
                 })
                 let sorted = orderBy(ghosted, [ 'listType', 'name' ], [ 'desc', 'asc' ])
                 item.lists = [
                 ...sorted,
                 ]
                 return {
                 ...item,
                 }
                 })
                 */

                console.log('VUEX UB actions getJobResults then sorted responseData', responseData)

                commit('SET_JOB_RESULTS', responseData)
            })
            .finally(() => {
                // console.log('VUEX UB actions getJobResults finally')
                commit('SET_JOB_RESULTS_BUSY', false)
                // mock.restore()
            })
    },

    // extendedInfoForAddress: (commit, state, getters, dispatch) => (address) => {
    extendedInfoForAddress: ({ commit, state, getters, dispatch }, { address }) => {

        console.log('VUEX actions extendedInfoForAddress address', address)
        // let retVal
        let extendedInfo = state.extendedInfo[address]
        console.log('VUEX actions extendedInfoForAddress extendedInfo', extendedInfo)

        if (extendedInfo) {
            console.log('VUEX actions extendedInfoForAddress extendedInfo EXISTS. return it', extendedInfo)
            console.log('VUEX actions extendedInfoForAddress extendedInfo typeof', typeof extendedInfo)

            // retVal = extendedInfo
        } else {
            // fire off get
            console.log('VUEX actions extendedInfoForAddress extendedInfo NOT EXISTS')

            // commit('SET_EXTENDED_INFO', { address: address })
            // retVal = {}

            dispatch('getExtendedInfoForAddress', address)

            /*
             const extendedInfoForAddressPromise = dispatch('getExtendedInfoForAddressPromise', address)
             extendedInfoForAddressPromise
             .then(response => {
             console.log('VUEX UB actions getExtendedInfoForAddress then response', response)
             // let results = getters.results

             let extendedInfo = response.data
             let results = getters.results

             commit('SET_EXTENDED_INFO', extendedInfo)

             // let results = Object.assign({}, getters.results)
             let resultsWithIp = results.entries.filter(object => object.address === address)
             // console.log('VUEX UB actions getExtendedInfoForAddress then resultsWithIp', resultsWithIp)

             // add _hasIpInfo
             // let responseData = response.data
             let entries = results.entries.map(item => {
             // let entries = resultsWithIp.entries.map(item => {
             if (item.address === address) {
             return {
             ...item,
             whois: extendedInfo.whois,
             reverseDns: extendedInfo.reverseDns,
             _hasIpInfo: true,
             }
             } else {
             return item
             }
             /!*
             return {
             ...item,
             whois: extendedInfo.whois,
             reverseDns: extendedInfo.reverseDns,
             _hasIpInfo: true,
             }
             *!/
             })
             commit('SET_JOB_RESULTS_ENTRIES', entries)
             })
             .catch((error) => {
             console.log('VUEX actions extendedInfoForAddress catch', error)

             })
             .finally(() => {
             // console.log('VUEX UB actions getExtendedInfoForAddress finally')
             })
             */

        }
        // console.log('VUEX actions extendedInfoForAddress retVal', retVal)
        console.log('VUEX actions extendedInfoForAddress ////////////////////')

        // return retVal
    },
    getExtendedInfoForAddress: ({ commit, state, getters, dispatch }, address) => {
        console.log('VUEX actions getExtendedInfoForAddress address', address)

        // if (state.extendedInfo[address]) return
        let extendedInfo = state.extendedInfo[address]
        console.log('VUEX actions getExtendedInfoForAddress extendedInfo', extendedInfo)

        if (extendedInfo) {
            return
        }

        let apiEndpoint = `/search/ipinfo/${ address }`

        axios.get(apiEndpoint)
            .then(response => {
                console.log('VUEX UB actions getExtendedInfoForAddress then response.data', response.data)
                // let results = getters.results

                let extendedInfo = response.data
                let results = getters.results

                commit('SET_EXTENDED_INFO', extendedInfo)
                console.log('VUEX UB actions getExtendedInfoForAddress then', state.extendedInfo)

                // let resultsWithIp = results.entries.filter(object => object.address === address)
                // console.log('VUEX UB actions getExtendedInfoForAddress then resultsWithIp', resultsWithIp)

                // add _hasIpInfo
                /*
                 let entries = results.entries.map(item => {
                 // let entries = resultsWithIp.entries.map(item => {
                 if (item.address === address) {
                 return {
                 ...item,
                 whois: extendedInfo.whois,
                 reverseDns: extendedInfo.reverseDns,
                 _hasIpInfo: true,
                 }
                 } else {
                 return item
                 }
                 /!*
                 return {
                 ...item,
                 whois: extendedInfo.whois,
                 reverseDns: extendedInfo.reverseDns,
                 _hasIpInfo: true,
                 }
                 *!/
                 })
                 commit('SET_JOB_RESULTS_ENTRIES', entries)
                 */


            })
            .finally(() => {
                // console.log('VUEX UB actions getExtendedInfoForAddress finally')
            })
    },

    getExtendedInfoForAddressPromise: ({ commit, state, getters, dispatch }, address) => {
        console.log('VUEX actions getExtendedInfoForAddressPromise address', address)

        // if (state.extendedInfo[address]) return
        let extendedInfo = state.extendedInfo[address]
        console.log('VUEX actions getExtendedInfoForAddress extendedInfo', extendedInfo)

        if (extendedInfo) {
            return
        }

        let apiEndpoint = `/search/ipinfo/${ address }`

        return axios.get(apiEndpoint)
    },

}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {
    ////////////////////////////////////////////////
    // start mutations
    ////////////////////////////////////////////////
    SET_EXTENDED_INFO: (state, extendedInfo) => {
        console.log('VUEX mutations SET_EXTENDED_INFO state', state)
        console.log('VUEX mutations SET_EXTENDED_INFO extendedInfo', extendedInfo)
        // state.extendedInfo[ip] = extendedInfo

        /*
         let currentExtendedInfo = state.extendedInfo
         set(currentExtendedInfo, [ extendedInfo.address ], extendedInfo)
         state.extendedInfo = currentExtendedInfo
         */

        state.extendedInfo = {
            ...state.extendedInfo,
            [extendedInfo.address]: extendedInfo,
        }
    },
    SET_FOCUSED: (state, payload) => {
        state.focused = payload
    },

    RESET_JOB_RESULTS: (state) => {
        // console.log('VUEX UB mutations RESET_JOB_RESULTS')
        state.results = null
        /*
         state.results = {
         parameters: {},
         entries: [],
         }
         */

    },
    SET_JOB_RESULTS_BUSY: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS_BUSY', payload)
        state.isJobResultsBusy = payload
    },
    SET_JOBS_BUSY: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOBS_BUSY', payload)
        state.isJobsBusy = payload
    },
    SET_JOB_RESULTS: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS', payload)
        state.results = payload
    },
    SET_JOB_RESULTS_ENTRIES: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS', payload)
        state.results.entries = [
            ...payload,
        ]
    },
    SET_JOBS: (state, payload) => {
        state.jobs = payload
    },
    SET_JOBS_REPEAT: (state, job) => {
        // console.log('vuex mutations SET_JOBS_REPEAT')
        state.jobsRepeat = job
    },
    ////////////////////////////////////////////////
    // end mutations job center
    ////////////////////////////////////////////////

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

