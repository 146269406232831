import axios from 'axios'

// this.$store.state['marketplace/successMessage']
const state = () => ({
    ////////////////////////////////////////////////
    // start repeating timed functions
    ////////////////////////////////////////////////
    messageJob: null,
    ////////////////////////////////////////////////
    // end repeating timed functions
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    // start message center
    ////////////////////////////////////////////////
    messages: [],
    ////////////////////////////////////////////////
    // end message center
    ////////////////////////////////////////////////

})

const POLL_FREQUENCY = 60000

// this.$store.getters['iocSearch/isOpenMarketplaceSuccessModal']
const getters = {
    ////////////////////////////////////////////////
    // start getters message center
    ////////////////////////////////////////////////
    messages: state => {
        return state.messages
    },
    messageJob: state => {
        return state.messageJob
    },
    hasMessages: state => {
        return state.messages.length > 0
    },
    messageCount: state => {
        return state.messages.length
    },
    ////////////////////////////////////////////////
    // end getters message center
    ////////////////////////////////////////////////

}

// $store.dispatch('auth/authRedirect')
const actions = {
    ////////////////////////////////////////////////
    // start actions message center
    ////////////////////////////////////////////////
    startMessagesJob: ({ commit, state, getters, dispatch }) => {
        // console.log('vuex actions startMessagesJob')
        let messageJob = setInterval(() => {
            dispatch('getMessages')
        }, POLL_FREQUENCY)
        // console.log('vuex actions startMessagesJob messageJob', messageJob)
        commit('SET_MESSAGE_JOB', messageJob)
        // console.log('vuex actions startMessagesJob context.getters.messageJob', getters.messageJob)
    },
    stopMessagesJob: ({ commit, state, getters, dispatch }) => {
        console.log('vuex actions stopMessagesJob', getters.messageJob)
        clearInterval(getters.messageJob)
        commit('SET_MESSAGE_JOB', null)
    },
    getMessages: ({ commit, state, getters, dispatch }) => {
        // console.log('vuex actions getMessages')
        let apiEndpoint = '/messages'

        axios.get(apiEndpoint)
            .then(response => {
                // console.log('VUEX actions getMessages then', response)
                commit('SET_MESSAGES', response.data)
            })
            .catch(error => {
                // console.log('VUEX actions getMessages catch', error)
            })

    },
    ////////////////////////////////////////////////
    // end actions message center
    ////////////////////////////////////////////////

}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {
    ////////////////////////////////////////////////
    // start mutations message center
    ////////////////////////////////////////////////
    SET_MESSAGES: (state, payload) => {
        state.messages = payload
    },
    SET_MESSAGE_JOB: (state, messageJob) => {
        // console.log('vuex mutations SET_MESSAGE_JOB')
        state.messageJob = messageJob
    },
    // TOGGLE_ALERTS: (state) => {
    //   state.showAlerts = !state.showAlerts
    // },
    // SET_ALERT: (state, alert) => {
    //   console.log('vuex mutations SET_ALERT')
    //   state.alert = alert
    // },
    ////////////////////////////////////////////////
    // end mutations message center
    ////////////////////////////////////////////////

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

