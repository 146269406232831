import light from './theme'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Boing from '@/components/icon/Boing'
import BoingSolid from '@/components/icon/BoingSolid.vue'

import SortSolid from '@/components/icon/SortSolid'
import SortUpSolid from '@/components/icon/SortUpSolid'
import SortDownSolid from '@/components/icon/SortDownSolid'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            boing: { // name of our custom icon
                component: Boing, // our custom component
            },
            boingSolid: { // name of our custom icon
                component: BoingSolid, // our custom component
            },
            sortSolid: { // name of our custom icon
                component: SortSolid, // our custom component
            },
            sortUpSolid: { // name of our custom icon
                component: SortUpSolid, // our custom component
            },
            sortDownSolid: { // name of our custom icon
                component: SortDownSolid, // our custom component
            },
        },

    },
    theme: {
        dark: false,
        themes: { light },
    },
})

export default vuetify
