import store from '@/store/vuex'
import branding from '/branding.config.js'
import helpers from '@/helpers/helpers'

export default [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    // auth start
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/auth/redirect',
        name: 'authRedirect',
        meta: {
            requiresAuthorization: false,
        },
        component: () => import(/* webpackChunkName: "AuthRedirect" */'/src/views/auth/AuthRedirect.vue'),
    },
    {
        path: '/auth/callback',
        name: 'authCallback',
        meta: {
            requiresAuthorization: false,
        },
        component: () => import(/* webpackChunkName: "AuthCallback" */'/src/views/auth/AuthCallback.vue'),
    },
    {
        path: '/auth/logout',
        name: 'authLogout',
        meta: {
            requiresAuthorization: false,
        },
        component: () => import(/* webpackChunkName: "AuthRedirect" */'/src/views/auth/AuthRedirect.vue'),
    },
    {
        path: '/auth/mfa',
        name: 'authMfaSetup',
        component: () => import(/* webpackChunkName: "AuthMfa" */'/src/views/auth/AuthMfa.vue'),
    },
    {
        path: '/auth/mfa/free',
        name: 'authMfaFreeSetup',
        props: {
            authMfaFreeSetup: true,
        },
        component: () => import(/* webpackChunkName: "AuthMfa" */'/src/views/auth/AuthMfa.vue'),
    },

    {
        path: '/auth/instance/login',
        name: 'authInstanceRedirect',
        component: () => import('/src/views/auth/AuthInstanceRedirect.vue'),
    },
    {
        path: '/auth/register',
        name: 'authInstanceRedirect',
        meta: {
            requiresAuthorization: false,
        },
        component: () => import(/* webpackChunkName: "AuthRegister" */'/src/views/auth/AuthRegister.vue'),
    },

    {
        path: '/auth/register/:passwordToken',
        name: 'authPasswordReset',
        meta: {
            requiresAuthorization: false,
        },
        props: route => ({
            passwordToken: route.params.passwordToken,
            context: 'register',
        }),
        component: () => import(/* webpackChunkName: "PasswordReset" */'/src/views/auth/PasswordReset.vue'),
    },

    {
        path: '/auth/password/:passwordToken',
        name: 'authPasswordReset',
        meta: {
            requiresAuthorization: false,
        },
        props: route => ({
            passwordToken: route.params.passwordToken,
            context: 'reset',
        }),
        // props: true,
        component: () => import(/* webpackChunkName: "PasswordReset" */'/src/views/auth/PasswordReset.vue'),
    },


    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    // auth end
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "BaseLayout" */'@/layouts/BaseLayout.vue'),
        redirect: () => {
            return store.getters['user/homeRouteForRole']
        },

        children: [
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // msps start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'msps',
                name: 'msps',
                meta: {
                    title: `${ branding.company } | MSPs`,
                },
                component: () => import(/* webpackChunkName: "MspsTabs" */'/src/views/msps/MspsTabs.vue'),
                redirect: route => {
                    // route pref
                    let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                    // console.log('redirect', theTabPref)
                    return {
                        name: theTabPref || 'mspCompanies',
                    }
                },
                children: [
                    {
                        path: 'companies',
                        name: 'mspCompanies',
                        meta: {
                            title: `${ branding.company } | MSP | Companies`,
                        },
                        component: () => import(/* webpackChunkName: "MspCompanies" */'/src/views/msps/companies/MspCompanies.vue'),
                    },
                    {
                        path: `${ helpers.urlCase(branding.instancePlural) }`,
                        name: 'mspInstances',
                        meta: {
                            title: `${ branding.company } | MSP | ${ branding.instancePlural }`,
                        },
                        component: () => import(/* webpackChunkName: "MspInstances" */'/src/views/msps/instances/MspInstances.vue'),
                    },
                ],
            },
            {
                path: `msps/${ helpers.urlCase(branding.instancePlural) }/software`,
                name: 'mspSoftware',
                meta: {
                    title: `${ branding.company } | MSP | ${ branding.instancePlural } | Software`,
                },
                component: () => import(/* webpackChunkName: "Software" */'/src/views/software/Software.vue'),
            },
            {
                path: `msps/${ helpers.urlCase(branding.instancePlural) }/:uuid/throughput`,
                name: 'mspNodeThroughput',
                meta: {
                    title: `${ branding.company } | MSP | ${ branding.instancePlural } | Throughput`,
                },
                component: () => import(/* webpackChunkName: "Throughput" */'/src/views/instances/Throughput.vue'),
                props: true,
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // msps end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////



            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // companies start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'companies',
                name: 'companies',
                meta: {
                    title: `${ branding.company } | Companies`,
                },
                props: true,
                component: () => import(/* webpackChunkName: "Companies" */'/src/views/companies/Companies.vue'),
            },
            {
                path: 'companies/profile',
                name: 'companyProfile',
                meta: {
                    title: `${ branding.company } | Company | Profile`,
                },
                component: () => import(/* webpackChunkName: "CompanyProfile" */'/src/views/companies/CompanyProfile.vue'),
            },
            {
                path: 'companies/risk-tolerance',
                name: 'toleranceAssess',
                meta: {
                    title: `${ branding.company } | Company | Risk Tolerance`,
                },
                component: () => import(/* webpackChunkName: "CompanyProfileRiskTolerance" */'/src/views/companies/RiskToleranceAssess.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // companies end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // plugins start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'rms/sources',
                name: 'rmsSources',
                component: () => import(/* webpackChunkName: "SourcesTabs" */'/src/views/sources/SourcesTabs.vue'),
                redirect: route => {
                    // route pref
                    let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                    // console.log('redirect', theTabPref)
                    return {
                        // name: theTabPref
                        name: theTabPref || 'sources',
                    }
                },
                children: [
                    {
                        path: 'assignment',
                        name: 'sources',
                        meta: {
                            title: `${ branding.company } | Sources | Assignment`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "SourcesAssignment" */'/src/views/sources/assignment/SourcesAssignment.vue'),
                    },
                    {
                        path: 'plugins',
                        name: 'sourcesPlugins',
                        meta: {
                            title: `${ branding.company } | RMS | Plugins`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "SourcesPlugins" */'/src/views/sources/plugins/SourcesPlugins.vue'),
                        /*
                         children: [
                         {
                         path: ':uuid/jobs',
                         name: 'sourcesPluginsJobs',
                         meta: {
                         title: `${ branding.company } | RMS | Plugins | Jobs`,
                         },
                         props: true,
                         component: () => import(/!* webpackChunkName: "SourcesPluginsJobs" *!/'/src/views/sources/plugins/SourcesPluginsJobs.vue'),
                         },

                         ],
                         */
                    },
                    {
                        path: 'jobs',
                        name: 'rmsSourcesPluginsJobs',
                        meta: {
                            title: `${ branding.company } | RMS | Plugins | Jobs`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "SourcesPluginsJobs" */'/src/views/sources/plugins/SourcesPluginsJobs.vue'),
                    },

                ],
            },
            /*
             {
             path: 'rms/sources/:uuid/jobs',
             name: 'sourcesPluginsJobs',
             meta: {
             title: `${ branding.company } | RMS | Plugins | Jobs`,
             },
             props: true,
             component: () => import(/!* webpackChunkName: "SourcesPluginsJobs" *!/'/src/views/sources/plugins/SourcesPluginsJobs.vue'),
             },
             */
            /*
             {
             path: 'rms/plugins/:uuid/jobs',
             name: 'pluginJobs',
             meta: {
             title: `${ branding.company } | RMS | Sources | Jobs`,
             },
             props: true,
             component: () => import(/!* webpackChunkName: "Jobs" *!/'/src/views/plugins/plugins/SourcesPluginsJobs.vue'),
             },
             */

            {
                path: 'msp/sources',
                name: 'mspSources',
                meta: {
                    title: `${ branding.company } | Sources`,
                },
                props: true,
                component: () => import(/* webpackChunkName: "SourcesAssignment" */'/src/views/sources/assignment/SourcesAssignment.vue'),
                // component: () => import(/* webpackChunkName: "Sources" */'/src/views/sources/assignment/SourcesAssignment.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////
            // plugins end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////


            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // collect start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            {
                path: 'collect/lists',
                name: 'lists',
                meta: {
                    title: `${ branding.company } | Collect | Lists`,
                },
                component: () => import(/* webpackChunkName: "Lists" */'/src/views/lists/Lists.vue'),
            },
            {
                path: 'collect/lists/create',
                name: 'listCreate',
                meta: {
                    title: `${ branding.company } | Collect | Create List`,
                },
                component: () => import(/* webpackChunkName: "ListWizard" */'/src/views/lists/ListWizard.vue'),
                props: {
                    context: 'create',
                },
            },
            {
                path: 'collect/lists/:uuid/edit',
                name: 'listEdit',
                meta: {
                    title: `${ branding.company } | Collect | Edit List`,
                },
                component: () => import(/* webpackChunkName: "ListWizard" */'/src/views/lists/ListWizard.vue'),
                props: route => ({
                    uuid: route.params.uuid,
                    context: 'edit',
                }),
            },
            {
                path: 'collect/lists/:listType/:uuid',
                name: 'list',
                meta: {
                    title: `${ branding.company } | Collect | View List`,
                },
                props: true,
                component: () => import(/* webpackChunkName: "List" */'/src/views/lists/List.vue'),
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // collect end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////


            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // marketplace LHN start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'marketplace/products',
                name: 'marketplaceProducts',
                meta: {
                    title: `${ branding.company } | Marketplace | Products`,
                },
                props: () => ({
                    // entry: 'lhn',
                }),
                component: () => import(/* webpackChunkName: "Products" */'/src/views/marketplace/Products.vue'),
            },
            {
                path: 'marketplace/products/:id',
                name: 'marketplaceProduct',
                meta: {
                    title: `${ branding.company } | Marketplace | Product`,
                },
                props: route => ({
                    id: route.params.id,
                    // entry: 'lhn',
                }),
                component: () => import(/* webpackChunkName: "Product" */'/src/views/marketplace/Product.vue'),
            },
            {
                path: 'marketplace/products/:id/subscribe',
                name: 'marketplaceProductSubscribe',
                meta: {
                    title: `${ branding.company } | Marketplace | Product| Subscribe`,
                },
                props: route => ({
                    id: route.params.id,
                    // context: 'subscribe',
                    // entry: 'lhn',
                }),
                component: () => import(/* webpackChunkName: "ProductBegin" */'/src/views/marketplace/ProductBegin.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // marketplace LHN end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // enforce tabs start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: `${ helpers.urlCase(branding.software) }`,
                name: 'instanceTabs',
                meta: {
                    title: `${ branding.company } | ${ branding.software }`,
                },
                component: () => import(/* webpackChunkName: "InstanceTabs" */'/src/views/instance/InstanceTabs.vue'),
                redirect: route => {
                    // route pref
                    let routeObject
                    let jwt = store.getters['auth/jwt']
                    let userRole = jwt.claims.user.role

                    if (userRole === 'FREE_MASTER') {
                        // console.log('instanceTabs redirect FREE_MASTER')
                        routeObject = {
                            name: 'enforceFreemium',
                            params: {
                                tabs: 'enforce',
                                feature: 'reports',
                            },
                        }
                    } else {
                        // console.log('tab redirect regular')
                        let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                        // console.log('tab redirect regular theTabPref', theTabPref)

                        /*
                         routeObject = {
                         name: theTabPref || 'reports',
                         }
                         */

                        if (theTabPref) {
                            routeObject = {
                                name: theTabPref,
                            }
                        } else {
                            routeObject = {
                                name: 'reports',
                                params: {
                                    report: 'allowed-blocked',
                                    subreport: 'reasons',
                                },
                            }
                        }
                    }
                    return routeObject
                },
                children: [
                    {
                        path: `:feature/freemium`,
                        name: 'enforceFreemium',
                        // name: 'enforceFreemium',
                        meta: {
                            title: `${ branding.company } | Freemium`,
                        },
                        component: () => import(/* webpackChunkName: "FreemiumFeature" */'/src/views/freemium/FreemiumFeature.vue'),
                    },

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // software start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: 'software',
                        name: 'software',
                        meta: {
                            title: `${ branding.company } | ${ branding.instance }`,
                        },
                        component: () => import(/* webpackChunkName: "Software" */'/src/views/software/Software.vue'),
                    },

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // software end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // instance start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `${ helpers.urlCase(branding.instancePlural) }`,
                        name: 'instances',
                        meta: {
                            title: `${ branding.company } | ${ branding.instancePlural }`,
                        },
                        // component: setComponent(Instances),
                        // component: setComponent('Instances', '/src/views/instances/Instances.vue'),
                        component: () => import(/* webpackChunkName: "Instances" */'/src/views/instances/Instances.vue'),
                    },
                    {
                        path: `${ helpers.urlCase(branding.instancePlural) }/:uuid`,
                        name: 'instance',
                        meta: {
                            title: `${ branding.company } | Instance`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "Instance" */'/src/views/instances/Instance.vue'),
                        children: [
                            {
                                path: `configuration`,
                                name: 'instanceConfiguration',
                                meta: {
                                    title: `${ branding.company } | Instance | Configuration`,
                                },
                                component: () => import(/* webpackChunkName: "Throughput" */'/src/views/instances/configuration/ConfigurationExpansionPanel.vue'),
                                props: true,
                            },

                        ],
                    },
                    {
                        path: `${ helpers.urlCase(branding.instancePlural) }/:uuid/throughput`,
                        name: 'nodeThroughput',
                        meta: {
                            title: `${ branding.company } | Instance | Throughput`,
                        },
                        component: () => import(/* webpackChunkName: "Throughput" */'/src/views/instances/Throughput.vue'),
                        props: true,
                    },

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // instance end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // networks start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `networks/create`,
                        name: 'networkCreate',
                        meta: {
                            title: `${ branding.company } | Create Network`,
                        },
                        component: () => import(/* webpackChunkName: "NetworkWizard" */'/src/views/networks/NetworkWizard.vue'),
                        props: {
                            context: 'create',
                        },
                    },
                    {
                        path: `networks`,
                        name: 'networks',
                        meta: {
                            title: `${ branding.company } | Networks`,
                        },
                        // component: setComponent(Networks),
                        // component: setComponent('Networks', '/src/views/networks/Networks.vue'),
                        component: () => import(/* webpackChunkName: "Networks" */'/src/views/networks/Networks.vue'),
                    },
                    {
                        path: `networks/:uuid/edit`,
                        name: 'networkEdit',
                        meta: {
                            title: `${ branding.company } | Edit Network`,
                        },
                        component: () => import(/* webpackChunkName: "NetworkWizard" */'/src/views/networks/NetworkWizard.vue'),
                        props: route => ({
                            uuid: route.params.uuid,
                            context: 'edit',
                        }),
                    },
                    {
                        path: `networks/:uuid`,
                        name: 'network',
                        meta: {
                            title: `${ branding.company } | Network`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "Network" */'/src/views/networks/Network.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // networks end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // ports start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `ports`,
                        name: 'ports',
                        meta: {
                            title: `${ branding.company } | Ports`,
                        },
                        // component: setComponent(Ports),
                        // component: setComponent('Ports', '/src/views/ports/Ports.vue'),
                        component: () => import(/* webpackChunkName: "Ports" */'/src/views/ports/Ports.vue'),
                    },
                    {
                        path: `ports/:uuid`,
                        name: 'port',
                        meta: {
                            title: `${ branding.company } | Port`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "Port" */'/src/views/ports/Port.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // ports end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // policies start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: 'policies',
                        name: 'policies',
                        meta: {
                            title: `${ branding.company } | Policies`,
                        },
                        // component: setComponent(Policies),
                        // component: setComponent('Policies', '/src/views/policies/Policies.vue'),
                        component: () => import(/* webpackChunkName: "Policies" */'/src/views/policies/Policies.vue'),
                    },
                    {
                        path: 'policies/create',
                        name: 'policyCreate',
                        meta: {
                            title: `${ branding.company } | Create Policy`,
                        },
                        component: () => import(/* webpackChunkName: "PolicyCreateWizard" */'/src/views/policies/PolicyWizard.vue'),
                        props: {
                            context: 'create',
                        },
                    },
                    {
                        path: 'policies/:uuid/edit',
                        name: 'policyEdit',
                        meta: {
                            title: `${ branding.company } | Edit Policy`,
                        },

                        component: () => import(/* webpackChunkName: "PolicyWizard" */'/src/views/policies/PolicyWizard.vue'),
                        // props: true,
                        props: route => ({
                            uuid: route.params.uuid,
                            step: route.params.step,
                            context: 'edit',
                        }),
                    },
                    {
                        path: 'policies/:uuid',
                        name: 'policy',
                        meta: {
                            title: `${ branding.company } | Policy`,
                        },
                        props: true,
                        component: () => import(/* webpackChunkName: "Policy" */'/src/views/policies/Policy.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // policies end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // subscriptions start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: 'subscriptions',
                        name: 'instanceSubscriptions',
                        meta: {
                            title: `${ branding.company } | Subscriptions`,
                        },
                        component: () => import(/* webpackChunkName: "InstanceSubscriptions" */'/src/views/subscriptions/InstanceSubscriptions.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // subscriptions end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // unexpected blocks start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `unexpected-blocks`,
                        name: 'unexpectedBlocks',
                        meta: {
                            title: `${ branding.company } | Unexpected Blocks`,
                        },
                        component: () => import(/* webpackChunkName: "Ports" */'/src/views/unexpectedBlocks/UnexpectedBlocks.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // unexpected blocks end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // reports start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

                    {
                        path: 'reports/80c65bdc-d4d1-4d76-a171-31edc5f9f848',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'allowed-blocked',
                                subreport: 'reasons',
                            },
                        },
                    },
                    {
                        path: 'reports/d2348977-1408-408e-9993-5d877c3baf52',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'allowed-blocked',
                                subreport: 'categories',
                            },
                        },
                    },
                    {
                        path: 'reports/7fccf964-8039-46af-a662-392504d15d83',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'allowed-blocked',
                                subreport: 'countries',
                            },
                        },
                    },
                    {
                        path: 'reports/6a382b48-b0ed-4507-843f-f75b86329ff7',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'allowed-blocked',
                                subreport: 'asns',
                            },
                        },
                    },
                    {
                        path: 'reports/1ceb27bc-4f58-4ef1-bd58-b88b929f5b53',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'top-10',
                                subreport: 'countries',
                            },
                        },
                    },
                    {
                        path: 'reports/a08fe3a2-fd69-4e10-875a-b2eeee853b74',
                        redirect: {
                            name: 'reports',
                            params: {
                                report: 'top-10',
                                subreport: 'asns',
                            },
                        },
                    },

                    {
                        path: 'reports/:report/:subreport',
                        name: 'reports',
                        meta: {
                            title: `${ branding.company } | Reports`,
                        },
                        component: () => import(/* webpackChunkName: "ReportView" */'/src/views/reports/ReportView.vue'),
                    },

                    {
                        path: 'reports/:context/:verdict/temporal',
                        name: 'reportsAllowedBlockedTemporal',
                        meta: {
                            title: `${ branding.company } | Reports | Allowed / Blocked | Temporal`,
                        },
                        props: true,

                        component: () => import(/* webpackChunkName: "ReportsAllowedBlockedTemporal" */'/src/views/reports/allowedBlocked/ReportsAllowedBlockedTemporal.vue'),
                    },

                    {
                        path: 'reports/scheduled',
                        name: 'reportsScheduled',
                        meta: {
                            title: `${ branding.company } | Reports | Scheduled`,
                        },
                        component: () => import(/* webpackChunkName: "ReportsScheduled" */'/src/views/reports/schedules/ReportsScheduled.vue'),
                    },



                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // reports end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                ],
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // enforce tabs end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////


            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // administrate tabs start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            {
                path: 'admin',
                name: 'adminTabs',
                meta: {
                    title: `${ branding.company } | admin`,
                },
                component: () => import(/* webpackChunkName: "InstanceTabs" */'/src/views/admin/AdminTabs.vue'),
                redirect: route => {
                    // route pref
                    let routeObject
                    let jwt = store.getters['auth/jwt']
                    let userRole = jwt.claims.user.role

                    if (userRole === 'FREE_MASTER') {
                        // console.log('adminTabs redirect FREE_MASTER')
                        routeObject = {
                            name: 'adminFreemium',
                            params: {
                                tabs: 'admin',
                                feature: 'users',
                            },
                        }
                    } else {
                        // console.log('redirect regular')
                        let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                        routeObject = {
                            name: theTabPref || 'users',
                        }
                    }
                    return routeObject
                },
                children: [
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // users start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `:feature/freemium`,
                        name: 'adminFreemium',
                        meta: {
                            title: `${ branding.company } | Freemium`,
                        },
                        component: () => import(/* webpackChunkName: "FreemiumFeature" */'/src/views/freemium/FreemiumFeature.vue'),
                    },
                    {
                        path: 'users',
                        name: 'users',
                        meta: {
                            title: `${ branding.company } | Users`,
                        },
                        // component: setComponent(Users),
                        // component: setComponent('Users', '/src/views/users/Users.vue'),
                        component: () => import(/* webpackChunkName: "Users" */'/src/views/users/Users.vue'),
                    },
                    {
                        path: 'subscriptions',
                        name: 'subscriptions',
                        meta: {
                            title: `${ branding.company } | Subscriptions`,
                        },
                        component: () => import(/* webpackChunkName: "SubscriptionInstances" */'/src/views/subscriptions/SubscriptionInstances.vue'),
                    },

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // users end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // commandLogs start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: 'command-logs',
                        name: 'commandLogs',
                        meta: {
                            title: `${ branding.company } | Command Logs`,
                        },
                        // component: setComponent(CommandLogs),
                        // component: setComponent('CommandLogs', '/src/views/commandLogs/CommandLogs.vue'),
                        component: () => import(/* webpackChunkName: "CommandLogs" */'/src/views/commandLogs/CommandLogs.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // commandLogs end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // downloads start
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    {
                        path: `software/downloads`,
                        name: 'softwareDownloads',
                        meta: {
                            title: `${ branding.company } | Software | Downloads`,
                        },
                        // component: setComponent(Downloads),
                        // component: setComponent('Downloads', '/src/views/software/Downloads.vue'),
                        component: () => import(/* webpackChunkName: "Downloads" */'/src/views/software/Downloads.vue'),
                    },
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                    // downloads end
                    ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ],
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // administrate tabs end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // subscriptions start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'subscriptions',
                name: 'subscriptionsTabs',
                meta: {
                    title: `${ branding.company } | Subscriptions`,
                },
                component: () => import(/* webpackChunkName: "SubscriptionsTabs" */'/src/views/subscriptions/SubscriptionsTabs.vue'),
                redirect: route => {
                    // route pref
                    let routeObject
                    let jwt = store.getters['auth/jwt']
                    let userRole = jwt.claims.user.role

                    if (userRole === 'FREE_MASTER') {
                        // console.log('subscriptionsTabs redirect FREE_MASTER')
                        routeObject = {
                            name: 'subscriptionsFreemium',
                            params: {
                                tabs: 'subscriptions',
                                feature: 'instanceSubscriptions',
                            },
                        }
                    } else {
                        // console.log('redirect regular')

                        let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                        // console.log('subscriptionsTabs redirect theTabPref', theTabPref)

                        routeObject = {
                            name: theTabPref || 'subscriptionInstances',
                        }
                    }
                    /*
                     let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                     // console.log('redirect', theTabPref)
                     return {
                     // name: theTabPref
                     name: theTabPref || 'instanceSubscriptions',
                     }
                     */
                    return routeObject
                },
                children: [
                    {
                        path: `:feature/freemium`,
                        name: 'subscriptionsFreemium',
                        meta: {
                            title: `${ branding.company } | Freemium`,
                        },
                        component: () => import(/* webpackChunkName: "FreemiumFeature" */'/src/views/freemium/FreemiumFeature.vue'),
                    },
                    {
                        path: `${ helpers.urlCase(branding.software) }`,
                        name: 'subscriptionInstances',
                        meta: {
                            title: `${ branding.company } | Subscriptions`,
                        },
                        component: () => import(/* webpackChunkName: "SubscriptionInstances" */'/src/views/subscriptions/SubscriptionInstances.vue'),
                    },
                    /*
                     {
                     path: `${ helpers.urlCase(branding.instancePlural) }`,
                     name: 'instanceSubscriptions',
                     meta: {
                     title: `${ branding.company } | Subscriptions | ${ branding.instancePlural }`,
                     },
                     component: () => import(/!* webpackChunkName: "InstanceSubscriptions" *!/'/src/views/subscriptions/InstanceSubscriptions.vue'),
                     },
                     */
                    {
                        path: 'marketplace',
                        name: 'marketplaceSubscriptions',
                        meta: {
                            title: `${ branding.company } | Subscriptions | Marketplace`,
                        },
                        component: () => import(/* webpackChunkName: "MarketplaceSubscriptions" */'/src/views/subscriptions/MarketplaceSubscriptions.vue'),
                    },
                ],
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // subscriptions end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // messages start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'messages',
                name: 'messages',
                meta: {
                    title: `${ branding.company } | Messages`,
                },
                component: () => import(/* webpackChunkName: "Messages" */'/src/views/messages/Messages.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // messages end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // rms start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: `rms/${ helpers.urlCase(branding.instance) }`,
                name: 'rmsInstance',
                component: () => import(/* webpackChunkName: "RmsInstanceTabs" */'/src/views/rms/instance/RmsInstanceTabs.vue'),
                redirect: route => {
                    // route pref
                    let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                    // console.log('redirect', theTabPref)
                    return {
                        // name: theTabPref
                        name: theTabPref || 'rmsInstances',
                    }
                },
                children: [
                    {
                        path: 'instances',
                        name: 'rmsInstances',
                        component: () => import(/* webpackChunkName: "RmsInstances" */'/src/views/rms/instance/instances/RmsInstances.vue'),
                    },
                    {
                        path: 'installs',
                        name: 'rmsInstanceInstalls',
                        component: () => import(/* webpackChunkName: "RmsInstanceInstalls" */'/src/views/rms/instance/installs/RmsInstanceInstalls.vue'),
                    },
                ],
            },
            {
                path: 'rms/companies',
                name: 'rmsCompanies',
                props: true,
                component: () => import(/* webpackChunkName: "RmsCompanies" */'/src/views/rms/companies/RmsCompanies.vue'),
            },
            {
                path: 'rms/companies/:uuid',
                name: 'rmsCompany',
                props: true,
                component: () => import(/* webpackChunkName: "RmsCompany" */'/src/views/rms/companies/RmsCompany.vue'),
            },

            {
                path: 'rms/msps',
                name: 'rmsMsps',
                props: true,
                component: () => import(/* webpackChunkName: "RmsMsps" */'/src/views/rms/msps/RmsMsps.vue'),
            },
            {
                path: 'rms/subscriptions',
                name: 'rmsSubscriptions',
                component: () => import(/* webpackChunkName: "RmsSubscriptions" */'/src/views/rms/subscriptions/RmsSubscriptions.vue'),
            },
            {
                path: 'rms/marketplace',
                name: 'rmsMarketplace',
                component: () => import(/* webpackChunkName: "RmsMarketplaceTabs" */'/src/views/rms/marketplace/RmsMarketplaceTabs.vue'),
                redirect: route => {
                    // route pref
                    let theTabPref = store.getters['preferences/preferenceAtPath'](`tabs.${ route.name }`)
                    // console.log('redirect', theTabPref)
                    return {
                        // name: theTabPref
                        name: theTabPref || 'rmsMarketplaceProducts',
                    }
                },
                children: [
                    {
                        path: 'products',
                        name: 'rmsMarketplaceProducts',
                        component: () => import(/* webpackChunkName: "RmsMarketplaceProducts" */'/src/views/rms/marketplace/products/RmsMarketplaceProducts.vue'),
                    },
                    {
                        path: 'plugins/:source/:protocol',
                        name: 'rmsMarketplaceSources',
                        props: true,
                        component: () => import(/* webpackChunkName: "RmsMarketplaceSources" */'/src/views/rms/marketplace/sources/RmsMarketplaceSources.vue'),
                    },
                ],
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // rms end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // freemium start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'freemium',
                name: 'freemium',
                meta: {
                    title: `${ branding.company } | Freemium`,
                },
                component: () => import(/* webpackChunkName: "Freemium" */'/src/views/freemium/Freemium.vue'),
            },
            {
                path: `:feature/freemium`,
                name: 'freemiumFeature',
                meta: {
                    title: `${ branding.company } | Freemium`,
                },
                component: () => import(/* webpackChunkName: "FreemiumFeature" */'/src/views/freemium/FreemiumFeature.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // freemium end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////

            {
                path: 'users/profile',
                name: 'userProfile',
                meta: {
                    title: `${ branding.company } | User | Profile`,
                },
                component: () => import(/* webpackChunkName: "UserProfile" */'/src/views/users/UserProfile.vue'),
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // skunkworks start
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            {
                path: 'skunkworks',
                name: 'skunkworks',
                meta: {
                    title: `${ branding.company } | skunkworks`,
                },
                component: () => import(/* webpackChunkName: "Skunkworks" */'/src/views/Skunkworks.vue'),
            },
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
            // skunkworks end
            ///////////////////////////////////////////////////////////////////////////////////////////////////////
        ],
    },
    {
        path: '/404',
        name: 'fourOhFour',
        component: () => import(/* webpackChunkName: "FourOhFour" */'/src/views/FourOhFour.vue'),
    },
    {
        path: '*',
        redirect: { name: 'fourOhFour' },
    },
]
