import { has, isEqual } from 'lodash'

import PreferencesMixin from '@/mixins/PreferencesMixin.js'

export default {
    name: 'StickyDiscriminatorMixin',
    mixins: [ PreferencesMixin ],
    data() {
        return {
            default: null,
        }
    },
    watch: {
        '$route.query': {
            deep: false,
            immediate: false,
            handler(newValue, oldValue) {
                // console.log('watch $route.query oldValue', oldValue)
                // console.log('watch $route.query newValue', newValue)
                this.setFromQueryParameter()
            },
        },
        '$route.name': {
            deep: false,
            immediate: false,
            handler(newValue, oldValue) {
                // console.log('watch $route.name oldValue', this.computedPreferencePath, oldValue)
                // console.log('watch $route.name newValue', this.computedPreferencePath, newValue)
                // console.log('watch $route.name setDiscriminatorFromPreferences')

                this.setDiscriminatorFromPreferences()
            },
        },
        localValue: {
            deep: false,
            immediate: false,
            handler(newValue, oldValue) {
                // console.log('watch localValue start ====================================================================')
                // console.log('watch localValue property', this.property)
                // console.log('watch localValue namespace', this.namespace)
                // console.log('watch localValue computedPreferencePath', this.computedPreferencePath)
                // console.log('watch localValue oldValue', oldValue)
                // console.log('watch localValue newValue', newValue)
                // console.log('watch localValue this.default', this.default)
                let resetDefault = isEqual(newValue, this.default)
                // let resetDefault = newValue === this.default
                // console.log('watch localValue resetDefault', resetDefault)

                // console.log('watch localValue end ====================================================================')
                if (newValue === null || resetDefault) {
                    this.setDiscriminatorProperty(newValue)
                }
            },
        },

    },
    computed: {},
    methods: {
        selectDidChange: function(value) {
            // console.log('sticky DidChange value', value)

            if (!this.sticky) return

            this.setDiscriminatorProperty(value)
        },
        setDiscriminatorProperty: function(value) {
            // console.log('setDiscriminatorProperty', value)

            if (!this.sticky) return

            let preferencePath = this.computedPreferencePath
            // console.log('setDiscriminatorProperty computedPreferencePath', this.computedPreferencePath)
            let payload = {
                preference: value,
                path: preferencePath,
            }
            // console.log('setDiscriminatorProperty', payload)
            this.$store.commit('preferences/SET_PREFERENCE_AT_PATH', payload)
        },
        setDiscriminatorFromPreferences: function() {
            // console.log('setDiscriminatorFromPreferences')

            let isSticky = this.sticky
            let property = this.property
            // console.log('setDiscriminatorFromPreferences property', property)
            // console.log('setDiscriminatorFromPreferences isSticky', isSticky)

            if (!isSticky) {
                return
            }

            // console.log('PreferenceMixin mounted this.computedPreferencePath', this.computedPreferencePath)
            let preferencePath = this.computedPreferencePath
            // console.log('setDiscriminatorFromPreferences preferencePath', preferencePath)

            let preference = this.$store.getters['preferences/preferenceAtPath'](preferencePath)
            // console.log('setDiscriminatorFromPreferences preference', preference)

            if (preference === undefined) {
                preference = this.default
            }
            // console.log('setDiscriminatorFromPreferences preference', preference)

            this.localValue = preference
            // console.log('setDiscriminatorFromPreferences ///////////////////////////////////////////////', property)
        },
        setFromQueryParameter: function() {
            // console.log('setFromQueryParameter')
            // let route = this.$route
            let query = this.$route.query
            const property = this.property
            // console.log('setFromQueryParameter query', query)
            // console.log('setFromQueryParameter property', property)
            // console.log('setFromQueryParameter computedPreferencePath', this.computedPreferencePath)
            if (has(query, property)) {
                // console.log('setFromQueryParameter route has property', property)
                // this.localValue = query[property]
                this.setDiscriminatorProperty(query[property])
            } else {
                // console.log('setFromQueryParameter route does not have property', property)
            }
            this.setDiscriminatorFromPreferences()
            // console.log('setFromQueryParameter ///////////////////////////////////////////////', property)

        },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        // this.setFromQueryParameter()
    },
    mounted() {
        // console.log('mounted', this.computedPreferencePath)
        this.setDiscriminatorFromPreferences()
    },
    beforeUpdate() {
        // console.log('beforeUpdate', this.computedPreferencePath)
    },
    updated() {
        // console.log('updated', this.computedPreferencePath)
    },
    beforeDestroy() {},
    destroyed() {},
}
