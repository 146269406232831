<!--
Copyright ThreatBlockr Inc 2022
Created by ejohnson on 11/14/22
-->
<template>

  <div class="d-flex fill-width flex-column">

    <div class="flex-gap-4 justify-end"
         :class="computedClasses">

      <div v-if="!hidePerPage"
           :disabled="pages === 1"
           class="pagination-per-page">
        <span class="detail-label">per page:</span>

        <v-select :items="computedItemsPerPageOptions"
                  :value="perPage"
                  class="ml-2"
                  dense
                  hide-details
                  outlined
                  single-line
                  @change="perPageDidChange($event)"></v-select>

      </div>

      <div v-if="!hideInfoString && computedPaginationString.length"
           class="">
        {{ computedPaginationString }}
      </div>

      <ul>

        <li v-if="!hideFirstLast">
          <v-btn :class="{ 'bs-pagination-navigation-disabled': !hasFirst }"
                 :disabled="!hasFirst"
                 class="bs-pagination-item bs-pagination-item-first-last"
                 text
                 @click="$emit('update:page', 1)">
            <v-icon v-if="buttonStyle === 'both' || buttonStyle === 'icon'">mdi-page-first</v-icon>
            <span v-if="buttonStyle === 'both' || buttonStyle === 'text'">First</span>
          </v-btn>
        </li>

        <li v-if="!hidePreviousNext">
          <v-btn
              :class="{ 'bs-pagination-navigation-disabled': page === 1 || hasPrevious === false || loading === true }"
              :disabled="page === 1 || hasPrevious === false || loading === true"

              class="bs-pagination-item bs-pagination-item-prev-next"
              text
              @click="$emit('update:page', page - 1)">
            <v-icon v-if="buttonStyle === 'both' || buttonStyle === 'icon'">mdi-chevron-left</v-icon>
            <span v-if="buttonStyle === 'both' || buttonStyle === 'text'">Previous</span>
          </v-btn>
        </li>

        <template v-for="(pageNumber, index) in computedPageNumbers">
          <li :key="index">
            <v-btn v-if="page === pageNumber"
                   class="bs-pagination-item bs-pagination-item-active info">{{ pageNumber }}
            </v-btn>
            <!--
            <v-btn v-if="page === pageNumber"
                   class="bs-pagination-item bs-pagination-item-active primary">{{ pageNumber }}
            </v-btn>
            -->
            <span v-else-if="!hidePageTiles && pageNumber && pageNumber.toString() === '...'"
                  class="bs-pagination-more">{{ pageNumber }}</span>
            <v-btn v-else-if="!hidePageTiles"
                   class="bs-pagination-item"
                   @click="$emit('update:page', pageNumber)">{{ pageNumber }}
            </v-btn>
          </li>
        </template>

        <li v-if="!hidePreviousNext">
          <v-btn
              :class="{ 'bs-pagination-navigation-disabled': page === pages || hasNext === false || loading === true }"
              :disabled="page === pages || hasNext === false || loading === true"

              class="bs-pagination-item bs-pagination-item-prev-next"
              text
              @click="$emit('update:page', page + 1)">
            <span v-if="buttonStyle === 'both' || buttonStyle === 'text'">Next</span>
            <v-icon v-if="buttonStyle === 'both' || buttonStyle === 'icon'">mdi-chevron-right</v-icon>
          </v-btn>
        </li>

        <li v-if="!hideFirstLast">

          <v-btn :class="{ 'bs-pagination-navigation-disabled': !hasLast }"
                 :disabled="!hasLast"
                 class="bs-pagination-item bs-pagination-item-first-last"
                 text
                 @click="$emit('update:page', pages)">
            <span v-if="buttonStyle === 'both' || buttonStyle === 'text'">Last</span>
            <v-icon v-if="buttonStyle === 'both' || buttonStyle === 'icon'">mdi-page-last</v-icon>
          </v-btn>

        </li>
      </ul>

    </div>

    <v-row v-if="debug && computedIsDevelopment"
           :no-gutters="false">
      <v-col>
        <bs-print :value="{perPage}"/>
      </v-col>
      <v-col>
        <bs-print :value="{
          total: total,
          filteredTotal: filteredTotal,
          pages: pages,
          perPage: perPage,
          page: page,
        }"/>
        <bs-print :value="{computedPaginationString}"/>

        <bs-print :value="{computedItemsPerPageOptions}"/>
      </v-col>
      <v-col>
        <bs-print :value="{allItem}"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import PreferencesMixin from '@/mixins/PreferencesMixin.js'

  export default {
    /*
     "pagination": {
     "page": 1,
     "perPage": 5,
     "sortColumn": "datetime",
     "sortDirection": "descending"
     "pages": 62,
     "total": 307,
     "hasNext": true,
     "hasPrevious": false,
     "search": null,
     }
     */
    name: 'BSPagination',
    props: {
      // proppy https://vuejs.org/guide/components/props.html#prop-validation

      perPageOptions: {
        type: Array,
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        default: function(rawProps) {
          return [
            10,
            20,
            50,
            100,
            -1,
          ]
          /*
           return [
           /!*
           {
           text: '5',
           value: 5,
           },
           *!/
           {
           text: '10',
           value: 10,
           },
           {
           text: '20',
           value: 20,
           },
           {
           text: '50',
           value: 50,
           },
           {
           text: '100',
           value: 100,
           },
           {
           text: 'All',
           value: -1,
           },
           ]
           */
        },
      },

      debug: {
        type: Boolean,
        default: false,
      },

      page: {
        type: Number,
        default: 1,
      },
      perPage: {
        type: Number,
        default: null,
      },
      pages: {
        type: Number,
        default: function() {
          return Math.ceil(this.total / this.perPage)
        },
      },

      total: {
        type: Number,
        default: 0,
      },
      filteredTotal: {
        type: Number,
        default: null,
      },

      loading: {
        type: Boolean,
        default: false,
      },
      hasPrevious: {
        type: Boolean,
        default: undefined,
      },
      hasNext: {
        type: Boolean,
        default: undefined,
      },
      hideFirstLast: {
        type: Boolean,
        default: false,
      },
      hidePreviousNext: {
        type: Boolean,
        default: false,
      },
      hidePerPage: {
        type: Boolean,
        default: false,
      },
      hideInfoString: {
        type: Boolean,
        default: false,
      },
      hidePageTiles: {
        type: Boolean,
        default: false,
      },
      allItem: {
        type: Boolean,
        default: false,
      },
      buttonStyle: {
        type: String,
        default: 'both',
      },
    },
    components: {},
    mixins: [
      PreferencesMixin,
    ],
    data() {
      return {
        localValue: {},
        namespace: 'pagination',
        property: 'perPage',
        /*
         perPageOptions: [
         {
         text: '4',
         value: 4,
         },
         {
         text: '10',
         value: 10,
         },
         {
         text: '20',
         value: 20,
         },
         {
         text: '50',
         value: 50,
         },
         {
         text: '100',
         value: 100,
         },
         {
         text: 'All',
         value: -1,
         },
         ],
         */
      }
    },
    watch: {
      computedPaginationString: {
        deep: false,
        immediate: true,
        handler(newValue, oldValue) {
          // console.log('computedPaginationString oldValue', oldValue)
          // console.log('computedPaginationString newValue', newValue)
          // this.defaultPagination.total = this.items.length
          if (newValue !== oldValue) {
            this.$emit('changed:paginationString', this.computedPaginationString)
          }
        },
      },

    },
    computed: {

      computedItemsPerPageOptions: function() {
        // console.log('computedItemsPerPageOptions')
        let perPageOptions = this.perPageOptions

        perPageOptions = perPageOptions.map(object => {
          let text = object
          if (object === -1) {
            text = 'All'
          }
          return {
            text: text,
            value: object,
          }
        })
        // let itemsPerPageOptions = this.itemsPerPageOptions
        // console.log('computedItemsPerPageOptions itemsPerPageOptions', itemsPerPageOptions)
        // console.log('computedItemsPerPageOptions this.allItem', this.allItem)

        if (this.allItem === false) {
          perPageOptions = perPageOptions.filter(object => object.value !== -1)
        }
        // console.log('computedItemsPerPageOptions itemsPerPageOptions', itemsPerPageOptions)
        // console.log('computedItemsPerPageOptions //////////////////////////////////////////////////')
        return perPageOptions
      },
      computedClasses: function() {
        let classes = [
          'bs-pagination',
          this.paginationStyle,
          this.debug && this.computedIsDevelopment ? 'debug' : '',
        ]
        return classes
      },
      computedPageNumbers: function() {
        // https://codereview.stackexchange.com/questions/139658/generic-paging-algorithm
        // console.log('computedPageNumbers3')
        // let pageNumber = 10
        let pageNumber = this.page
        const pageCount = this.pages

        let retVal = []



        let start = 1
        let end = pageCount

        const prependedItems = [
          1,
          2,
          '...',
        ]

        const appendedItems = [
          '...',
          pageCount - 1,
          pageCount,
        ]

        let surroundingPageCount = 2
        let arbitraryNum = 2
        let truncate = pageCount > ((surroundingPageCount * 2) + arbitraryNum)

        if (truncate) {

          //        pageCount - (2 + (surroundingPageCount * 2))
          let truncateStart = pageNumber >= (pageCount - (surroundingPageCount * 2) + 1)
          // let truncateStart = false

          let truncateBoth = pageNumber <= (pageCount - (surroundingPageCount * 2)) &&
              pageNumber >= ((surroundingPageCount * 2))

          let truncateEnd = pageNumber <= ((surroundingPageCount * 2) + 1)

          if (truncateEnd) {
            // console.log('truncateEnd', pageNumber)
            // start = 1
            end = (4 + (surroundingPageCount * 2))
            for (let index = start; index < end; index++) {
              retVal.push(index)
            }

            retVal = [ ...retVal, ...appendedItems ]

          } else if (truncateBoth) {
            // console.log('truncateBoth', pageNumber)

            retVal = [ ...retVal, ...prependedItems ]

            start = (pageNumber - surroundingPageCount)
            end = (pageNumber + surroundingPageCount)
            for (let index = start; index <= end; index++) {
              retVal.push(index)
            }

            retVal = [ ...retVal, ...appendedItems ]

          } else if (truncateStart) {
            // console.log('truncateStart', pageNumber)

            retVal = [ ...retVal, ...prependedItems ]

            start = pageCount - ((surroundingPageCount * 2))
            // end = pageCount
            for (let index = start; index <= end; index++) {
              retVal.push(index)
            }
          } else { // Truncate start
            /*
             console.log('truncate else', pageNumber)

             retVal = [...retVal, ...prependedItems]

             start = pageCount - (2 + (surroundingPageCount * 2))
             // end = pageCount
             for (let index = start; index <= end; index++) {
             retVal.push(index)
             }
             */
          }
        } else {
          start = 1
          // end = pageCount

          for (let index = start; index <= end; index++) {
            retVal.push(index)
          }
        }

        return retVal
      },

      hasFirst: function() {
        // console.log('hasFirst')
        return this.page > 1
      },
      hasLast: function() {
        // console.log('hasLast')
        return this.page < this.pages
      },
      computedPaginationString: function() {
        // console.log('computedPaginationString')
        let retVal

        const allItemCount = this.total // localize
        if (allItemCount === 0) return ''

        const filteredItemCount = this.filteredTotal // localize
        const isFiltered = filteredItemCount !== null && allItemCount !== filteredItemCount

        let pageCount = this.pages
        const perPage = this.perPage
        const page = this.page

        console.log('computedPaginationString allItemCount', allItemCount)
        console.log('computedPaginationString filteredItemCount', filteredItemCount)
        console.log('computedPaginationString perPage', perPage)
        console.log('computedPaginationString page', page)

        if (perPage === -1) {
          retVal = `1-${ allItemCount } of ${ allItemCount } total`

          if (isFiltered) {
            retVal = `${ filteredItemCount } filtered. ${ allItemCount } total`
          } else {
            retVal = `${ allItemCount } total`
          }

        } else {
          let pageOffset = (perPage - 1)
          let firstItemNumber = (page * perPage) - pageOffset // localize
          let lastItemNumber = firstItemNumber + pageOffset // localize
          lastItemNumber = lastItemNumber > allItemCount ? allItemCount : lastItemNumber
          if (pageCount === 1) {
            if (isFiltered) {
              retVal = `${ filteredItemCount } filtered. ${ allItemCount } total`
            } else {
              retVal = `${ firstItemNumber }-${ lastItemNumber } total`
            }
          } else {
            if (isFiltered) {
              retVal = `${ firstItemNumber }-${ lastItemNumber } of ${ filteredItemCount } filtered. ${ allItemCount } total`
            } else {
              retVal = `${ firstItemNumber }-${ lastItemNumber } of ${ allItemCount } total`
            }
          }
        }
        console.log('computedPaginationString //////////////', retVal)

        return retVal
      },

      computedPreferencePath: function() {
        // console.log('computedPreferencePath')
        const namespace = this.namespace
        const property = this.property

        // discriminators.listsThreat.isHealthy
        let thePath = `${ namespace }.${ property }`
        // console.log('computedPreferencePath thePath', thePath)
        return thePath
      },
    },
    methods: {
      perPageDidChange: function(event) {
        console.log('perPageDidChange event', event)

        let payload = {
          preference: event,
          path: 'pagination.perPage',
        }
        this.$store.commit('preferences/SET_PREFERENCE_AT_PATH', payload)
        console.log('perPageDidChange payload', payload)

        this.$emit('update:perPage', event)
      },

      /*
       testPaginator: function() {
       console.log('testPaginator')
       for (let current = 1, total = 19; current <= total; current++)
       console.log(`Page ${current}:`, this.pageNumbers(current, total, 2)); // delta 4

       },
       */
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      // console.log('pagination initial state perPage')
      // let perPage = localStorage.getItem('perPage')
      let perPage = this.$store.getters['preferences/preferenceAtPath']('pagination.perPage')
      // console.log('mounted perPage', perPage)
      if (perPage) {
        // console.log('mounted perPage', perPage)
        if (perPage === -1) {
          // allItem
          // console.log('mounted perPage allItem perPage === -1', perPage)
          if (this.allItem === true) {
            // set from pref
            // console.log('mounted perPage this.allItem === true')
            this.$emit('update:perPage', perPage)
          } else {
            // default
            // console.log('mounted perPage this.allItem === false UNHANDLED')
            this.$emit('update:perPage', 100)
          }
        } else {
          // NOT allItem
          // set from pref
          // console.log('mounted perPage NOT allItem perPage !== -1', perPage)
          this.$emit('update:perPage', perPage)
        }
      } else {
        // default
        // console.log('mounted perPage !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! no per page UNHANDLED')
      }
      // this.perPage = perPage
      // this.testPaginator()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  :deep(.v-text-field--outlined).v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 38px;
  }
</style>
