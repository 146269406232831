// gorgon lighten-7 gorgon--text text--lighten-7

// also defined in src/sass/variables.scss
export default {
    /*
     'buttons': {
     'disabled': rgba(map-get($shades, 'black'), 0.26),
     'focused': rgba(map-get($shades, 'black'), 0.12),
     'focused-alt': rgba(map-get($shades, 'white'), 0.6),
     'pressed': rgba(#999, 0.4)
     },
     */

    message: '#5D6C7F',
    // message: '#0F78F7',
    info: '#0F78F7',
    success: '#1d7c66',
    danger: '#d60000',
    muted: '#5D6C7F', // this is slate lighten-2
    // "primary": "#FB23FF",
    // "secondary": "#FB23FF",
    // "accent": "#FB23FF",
    error: '#d60000',
    // "warning": "#FB23FF"

    green: {
        base: '#098979',
        lighten7: '#CCE7E3',
        lighten6: '#9CCFC9',
        lighten5: '#83C4BC',
        lighten4: '#6BB8AF',
        lighten3: '#52ACA1',
        lighten2: '#3AA194',
        lighten1: '#229586',
        darken1: '#107B6D',
        darken2: '#0D6E61',
        darken3: '#0A6055',
        darken4: '#075249',
    },

    blue: {
        base: '#1665B9',
        lighten7: '#D9E7F7',
        lighten6: '#A3C0E3',
        lighten5: '#8BB2DC',
        lighten4: '#72A3D5',
        lighten3: '#5B93CE',
        lighten2: '#4783C7',
        lighten1: '#2E74C0',
        darken1: '#135CA7',
        darken2: '#115294',
        darken3: '#114782',
        darken4: '#0C3D6F',
    },

    slate: {
        base: '#34475F',
        lighten7: '#C2C8CF',
        lighten6: '#ADB5BF',
        lighten5: '#9AA3AF',
        lighten4: '#84919F',
        lighten3: '#707E8F',
        lighten2: '#5D6C7F',
        lighten1: '#47586F',
        darken1: '#2E4056',
        darken2: '#29394C',
        darken3: '#243243',
        darken4: '#1E2B39',
    },

    red: {
        base: '#D60000',
        lighten7: '#F7CACA',
        lighten6: '#EF9999',
        lighten5: '#EB8080',
        lighten4: '#E66766',
        lighten3: '#E24E4D',
        lighten2: '#DE3333',
        lighten1: '#DA1B1A',
        darken1: '#C10100',
        darken2: '#AB0200',
        darken3: '#950200',
        darken4: '#800000',
    },

    yellow: {
        base: '#FFC93C',
        lighten7: '#FFF4D6',
        lighten6: '#FEE9B0',
        lighten5: '#FEE49F',
        lighten4: '#FEDF8A',
        lighten3: '#FED977',
        lighten2: '#FED563',
        lighten1: '#FECE50',
        darken1: '#E5B536',
        darken2: '#CBA130',
        darken3: '#B38C2A',
        darken4: '#997924',
    },

    gray: {
        base: '#BBC0C9',
        lighten7: '#f7f8f9',
        // lighten7: '#EBECEF',
        lighten6: '#E3E6E9',
        lighten5: '#DEE0E3',
        lighten4: '#D6D9DF',
        lighten3: '#CFD3D9',
        lighten2: '#C9CDD4',
        lighten1: '#C2C6CE',
        darken1: '#A8ADB5',
        darken2: '#969AA1',
        darken3: '#82868D',
        darken4: '#6F7378',
    },

}

