export default {
    name: 'PreferencesMixin',
    data() {
        return {}
    },
    watch: {
        /*
         localFormModel: {
         deep: false,
         immediate: false,
         handler(newValue, oldValue) {
         // console.log('watch localFormModel start ====================================================================')
         // console.log('watch localFormModel property', this.property)
         // console.log('watch localFormModel namespace', this.namespace)
         // console.log('watch localFormModel computedPreferencePath', this.computedPreferencePath)
         // console.log('watch localFormModel oldValue', oldValue)
         // console.log('watch localFormModel newValue', newValue)
         // console.log('watch localFormModel end ====================================================================')
         if (newValue === null) {
         this.setDiscriminatorProperty(newValue)
         }
         },
         },
         */
    },
    computed: {
        computedPreferences: function() {
            // console.log('computedPreferences')
            return this.$store.getters['preferences/preferences']
        },
        computedPreferencePath: function() {
            // console.log('prefs mixin computedPreferencePath')
            const namespace = this.namespace
            const routeName = this.$route.name
            const property = this.property
            // console.log('prefs mixin computedPreferencePath namespace', namespace)
            // console.log('prefs mixin computedPreferencePath routeName', routeName)
            // console.log('prefs mixin computedPreferencePath property', property)
            // console.log('prefs mixin computedPreferencePath typeof property', typeof property)

            let thePath
            if (typeof property === 'string') {
                console.log('prefs mixin computedPreferencePath string')
                // lodash needs this for get()
                thePath = [
                    namespace,
                    routeName,
                    property,
                ]

            } else if (typeof property === 'object') {
                console.log('prefs mixin computedPreferencePath object')
                // lodash needs this for get()
                thePath = [
                    namespace,
                    routeName,
                    ...property,
                ]

            }
            // let thePath = `${ namespace }.${ routeName }.${ property }`
            console.log('computedPreferencePath thePath', thePath)
            return thePath
        },
    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
