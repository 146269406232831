// import router from '@/router'
import axios from 'axios'
import { get } from 'lodash'

function assert(cond, message) {
    if (!(cond)) {
        throw message || 'assertion failed'
    }
}

const state = () => ({
    jwt: JSON.parse(localStorage.getItem('jwt')),
})

const getters = {
    jwt: (state, getters, rootState, rootGetters) => {
        // console.log('VUEX auth getters jwt')
        return state.jwt
    },
    authorized: (state, getters, rootState, rootGetters) => {
        // console.log('VUEX auth getters authorized')
        const accessToken = get(getters.jwt, 'tokens.access', null)
        return (accessToken !== null)
    },
    bearerToken: (state, getters, rootState, rootGetters) => {
        return 'Bearer ' + get(getters.jwt, 'tokens.access', null)
    },
    refreshToken: (state, getters, rootState, rootGetters) => {
        return get(getters.jwt, 'tokens.refresh', null)
    },
    nextRedirect: (state, getters, rootState, rootGetters) => {
        const redirectRouteStr = localStorage.getItem('nextRedirect')
        console.log('VUEX getters: nextRedirect redirectRouteStr', redirectRouteStr)
        if (redirectRouteStr === null) {
            return null
        }
        const retVal = JSON.parse(redirectRouteStr)
        console.log('VUEX getters: nextRedirect', retVal)

        return retVal
    },
}

// $store.dispatch('auth/authRedirect')
const actions = {
    registerUser: (context, payload) =>
        new Promise((resolve, reject) => {
            let apiEndpoint = `/auth/register/${ payload.token }`
            // let apiEndpoint = '/auth/register'

            axios.put(apiEndpoint, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        }),
    changePassword: (context, payload) =>
        new Promise((resolve, reject) => {
            let apiEndpoint = '/auth/password/reset'

            axios.put(apiEndpoint, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        }),

    authRedirect: (context) => {
        console.log('VUEX auth actions authRedirect')
        return new Promise((resolve, reject) => {
            let config = {}
            if (process.env.NODE_ENV === 'development') {
                config = {
                    params: {
                        useLocalCallback: true,
                    },
                }
            }

            let apiEndpoint = '/auth/login'
            axios.get(apiEndpoint, config)
                .then(response => {
                    console.log('VUEX auth authRedirect then', response)

                    let pingUrl = response.data.url
                    console.log('VUEX auth pingUrl', pingUrl)
                    location.href = pingUrl

                    resolve(response)
                })
                .catch(error => {
                    console.log('VUEX auth authRedirect catch!', error)

                    context.commit('DEAUTHORIZE')
                    reject(error)
                })
        })
    },
    login: (context, credentials) => {
        console.log('VUEX auth actions login')

        return new Promise((resolve, reject) => {
            let apiEndpoint = '/auth/login'
            axios.post(apiEndpoint, credentials)
                .then(response => {
                    context.commit('SET_JWT', response.data)
                    resolve(response)
                })
                .catch(error => {
                    context.commit('DEAUTHORIZE')
                    reject(error)
                })
        })
    },
    /*
     logout: (context) => {
     context.commit('DEAUTHORIZE')
     },
     */
    deauthorize: (context) => {
        console.log('VUEX auth actions deauthorize')
        context.commit('SET_JWT', null)
        // localStorage.clear()
        localStorage.removeItem('jwt')
    },

    setNextRedirect: (context, nextRedirect) => {
        context.commit('VUEX SET_NEXT_REDIRECT', nextRedirect)
    },

}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {
    SET_JWT: (state, jwt) => {
        console.log('VUEX auth mutations SET_JWT jwt', jwt)
        state.jwt = jwt
        const jwtString = JSON.stringify(jwt)
        localStorage.setItem('jwt', jwtString)
    },
    DEAUTHORIZE: (state) => {
        // localStorage.clear()
        localStorage.removeItem('jwt')
    },
    SET_NEXT_REDIRECT: (state, nextRedirect) => {
        console.log('VUEX auth mutations SET_NEXT_REDIRECT: ', nextRedirect)
        if (nextRedirect === null) {
            console.log('VUEX auth mutations SET_NEXT_REDIRECT: null')

            localStorage.removeItem('nextRedirect')
        } else {
            // The 'matched' property breaks stringify due to 'cyclic object' errors
            const theRoute = {
                'path': nextRedirect.path,
                'params': nextRedirect.params,
                'query': nextRedirect.query,
            }
            console.log('VUEX auth mutations SET_NEXT_REDIRECT 2: ', theRoute)
            localStorage.setItem('nextRedirect', JSON.stringify(theRoute))
        }
        console.log('VUEX auth mutations SET_NEXT_REDIRECT: DONE')
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
