import { get } from 'lodash'
import axios from 'axios'
import router from '@/router'

const state = () => ({})

// this.$store.getters['preferences/preferenceAtPath']('pagination.perPage')
const getters = {
    homeRouteForRole: (state, getters, rootState, rootGetters) => {
        // console.log('VUEX user homeRouteForRole')
        let roles = {
            'OTP_FREE_SETUP': { name: 'authMfaFreeSetup' },
            'OTP_SETUP': { name: 'authMfaSetup' },
            // 'FREE_MASTER': { name: 'lists' },
            'FREE_MASTER': { name: 'freemium' },
            'CMP_READONLY': { name: 'reports', params: { report: 'allowed-blocked', subreport: 'reasons' } },
            'CMP_HELPDESK': { name: 'reports', params: { report: 'allowed-blocked', subreport: 'reasons' } },
            'CMP_ADMIN': { name: 'reports', params: { report: 'allowed-blocked', subreport: 'reasons' } },
            'CMP_MASTER': { name: 'reports', params: { report: 'allowed-blocked', subreport: 'reasons' } },
            'MSP_READONLY': { name: 'mspCompanies' },
            'MSP_HELPDESK': { name: 'mspCompanies' },
            'MSP_ADMIN': { name: 'mspCompanies' },
            'MSP_MASTER': { name: 'mspCompanies' },
            'RMS_READONLY': { name: 'rmsInstance' },
            'RMS_HELPDESK': { name: 'rmsInstance' },
            'RMS_ADMIN': { name: 'rmsInstance' },
            'RMS_MASTER': { name: 'rmsInstance' },
        }

        const userRole = get(rootGetters['auth/jwt'], 'claims.user.role', 'home')
        // const userRole = get(rootGetters['auth/jwt'], 'claims.user.role', null)
        // console.log('VUEX user homeRouteForRole userRole', userRole)
        const retVal = roles[userRole]
        // console.log('VUEX user homeRouteForRole retVal', retVal)

        return retVal
    },
    logoPathForMsp: (state, getters, rootState, rootGetters) => {
        const mspUuids = {
            'dd4c9273-867a-4be9-9e0a-1ebe7cedbec4': 'cyber-stash',
        }
        const mspUuid = get(rootGetters['auth/jwt'], 'claims.msp.uuid', null)

        const imageName = mspUuids[mspUuid] || 'web-app'
        return `https://gmc-public-assets.s3.amazonaws.com/branding/${ imageName }.svg`
        // return '/branding/' + imageName + '.svg'
    },
    userIsImpersonating: (state, getters, rootState, rootGetters) => {
        const realName = get(rootGetters['auth/jwt'], 'claims.real_user', null)
        // console.log('VUEX user userIsImpersonating realName', realName)

        return realName !== null
    },
}

// $store.dispatch('auth/authRedirect')
const actions = {
    sendPasswordResetEmail: ({ commit, state, getters, dispatch, rootState, rootGetters }, email) =>
        new Promise((resolve, reject) => {
            let apiEndpoint = '/auth/password/reset'

            axios.post(apiEndpoint, { email: email })
                .then(response => {
                    dispatch({
                        type: 'flash/addFlash',
                        title: 'Password Reset Email Sent Successfully',
                        flashType: 'success',
                    }, { root: true }).then(result => {
                    })

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        }),
    impersonateUser: ({ commit, state, getters, dispatch, rootState, rootGetters }, userUuid) => {
        // console.log('VUEX user actions impersonateUser userUuid', userUuid)

        return new Promise((resolve, reject) => {
            const refreshToken = get(rootGetters['auth/jwt'], 'tokens.refresh', null)
            let apiEndpoint = '/auth/jwt/refresh/' + refreshToken

            axios.put(apiEndpoint, { userUuid: userUuid })
                .then(response => {
                    commit('auth/SET_JWT', response.data, { root: true })
                    // console.log('VUEX user actions impersonateUser homeRouteForRole', getters.homeRouteForRole)

                    const currentRoute = router.currentRoute
                    const homeRouteForRole = getters.homeRouteForRole

                    if (currentRoute.name === homeRouteForRole.name) {
                        location.reload()
                    } else {
                        router.push(homeRouteForRole).catch(() => {})
                    }

                    dispatch({
                        type: 'flash/addFlash',
                        title: 'User Impersonation Successful',
                        flashType: 'success',
                    }, { root: true }).then(result => {
                    })

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    endImpersonateUser: ({ commit, state, getters, dispatch, rootState, rootGetters }) => {
        // console.log('VUEX user actions endImpersonateUser')

        return new Promise((resolve, reject) => {
            const refreshToken = get(rootGetters['auth/jwt'], 'tokens.refresh', null)

            let apiEndpoint = '/auth/jwt/refresh/' + refreshToken

            axios.delete(apiEndpoint)
                .then(response => {
                    commit('auth/SET_JWT', response.data, { root: true })

                    const currentRoute = router.currentRoute.name

                    if (currentRoute === 'users') {
                        location.reload()
                    } else {
                        router.push({ name: 'users' }).catch(() => {})
                    }


                    dispatch({
                        type: 'flash/addFlash',
                        title: 'End User Impersonation',
                        flashType: 'success',
                    }, { root: true }).then(result => {
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

