<!--
Copyright ThreatBlockr Inc 2022
Created by ejohnson on 8/23/22
-->
<template>

  <v-select v-if="multiple === false"
            v-model="localValue"
            :items="computedSelectItems"
            :loading="loading"
            :menu-props="{ offsetY: true }"
            clear-icon="mdi-close-circle"
            :multiple="multiple"
            clearable
            :placeholder="computedPlaceholder"

            :class="{ 'bs-field-dirty': this.localValue !== this.default }"

            v-bind="$attrs"
            @change="selectDidChange"
            v-on="$listeners">

    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="slotData">
      <slot :name="slot" v-bind="{...slotData}"/>
    </template>

    <!--
    <template v-slot:selection="{ item, index }">
      <span class="text-truncate">{{ item.text }}</span>
      <span v-if="!item.value" class="grey&#45;&#45;text text-caption ml-1">({{ computedSelectItemsDistinct.length }})</span>
    </template>
    -->

  </v-select>

  <v-select v-else-if="multiple === true"
            v-model="localValue"
            :items="computedSelectItems"
            :loading="loading"
            :menu-props="{ offsetY: true }"
            clear-icon="mdi-close-circle"
            :multiple="multiple"
            placeholder="All"
            clearable
            v-bind="$attrs"
            @change="selectDidChange"
            v-on="$listeners">

    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="slotData">
      <slot :name="slot" v-bind="{...slotData}"/>
    </template>

    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" small>
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text text-caption">(+{{ value.length - 1 }} others)</span>
    </template>

    <template v-slot:prepend-item>
      <v-list-item ripple
                   @mousedown.prevent>
        <v-list-item-action>
          <v-icon>
            mdi-checkbox-blank-outline
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ `All ${ computedItemLabel || header.value }` }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>


  </v-select>

</template>

<script>
  import StickyDiscriminatorMixin from '@/mixins/StickyDiscriminatorMixin.js'

  export default {
    name: 'BSTableUniqueSelect',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation

      genericPlaceholder: {
        type: Boolean,
        default: false,
      },
      sticky: {
        type: Boolean,
        default: true,
      },

      value: {
        // type: [ String, Boolean, Number ],
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      header: {
        type: Object,
        required: true,
        default(rawProps) {
          return {}
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      vid: {
        type: String,
        default: undefined,
      },
      preserveOrder: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        namespace: 'discriminators',
        property: this.header.value,
      }
    },
    components: {},
    mixins: [ StickyDiscriminatorMixin ],
    watch: {
      loading: {
        deep: false,
        immediate: false,
        handler(newValue, oldValue) {
          // console.log('watch stickyPref loading oldValue', oldValue)
          // console.log('watch stickyPref loading newValue', newValue)
          if (newValue === false) {
            // console.log('watch stickyPref loading newValue false', newValue)
            // console.log('watch stickyPref loading newValue this.computedSelectItems', this.computedSelectItems)
            // console.log('watch stickyPref loading newValue this.localValue', this.localValue)
            // this code checks to see if the model value exists as an option. if it doesn't, set the model value to null
            let valueExists = this.computedSelectItems.find(object => object.value === this.localValue)
            // console.log('watch stickyPref loading newValue false valueExists', valueExists)
            // console.log('watch stickyPref computedDistinctSources valueExists', valueExists)
            if (!valueExists) {
              // console.log('watch stickyPref pref source not in current plugins. set pref to null')
              this.localValue = null
            } else {
              // console.log('watch stickyPref pref source exists')
            }
          }
        },
      },
    },
    computed: {
      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localValue: {
        get() {
          // console.log('localValue', this.value)
          return this.value
        },
        set(value) {
          // console.log('localValue', value)
          this.$emit('input', value)
        },
      },
      computedPlaceholder: function() {
        let placeHolderString
        if (this.genericPlaceholder) {
          placeHolderString = `All (${ this.computedSelectItemsDistinct.length })`
        } else {
          // text: `All ${ this.computedItemLabel || this.header.value }`,
          placeHolderString = `All ${ this.header.filterLabel || this.header.value } (${ this.computedSelectItemsDistinct.length })`
          // let foo = this.header.filterLabel || this.header.value
        }
        return placeHolderString
      },
      computedItemLabel: function() {
        return this.header.filterLabel
      },
      computedSelectItems: function() {

        let distinctItems = this.computedSelectItemsDistinct

        /*
         const set = new Set
         distinctItems = filteredItems.filter(object => {
         return object.value !== null && !set.has(object.value) && set.add(object.value)
         })
         */


        /*
         if (this.multiple === false) {
         const allItem = {
         text: `All ${ this.computedItemLabel || this.header.value }`,
         value: null,
         }
         distinctItems = [
         allItem,
         ...distinctItems,
         ]
         }
         */

        return distinctItems
      },

      // header.filterItems
      // header.value
      // items
      computedSelectItemsDistinct: function() {
        let filteredItems

        if (this.header.filterItems === undefined) {
          filteredItems = this.items.map(item => {
            // console.log('filterItems this.items', item)
            return {
              text: item[this.header.value],
              value: item[this.header.value],
            }
          })
        } else {
          filteredItems = this.header.filterItems(this.items)
        }


        let distinctItems

        const set = new Set
        distinctItems = filteredItems.filter(object => {
          return object.value !== null && !set.has(object.value) && set.add(object.value)
        })

        /*
         if (this.multiple === false) {
         const allItem = {
         text: `All ${ this.computedItemLabel || this.header.value } (${ distinctItems.length })`,
         value: null,
         }
         distinctItems = [
         allItem,
         ...distinctItems,
         ]
         }
         */

        // console.log('computedSelectItemsDistinct distinctItems', distinctItems)
        return distinctItems
      },
    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
