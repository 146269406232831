import Vue from 'vue'
import config from '/branding.config.js'


const branding = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        Vue.mixin({
            data() {
                return {
                    branding: config,
                }
            },
        })
    },
}

export default branding

Vue.use(branding)

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(branding)
}
