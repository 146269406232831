import Vue from 'vue'
import helpers from '@/helpers/helpers'

export const filtersObject = {
    truncate: (value, length = 24) => {
        if (!value) return '-'

        return helpers.truncate(value, length)
    },

    currency: (value) => {
        return helpers.formatCurrency(value)
    },
    number: (value, nullValue = '-') => {
        // console.log('filters localeString', value)
        if (!value) return nullValue
        // console.log('filters localeString still trucking')

        return value.toLocaleString()
    },

    isEmpty: (value) => {
        return helpers.isEmpty(value)
    },
    isFinite: (value) => {
        return helpers.isFinite(value)
    },
    isNaN: (value) => {
        return helpers.isNaN(isNaN)
    },
    upper: (value) => {
        // console.log('filters localeString', value)
        if (value === null) return '-'
        // console.log('filters localeString still trucking')

        return value.toUpperCase()
    },
    localeString: (value, nullValue = '-') => {
        // console.log('filters localeString', value)
        if ([ null, undefined ].includes(value)) return nullValue
        // console.log('filters localeString still trucking', typeof value)

        return value.toLocaleString()
    },
    datetime: (value, format = 'MM/dd/yy, hh:mm a') => {
        // https://date-fns.org/v2.29.3/docs/format
        // console.log('filters datetime', value)
        if (!value) return '-'

        return helpers.formatDatetime(value, format)
    },
    datetimeUtc: (value, format = 'MM/dd/yy, hh:mm a UTC') => {
        // https://date-fns.org/v2.29.3/docs/format
        // console.log('filters datetime', value)
        if (!value) return '-'

        return helpers.formatDatetimeUtc(value, format)
    },



    formatBits: (bits, precision) => {
        return helpers.formatBits(bits, precision)
    },
    formatBytes: (bytes, precision) => {
        return helpers.formatBytes(bytes, precision)
    },
    formatMegaBits: (bits, precision) => {
        return helpers.formatMegaBits(bits, precision)
    },
    formatMegaBytes: (bytes, precision) => {
        return helpers.formatMegaBytes(bytes, precision)
    },
    formatGigaBits: (bits, precision) => {
        return helpers.formatGigaBits(bits, precision)
    },
    formatGigaBytes: (bytes, precision) => {
        return helpers.formatGigaBytes(bytes, precision)
    },




    titleCase: (value) => {
        if (value === null) return ''
        return helpers.titleCase(value)
    },

}

Object.entries(filtersObject).forEach(([ filterName, filterFunction ]) => {
    Vue.filter(filterName, filterFunction)
})
