import Vue from 'vue'
import helpers from '@/helpers/helpers.js'

Vue.mixin({
    computed: {
        computedIsDevelopment: function() {
            // console.log('computedIsDevelopment')
            return this.$environment === 'development'
            // return process.env.NODE_ENV
        },
        computedSuppressDebug: function() {
            // console.log('computedSuppressDebug $suppressDebug', this.$suppressDebug)
            // console.log('computedSuppressDebug $scooby', this.$scooby)
            return this.$suppressDebug
        },

    },
})
