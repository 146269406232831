<!--
Copyright ThreatBlockr Inc 2023
Created by ejohnson on 12/6/23

// https://github.com/mengxiong10/vue2-datepicker.git
-->
<template>
  <!--:disabled-date="computedDisabledDate"-->
  <!--:disabled-time="computedDisabledTime"-->

  <!--
  <bs-base-datetime-picker v-model="localValue"
                           :items="items"
                           range
                           :placeholder=computedPlaceholder
                           range-separator=" to "

                           v-bind="{ ...$attrs, ...computedAttributes }"

                           @change="rangePickerDidChange"
                           v-on="$listeners"/>
  -->

  <bs-base-datetime-picker v-model="localValue"
                           :items="items"
                           :datetime-property="datetimeProperty"

                           range
                           :placeholder=computedPlaceholder
                           range-separator=" to "

                           v-bind="$attrs"

                           @change="rangePickerDidChange"
                           v-on="$listeners"/>

  <!--
  <date-picker v-model="localValue"

               :confirm="confirm"
               :disabled-date="computedDisabledDate"
               :disabled-time="computedDisabledTime"
               :placeholder=computedPlaceholder
               :show-time-header="true"
               :show-time-panel="showTimePanel"
               :append-to-body="false"
               type="datetime"
               format="MM/DD/YY, hh:mm a"
               input-class="v-input"
               range

               :class="{ 'discriminator-dirty': computedIsDirty }"

               value-type="YYYY-MM-DDTHH:mm:ssZ"
               range-separator=" to "
               time-title-format="MM/DD/YY, hh:mm a"

               @change="rangePickerDidChange"

               v-bind="$attrs"
               v-on="$listeners">

    <template v-slot:input="slotProps">
      <v-text-field v-bind="slotProps.props"
                    v-on="slotProps.events"
                    dense
                    :disabled="$attrs.disabled || loading"
                    :loading="loading"

                    hide-details="auto"
                    clear-icon="mdi-close-circle"
                    outlined>
        <template v-slot:prepend-inner>
          <v-icon class="mr-1">mdi-calendar-clock</v-icon>
        </template>
      </v-text-field>
    </template>

    <template v-slot:icon-calendar>
      <v-icon class=""></v-icon>
    </template>
    <template v-slot:icon-clear>
      <v-icon class="">mdi-close-circle</v-icon>
    </template>
    <template v-slot:footer="{ emit }">
      <v-btn-toggle v-model="showTimePanel"
                    dense
                    mandatory>

        <v-btn :value="false">
          <v-icon>mdi-calendar-blank-outline</v-icon>
        </v-btn>
        <v-btn :value="true">
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

    </template>

  </date-picker>
  -->

</template>

<script>
  // https://github.com/mengxiong10/vue2-datepicker/issues/671
  // https://github.com/mengxiong10/vue2-datepicker/issues/584
  // https://github.com/mengxiong10/vue2-datepicker/issues/616
  // https://github.com/mengxiong10/vue2-datepicker/issues/457 https://codesandbox.io/p/sandbox/open-time-panel-8wm3g     detect click date vs time

  import 'vue2-datepicker/index.css'

  import { getDayOfYear, isWithinInterval, parseISO } from 'date-fns'
  import StickyDiscriminatorMixin from '@/mixins/StickyDiscriminatorMixin.js'
  import { compact, first, last, orderBy } from 'lodash'
  import BSDatetimeRangePickerMixin from '@/mixins/BSDatetimeRangePickerMixin.js'
  import helpers from '@/helpers/helpers.js'
  import BSBaseDatetimePicker from '@/views/unexpectedBlocks/BSBaseDatetimePicker.vue'

  export default {
    name: 'BSDatetimeRangePicker',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation
      datetimeProperty: {
        default: 'datetime',
      },
      debug: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Array,
        default: function() {
          return []
        },
      },
      items: {
        type: Array,
        required: false,
        default(rawProps) {
          return []
        },
      },
      placeholder: {
        type: String,
        default: 'Select Date Range',
      },
      sticky: {
        type: Boolean,
        default: false,
      },
      stickyModelPathArray: {
        type: Array,
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        required: false,
        default(rawProps) {
          return [ 'datetimeRange' ]
        },
      },
      /*
       startDatetime: {
       type: String,
       // type: [ Date, String ],
       default: null,
       },
       */
      /*
       endDatetime: {
       type: String,
       // type: [ Date, String ],
       default: null,
       },
       */
      /*
       disabledDate: {
       type: Function,
       },
       */
      /*
       disabledTime: {
       type: Function,
       },
       */
      /*
       betweenDatesFunc: {
       type: Function,
       },
       */
      /*
       betweenTimesFunc: {
       type: Function,
       },
       */
      /*
       futureOnly: {
       type: Boolean,
       default: false,
       },
       */
      /*
       pastOnly: {
       type: Boolean,
       default: false,
       },
       */
      /*
       confirm: {
       type: Boolean,
       default: false,
       },
       */
      loading: {
        type: Boolean,
        default: false,
      },

    },
    data() {
      return {
        viewType: undefined,
        showTimePanel: false,
        namespace: 'discriminators',
        // default: null,
        datetimeArray: null,
        /*
         shortcuts: [
         {
         text: 'Today',
         onClick() {
         const date = new Date()
         return date
         },
         },
         {
         text: 'Yesterday',
         onClick: () => {
         const date = new Date()
         date.setTime(date.getTime() - 3600 * 1000 * 24)
         return date
         },
         },
         ],
         */
      }
    },
    components: {
      'bs-base-datetime-picker': BSBaseDatetimePicker,
      // 'date-picker': DatePicker,
    },
    mixins: [
      StickyDiscriminatorMixin,
      BSDatetimeRangePickerMixin,
    ],
    watch: {},
    computed: {

      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localValue: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
      // start sticky /////////////////////////////////////////////////////
      // start sticky /////////////////////////////////////////////////////
      // start sticky /////////////////////////////////////////////////////
      property: function() {
        // console.log('property', this.stickyModelPathArray)
        return this.stickyModelPathArray
      },
      // end sticky /////////////////////////////////////////////////////
      // end sticky /////////////////////////////////////////////////////
      // end sticky /////////////////////////////////////////////////////

      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      computedOldest: function() {
        // console.log('computedOldest ///////////////////////////////////////////////')
        // console.log('computedOldest this.items', this.items)

        let sortedArray = this.computedOrderedItems
        // console.log('computedOldest sortedArray', sortedArray)

        let row = first(sortedArray)
        // console.log('computedOldest row', row)

        if (!row) return
        let datetime = row[this.datetimeProperty]
        // let datetime = row.datetime
        console.log('===================================== bookends computedOldest datetime', datetime)

        return datetime
      },
      computedNewest: function() {
        // console.log('bookends computedNewest')
        let sortedArray = this.computedOrderedItems
        console.log('bookends computedNewest sortedArray', sortedArray)

        let row = last(sortedArray)
        console.log('bookends computedNewest row', row)

        if (!row) return
        let datetime = row[this.datetimeProperty]
        // let datetime = row.datetime
        // console.log('bookends computedNewest datetime', datetime)

        console.log('===================================== bookends computedNewest datetime', datetime)
        return datetime
      },
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      computedPlaceholder: function() {
        // console.log('///////////////////////////////////// start computedPlaceholder')
        let placeholder = this.placeholder
        // let placeholderFunc = this.placeholderFunc
        // let placeholderItems = this.placeholderItems

        /*
         if (placeholderFunc) {
         console.log('computedPlaceholder placeholderFunc', placeholderFunc)
         }
         */
        /*
         if (placeholderItems) {
         console.log('computedPlaceholder placeholderItems', placeholderItems)
         }
         */

        // console.log('computedPlaceholder placeholder', placeholder)

        let items = this.items

        if (items?.length) {
          // console.log('computedPlaceholder items', items)

          const startDatetime = this.computedOldest
          // const startDatetime = this.oldest()
          // console.log('computedPlaceholder items startDatetime', startDatetime)

          const endDatetime = this.computedNewest
          // const endDatetime = this.newest()
          // console.log('computedPlaceholder items endDatetime', endDatetime)

          placeholder = this.getRangeString(startDatetime, endDatetime)
        }
        // console.log('computedPlaceholder placeholder', placeholder)
        // console.log('===================================== end computedPlaceholder')

        return placeholder
      },
      computedAttrs: function() {
        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        return attrs
      },
      computedOrderedItems: function() {
        // console.log('bookends computedOrderedItems!')

        let items = this.items
        items = items.filter(object => {
          return object[this.datetimeProperty] !== null && object[this.datetimeProperty] !== undefined
        })
        console.log('bookends computedOrderedItems datetimeProperty', this.datetimeProperty)
        let sortedArray = orderBy(items, [ this.datetimeProperty ], [ 'asc' ])
        // let sortedArray = orderBy(items, [ 'datetime' ], [ 'asc' ])
        return sortedArray
      },
    },
    methods: {
      // start sticky /////////////////////////////////////////////////////
      // start sticky /////////////////////////////////////////////////////
      // start sticky /////////////////////////////////////////////////////
      rangePickerDidChange: function(datetimeArray) {
        // console.log('rangePickerDidChange datetimeArray', datetimeArray)

        let cleanedDatetimeArray = compact(datetimeArray)

        if (cleanedDatetimeArray.length === 0) {
          // console.log('rangePickerDidChange cleanedDatetimeArray.length === 0')

          cleanedDatetimeArray = null
          this.localValue = null

        }

        if (this.sticky) {
          // console.log('rangePickerDidChange sticky', this.sticky)
          this.setDiscriminatorProperty(cleanedDatetimeArray)
        }
      },
      setDiscriminatorFromPreferences: function() {
        // console.log('setDiscriminatorFromPreferences')
        // console.log('PreferenceMixin mounted')
        // console.log('PreferenceMixin mounted this.computedPreferencePath', this.computedPreferencePath)

        let isSticky = this.sticky
        if (isSticky) {
          // console.log('setDiscriminatorFromPreferences isSticky', isSticky)

          let property = this.property
          // console.log('setDiscriminatorFromPreferences property', property)

          let preferencePath = this.computedPreferencePath
          // console.log('sticky range picker setDiscriminatorFromPreferences preferencePath', preferencePath)

          let preference = this.$store.getters['preferences/preferenceAtPath'](preferencePath)
          // console.log('setDiscriminatorFromPreferences preference', preference)

          if (preference === undefined) {
            preference = this.default
          }

          this.localValue = preference

        }

      },
      // end sticky /////////////////////////////////////////////////////
      // end sticky /////////////////////////////////////////////////////
      // end sticky /////////////////////////////////////////////////////

      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      getRangeString: function(startDatetime, endDatetime) {
        const parameters = {
          startDatetime: startDatetime,
          endDatetime: endDatetime,
        }
        const datetimeString = this.datetimeRangeStringFormat(parameters)
        return datetimeString
      },
      datetimeRangeStringFormat: function(parameters) {
        const formatString = 'MM/dd/yy, hh:mm aaa'
        const startDatetime = parameters.startDatetime
        const endDatetime = parameters.endDatetime

        let retVal = ''
        if (startDatetime && endDatetime) {
          const formatStartDatetime = helpers.formatDatetime(startDatetime, formatString)
          const formatEndDatetime = helpers.formatDatetime(endDatetime, formatString)

          retVal = `${ formatStartDatetime } to ${ formatEndDatetime }`

        }

        return retVal
      },

      dateOutsideFunc: function(datetime) {
        // console.log('dateOutsideFunc')
        return !this.dateBetweenFunc(datetime)
      },
      timeOutsideFunc: function(datetime, index) {
        // console.log('timeOutsideFunc internal index', index)

        const startDatetimeString = this.computedOldest
        const endDatetimeString = this.computedNewest
        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)

        let time = datetime.getTime()
        // console.log('timeOutsideFunc internal time', time)

        const startTime = startDatetime.getTime()
        const endTime = endDatetime.getTime()
        // console.log('timeOutsideFunc internal startTime', startTime)
        // console.log('timeOutsideFunc internal endTime', endTime)

        let isTimeOutside
        if (index === 0) {
          // start/left side
          let isTimeLessThanStart = time < startTime
          // console.log('timeOutsideFunc internal isTimeLessThanStart', isTimeLessThanStart)
          isTimeOutside = isTimeLessThanStart
        } else if (index === 1) {
          // end/right side
          let isTimeGreaterThanEnd = time > endTime
          // console.log('timeOutsideFunc internal isTimeGreaterThanEnd', isTimeGreaterThanEnd)
          isTimeOutside = isTimeGreaterThanEnd
        }

        // console.log('timeOutsideFunc internal /////////////////////////////////////////////////')
        return isTimeOutside
      },

      dateBetweenFunc: function(datetime) {
        // console.log('dateBetweenFunc internal')
        let datetimeType = typeof datetime

        const datetimeDayOfYear = getDayOfYear(datetime)
        // console.log('dateBetweenFunc internal datetimeDayOfYear', datetimeDayOfYear)

        const startDatetimeString = this.computedOldest
        const endDatetimeString = this.computedNewest

        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)

        const startDatetimeDayOfYear = getDayOfYear(startDatetime)
        const endDatetimeDayOfYear = getDayOfYear(endDatetime)
        // console.log('dateBetweenFunc internal startDatetimeDayOfYear', startDatetimeDayOfYear)
        // console.log('dateBetweenFunc internal endDatetimeDayOfYear', endDatetimeDayOfYear)

        const dayOfYearIsAfter = datetimeDayOfYear >= startDatetimeDayOfYear
        const dayOfYearIsBefore = datetimeDayOfYear <= endDatetimeDayOfYear

        let shouldShowDay = dayOfYearIsAfter && dayOfYearIsBefore
        // console.log('dateBetweenFunc internal shouldShowDay', shouldShowDay)

        return shouldShowDay
      },
      datetimeInRange: function(datetime, datetimeRange) {

        if (!datetimeRange.length) return true
        const startDatetime = datetimeRange[0]
        const endDatetime = datetimeRange[1]
        // console.log('datetimeInRange internal startDatetime', startDatetime)
        // console.log('datetimeInRange internal endDatetime', endDatetime)

        let interval = {
          start: startDatetime,
          end: endDatetime,
        }
        // console.log('datetimeInRange internal interval', interval)

        let isWithinIntervalBool = isWithinInterval(datetime, interval)

        // console.log('datetimeInRange internal isWithinIntervalBool datetime', datetime)
        // console.log('datetimeInRange internal isWithinIntervalBool interval.start', interval.start)
        // console.log('datetimeInRange internal isWithinIntervalBool interval.end', interval.end)
        // console.log('datetimeInRange internal isWithinIntervalBool', isWithinIntervalBool)
        // console.log('datetimeInRange internal isWithinIntervalBool ////////////////////////////////')
        // console.log('datetimeInRange internal ////////////////////////////////')

        return isWithinIntervalBool
      },
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      // this.$emit('mounted')
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .success {
    border-color: #1d7c66 !important;
    background-color: #1d7c66 !important;
  }
</style>
