/*
 const STATE_ENUM = {
 idle: 0,
 viewed: 2
 }
 */
import router from '@/router'

const flashDismissTimeout = 5000
const persistTypes = [
    'error',
    'message',
    // 'info',
    // 'success',
]

export const ID = (i => () => i++)(0)

const state = () => ({
    flashes: [],
})

const getters = {
    hasFlashes: state => {
        return state.flashes.length > 0
    },
    flashes: state => {
        return state.flashes
    },
    hasFlashWithId: (state, getters) => (id) => {
        // console.log('VUEX flash getters hasFlashWithId id', id)

        // let hasFlashWithId = state.flashes.includes(flash => flash.id === id)
        let flash = state.flashes.find(object => {
            // console.log('VUEX flash mutations object', object)
            return object.id === id
        })
        // console.log('VUEX flash getters hasFlashWithId', flash)

        let retVal = flash !== undefined

        // console.log('VUEX flash getters hasFlashWithId retVal', retVal)

        return retVal
    },

    /*
     hasFlashWithId: (state, getters, id) => {
     let hasFlashWithId = state.flashes.includes(object => {
     // console.log('VUEX flash mutations object', object)
     return object.id === id
     })
     /!*
     let flash = state.flashes.find(object => {
     // console.log('VUEX flash mutations object', object)
     return object.id === id
     })
     *!/

     return hasFlashWithId
     },
     */

}

// $store.dispatch('flash/removeFlash')
// commit('REMOVE_FLASH', flash)
// getters.flashes
const actions = {
    setStatusForFlashWithId: ({ commit, state, getters, dispatch }, payload) => {
        // console.log('VUEX flash actions setStatusForFlashWithId ///////////////////////////////')
        // const currentRoute = router.currentRoute
        // console.log('VUEX flash actions setStatusForFlashWithId this.$route.name', currentRoute.name)
        // console.log('VUEX flash actions setStatusForFlashWithId payload', payload)

        commit('SET_STATUS_FOR_FLASH_ID', payload)

        // if (currentRoute.name === 'unexpectedBlocks' && payload.progress.status.status === 'COMPLETE') {
        if (payload.progress.status.status === 'COMPLETE') {
            // console.log('VUEX flash actions setStatusForFlashWithId make go away')
            // dismissal should be explicit
            // commit('REMOVE_FLASH_WITH_ID', payload.progress.flashId)
        } else {
            commit('SET_STATUS_FOR_FLASH_ID', payload)
        }


        // console.log('VUEX flash actions setStatusForFlashWithId flash', flash)
    },
    addFlash: ({ commit, state, getters, dispatch }, flash) => {
        // console.log('VUEX flash actions addFlash flash', flash)

        /*
         let persistTypes = [
         'error',
         // 'message',
         // 'info',
         // 'success',
         ]
         */
        if (typeof flash.progress === 'object') {
            // console.log('VUEX flash actions addFlash progress', flash.progress)
        }
        let foo = commit('ADD_FLASH', flash)
        // console.log('VUEX flash actions addFlash flash foo', foo)

        // console.log('VUEX flash actions addFlash flash', flash)
        // persistTypes false or flash.evaporate === true
        /*
         persistTypes true
         evaporate === true
         evaporate
         persistTypes false
         */
        if (flash.progress === undefined && (persistTypes.includes(flash.flashType) === false)) {
            // console.log('VUEX flash actions addFlash /////////////')
            // console.log('VUEX flash actions addFlash progress', flash.progress)
            // console.log('VUEX flash actions addFlash persistTypes.includes(flash.flashType)', persistTypes.includes(flash.flashType))
            // console.log('VUEX flash actions addFlash =============')

            setInterval(() => {
                commit('REMOVE_FLASH', flash)
            }, flashDismissTimeout)
        }
        /*
         if ((persistTypes.includes(flash.flashType) === false) ||
         (persistTypes.includes(flash.flashType) === true) && (flash.evaporate === true)) {
         setInterval(() => {
         commit('REMOVE_FLASH', flash)
         }, flashDismissTimeout)
         }
         */
        return flash
    },
}

// $store.commit('flash/REMOVE_HIDDEN_FLASHES', response.data)
const mutations = {
    SET_STATUS_FOR_FLASH_ID: (state, payload) => {
        // console.log('VUEX flash mutations SET_STATUS_FOR_FLASH_ID ///////////////////////////////')
        // console.log('VUEX flash mutations SET_STATUS_FOR_FLASH_ID payload', payload)
        // let flash = getFlashWithId(payload.progress.flashId)
        let flash = state.flashes.find(object => {
            // console.log('VUEX flash mutations object', object)
            return object.id === payload.progress.flashId
        })
        // console.log('VUEX flash mutations flash', flash)

        if (flash) {
            flash.progress = payload.progress.status
        }
        // flash.status = payload.update.status
    },
    ADD_FLASH: (state, flash) => {
        if (typeof flash.progress === 'object') {
            // console.log('VUEX flash mutations ADD_FLASH progress', flash.progress)
            flash.id = flash.progress.uuid
        } else {
            flash.id = ID()
        }

        flash.createdDatetime = Date.now()
        flash.visible = true
        // console.log('VUEX flash mutations ADD_FLASH flash', flash)
        state.flashes.unshift(flash)
        return flash.id
    },
    REMOVE_FLASH_WITH_ID: (state, id) => {
        // console.log('VUEX flash mutations REMOVE_FLASH_WITH_ID id', id)

        let flash = state.flashes.find(object => object.id === id)
        if (flash) {
            flash.visible = false
            state.flashes = state.flashes.filter(thisFlash => {
                return thisFlash.id !== id
            })
        }
    },
    REMOVE_FLASH: (state, flash) => {
        flash.visible = false
        state.flashes = state.flashes.filter(thisFlash => {
            return thisFlash.id !== flash.id
        })
    },
    REMOVE_ALL_FLASHES: (state) => {
        state.flashes = []
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

