<!--
Copyright ThreatBlockr Inc 2022
Created by ejohnson on 11/9/22
-->
<template>
  <v-btn v-if="icon === false"
         :disabled="!computedHasDirtyDiscriminators"
         color="blue"
         plain
         class="px-0"
         @click="resetDiscriminators">Reset
  </v-btn>
  <div v-else
       class="d-flex align-center justify-center">
    <v-icon v-show="computedHasDirtyDiscriminators"
            class="header-discriminator-reset"
            :plain="true"
            :muted="true"
            :ripple="false"

            @click="resetDiscriminators">
      mdi-close-circle
    </v-icon>

  </div>

</template>

<script>
  import ReportMixin from '@/mixins/ReportMixin.js'
  import { get } from 'lodash'

  const ENTITY_DISPLAY_NAME = 'BSDiscriminatorReset'

  export default {
    name: ENTITY_DISPLAY_NAME,
    props: {
      icon: {
        type: Boolean,
        default: false,
      },
      // Object with a default value
      discriminatorsDefaults: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        /*
         default(rawProps) {
         return {}
         },
         */
      },
      search: {
        type: String,
        default: null,
      },
      discriminators: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        default(rawProps) {
          return {}
        },
      },
      reportToolbar: {
        type: Boolean,
      },
    },
    components: {},
    mixins: [ ReportMixin ],
    data() {
      return {}
    },
    watch: {
      /*
       localValue(newVal) {
       // allows us to use v-model on our input.
       // Handles internal model changes.
       this.$emit('input', newVal)
       },
       */
      /*
       value(newVal) {
       // Handles external model changes.
       this.localValue = newVal
       },
       */

    },
    computed: {
      localSearch: {
        get() {
          return this.search
        },
        set(val) {
          this.$emit('search:reset')
        },
      },
      localValue: {
        get() {
          return this.discriminators
        },
        set(val) {
          this.$emit('input', val)
        },
      },

      computedHasDirtyDiscriminators: function(object, path) {
        let theValues
        if (this.reportToolbar) {
          theValues = JSON.parse(JSON.stringify(this.computedParameters))
          // theValues = this.computedParameters
          delete theValues.timezone
        } else {
          theValues = this.localValue
        }
        // console.log('theValues', theValues)

        let discriminatorsDefaults = this.discriminatorsDefaults

        const nonNullDiscriminatorValues = Object.values(theValues).filter(discriminator => {
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues discriminator', discriminator)
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues this.default', this.default)
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues discriminatorsDefaults', discriminatorsDefaults)

          // let discriminatorsDefaults = this.discriminatorsDefaults

          // let discriminatorsDefault = get(discriminatorsDefaults, discriminator, null)
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues discriminatorsDefault', discriminatorsDefault)

          // let isDefault = isEqual(discriminator, discriminatorsDefault)
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues isDefault', isDefault)

          let hasValue = (discriminator !== null && discriminator !== undefined)
          // let hasValue = !isEmpty(discriminator)
          // console.log('computedHasDirtyDiscriminators nonNullDiscriminatorValues discriminator hasValue', discriminator, hasValue)
          // console.log('computedHasDirtyDiscriminators ////////////////////////////////////////////')

          return hasValue
          // return discriminator !== null && discriminator !== undefined
        })
        // console.log('nonNulldiscriminatorValues', nonNulldiscriminatorValues)

        // console.log('computedHasDirtyDiscriminators nonNulldiscriminatorValues', nonNulldiscriminatorValues)
        // console.log('///////////////////////////////////////////////////////////////////////// computedHasDirtyDiscriminators nonNulldiscriminatorValues')

        const hasDirtyDiscriminators = nonNullDiscriminatorValues.length > 0
        let hasSearchText = this.localSearch?.length

        return hasDirtyDiscriminators || hasSearchText
      },

    },
    methods: {
      resetDiscriminators: function() {
        // console.log('resetDiscriminators')
        if (this.reportToolbar) {
          let parameters = {
            preset: null,
            startDatetime: undefined,
            endDatetime: undefined,
            instanceUuid: null,
            policyUuid: null,
          }
          this.$store.commit('report/SET_PARAMETERS', parameters)
        } else {
          let discriminatorsDefaults = this.discriminatorsDefaults
          // console.log('resetDiscriminators discriminatorsDefaults', discriminatorsDefaults)

          Object.keys(this.localValue).forEach(discriminator => {
            // console.log('resetDiscriminators discriminator', discriminator)
            let discriminatorsDefault = get(discriminatorsDefaults, discriminator, null)
            console.log('resetDiscriminators discriminator discriminatorsDefaultValue', discriminator, discriminatorsDefault)
            this.localValue[discriminator] = discriminatorsDefault
          })
          if (this.search) {
            this.localSearch = null
          }
        }
      },
    },
    beforeCreate() {},
    created() {
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
