import ReportLookup from '@/components/select/BSReportLookup.js'

export default {

    components: {},
    data() {
        return {}
    },
    watch: {},
    computed: {
        computedRoute: {
            get() {
                return this.$store.getters['report/route']
            },
        },
        computedQueryParameters: {
            get() {
                const query = this.$store.getters['report/query']
                // console.log('computedQueryParameters get query', query)
                return query
            },
            set(value) {
                // console.log('computedQueryParameters set value', value)
                this.$store.commit('report/SET_QUERY', value)
            },
        },
        computedParams: {
            get() {
                // return this.$store.state['report/parameters']
                return this.$store.getters['report/params']
            },
            set(value) {
                this.$store.commit('report/SET_PARAMETERS', value)
            },
        },
        computedParameters: {
            get() {
                // return this.$store.state['report/parameters']
                return this.$store.getters['report/parameters']
            },
            set(value) {
                this.$store.commit('report/SET_PARAMETERS', value)
            },
        },
        computedReport: {
            get() {
                // console.log('computedReport get')
                const reportUuid = this.computedParams.reportUuid
                const reportUuid2 = this.computedParams.reportUuid
                // const reportUuid = this.computedParameters.reportUuid
                // console.log('computedReport get reportUuid', reportUuid)

                // console.log('computedReport get this.computedParameters', this.computedParams)
                const reportMeta = this.getReportMetaForUuid(reportUuid)
                // console.log('computedReport get reportMeta', reportMeta)
                return reportMeta
            },
            set(newReportMeta) {
                let meta
                if (newReportMeta) {
                    // console.log('computedReport set newReportMeta', newReportMeta)
                    meta = newReportMeta
                } else {
                    meta = this.computedDefaultReportMeta
                    // console.log('computedReport set computedDefaultReportMeta', meta)
                }

                this.$store.commit('report/SET_REPORT_UUID', meta.uuid)
                this.$store.commit('report/SET_REPORT', meta.report)
                this.$store.commit('report/SET_SUBREPORT', meta.subreport)

                // console.log('computedReport set meta', meta)
                // console.log('computedReport ///////////////////////////////////')
            },
        },
        computedPreset: {
            get() {
                // return this.computedQueryParameters.preset
                // const preset = this.computedQueryParameters.preset
                const preset = this.$store.getters['report/preset']
                // console.log('computedPreset get preset', preset)
                return preset

            },
            set(value) {
                this.$store.commit('report/SET_PRESET', value)
            },
        },
        computedStartDatetime: {
            get() {
                const startDatetime = this.computedQueryParameters.startDatetime
                // const startDatetime = this.$store.getters['report/startDatetime']
                // const startDatetime = this.$store.state['report/query'].startDatetime
                // console.log('computedStartDatetime get startDatetime', startDatetime)
                return startDatetime
            },
            set(value) {
                // console.log('computedStartDatetime set value', value)
                // only once menu is closed
                this.$store.commit('report/SET_STARTDATETIME', value)
            },
        },
        computedEndDatetime: {
            get() {
                const endDatetime = this.computedQueryParameters.endDatetime
                // const endDatetime = this.$store.getters['report/endDatetime']
                // const endDatetime = this.$store.state['report/query'].endDatetime
                // console.log('computedEndDatetime get endDatetime', endDatetime)
                return endDatetime
            },
            set(value) {
                // console.log('computedEndDatetime set value', value)
                // only once menu is closed
                this.$store.commit('report/SET_ENDDATETIME', value)
            },
        },
        computedInstanceUuid: {
            get() {
                return this.computedQueryParameters.instanceUuid
            },
            set(value) {
                this.$store.commit('report/SET_INSTANCEUUID', value)
            },
        },
        computedPolicyUuid: {
            get() {
                return this.computedQueryParameters.policyUuid
            },
            set(value) {
                this.$store.commit('report/SET_POLICYUUID', value)
            },
        },

        computedReportMetaForRoute: function() {
            // console.log('computedReportMetaForRoute')

            let retVal = {
                props: {
                    discriminators: [],
                },
            }
            const report = this.$route.params.report
            // console.log('computedReportMetaForRoute report', report)

            const subreport = this.$route.params.subreport
            // console.log('computedReportMetaForRoute subreport', subreport)

            if (report && subreport) {
                // console.log('computedReportMetaForRoute report && subreport')

                retVal = this.getReportMetaForReportAndSubreport(report, subreport)
            }
            return retVal
        },
        computedDefaultReportMeta: function() {
            // console.log('computedDefaultReportMeta')
            const itemMeta = ReportLookup.find(object => object.default === true)
            return itemMeta
        },


    },
    methods: {
        getReportMetaForReportAndSubreport: function(report, subreport) {
            // console.log('getReportUuidForReportAndSubreport')
            const itemMeta = ReportLookup.find(object => {
                return object.report === report && object.subreport === subreport
            })
            return itemMeta
        },
        getReportMetaPropertyForUuid: function(uuid, property) {
            // console.log('getReportMetaPropertyForUuid')
            const itemMeta = this.getReportMetaForUuid(uuid)
            return itemMeta[property]
        },

        getReportMetaForUuid: function(uuid) {
            // console.log('getReportMetaForUuid')
            const itemMeta = ReportLookup.find(object => object.uuid === uuid)
            return itemMeta
        },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
