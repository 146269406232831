<template>
  <!--  append-icon="mdi-magnify"-->
  <!--  append-icon="mdi-filter-outline"-->

  <v-text-field v-model="localValue"
                ref="BSFilterTextfield"
                :label="label"
                :append-icon="computedAppendIcon"
                clear-icon="mdi-close-circle"
                :outlined="outlined"
                single-line
                clearable
                hide-details
                filled
                v-bind="$attrs">


    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"/>
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"/>
    </template>

  </v-text-field>
</template>

<script>
  import { debounce } from 'lodash'

  export default {
    name: 'BSFilterTextfield',
    props: {
      value: {
        type: String,
        default: '',
      },
      debounce: {
        type: Boolean,
        default: false,
      },
      appendIcon: {
        type: String,
        default: 'mdi-magnify',
      },
      appendIconAlt: {
        type: String,
        default: 'mdi-magnify',
      },
      label: {
        type: String,
        default: 'Filter',
      },
      outlined: {
        type: Boolean,
        default: true,
      },

    },
    data() {
      return {
        localValue: '',
        touched: false,
      }
    },
    components: {},
    mixins: [],
    watch: {
      localValue(newVal) {
        // allows us to use v-model on our input.
        // Handles internal model changes.
        // this.$emit('input', newVal)

        this.touched = true
        if (!this.debounce || newVal === null) {
          this.touched = false
          this.$emit('input', newVal)
          this.$emit('update:value', newVal)
        } else {
          this.updateValue(newVal)
        }

      },
      value(newVal) {
        // Handles external model changes.
        this.localValue = newVal
      },
    },
    computed: {
      computedAppendIcon: function() {
        // console.log('computedAppendIcon')
        let retVal = this.appendIcon
        if (this.localValue) {
          retVal = this.appendIconAlt
        }
        return retVal
      },
    },
    methods: {
      updateValue: debounce(function(newVal) {
        this.touched = false
        this.$emit('input', newVal)
        this.$emit('update:value', newVal)
      }, 750),
    },
    beforeCreate() {},
    created() {
      if (this.value) {
        this.localValue = this.value
      }
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
