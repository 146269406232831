import Vue from 'vue'

import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import loadMap from 'highcharts/modules/map'
import loadAccessibility from 'highcharts/modules/accessibility'
import loadNoDataModule from 'highcharts/modules/no-data-to-display'

import { highchartsOptions } from '@/plugins/highcharts/options.js'

loadMap(Highcharts)
loadAccessibility(Highcharts)
loadNoDataModule(Highcharts)
Highcharts.setOptions(highchartsOptions)

Vue.use(HighchartsVue)
