// import axios from "axios"
// import router from "@/router"
// import vuex from "@/store/vuex"

// const MESSAGE_POLL_FREQUENCY = 60000

// this.$store.state['base/apiVersion']
const state = () => ({
    apiBaseUrl: process.env.VUE_APP_API_URL,
    apiVersion: 'v6',

    // marketplaceSuccessMessage: null,
})

// this.$store.getters['base/apiVersion']
const getters = {
    apiVersion: (state, getters, rootState, rootGetters) => {
        return state.apiVersion
    },
    baseURL: (state, getters, rootState, rootGetters) => {
        return state.apiBaseUrl + 'api' + '/' + state.apiVersion
    },
}

// $store.dispatch('base/authRedirect')
const actions = {}

// $store.commit('base/SET_JWT', response.data)
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
