import { keyBy, mapValues } from 'lodash'

export default {
    name: 'PermissionsMixin',
    data() {
        return {
            environment: process.env.NODE_ENV,
            tableItemsFilter: null,
            // roles: [],
            headerVar: 'headers',
            unexpectedBlocksRoles: [
                // 'FREE_MASTER',
                'CMP_READONLY',
                'CMP_HELPDESK',
                'CMP_ADMIN',
                'CMP_MASTER',
                // 'MSP_READONLY',
                // 'MSP_HELPDESK',
                // 'MSP_ADMIN',
                // 'MSP_MASTER',
                // 'RMS_READONLY',
                // 'RMS_HELPDESK',
                // 'RMS_ADMIN',
                // 'RMS_MASTER',
            ],
            writePermAccess: [
                // 'READONLY',
                // 'HELPDESK',
                'ADMIN',
                'MASTER',
            ],

            roles: [
                {
                    text: 'Free Master',
                    value: 'FREE_MASTER',
                    scope: 'FREE',
                    access: 'MASTER',
                },





                {
                    text: 'Company Read Only',
                    value: 'CMP_READONLY',
                    scope: 'CMP',
                    access: 'READONLY',
                },
                {
                    text: 'Company Help Desk',
                    value: 'CMP_HELPDESK',
                    scope: 'CMP',
                    access: 'HELPDESK',
                },
                {
                    text: 'Company Admin',
                    value: 'CMP_ADMIN',
                    scope: 'CMP',
                    access: 'ADMIN',
                },
                {
                    text: 'Company Master',
                    value: 'CMP_MASTER',
                    scope: 'CMP',
                    access: 'MASTER',
                },





                {
                    text: 'MSP Read Only',
                    value: 'MSP_READONLY',
                    scope: 'MSP',
                    access: 'READONLY',
                },
                {
                    text: 'MSP Help Desk',
                    value: 'MSP_HELPDESK',
                    scope: 'MSP',
                    access: 'HELPDESK',
                },
                {
                    text: 'MSP Admin',
                    value: 'MSP_ADMIN',
                    scope: 'MSP',
                    access: 'ADMIN',
                },
                {
                    text: 'MSP Master',
                    value: 'MSP_MASTER',
                    scope: 'MSP',
                    access: 'MASTER',
                },



                {
                    text: 'RMS Read Only',
                    value: 'RMS_READONLY',
                    scope: 'RMS',
                    access: 'READONLY',
                },
                {
                    text: 'RMS Help Desk',
                    value: 'RMS_HELPDESK',
                    scope: 'RMS',
                    access: 'HELPDESK',
                },
                {
                    text: 'RMS Admin',
                    value: 'RMS_ADMIN',
                    scope: 'RMS',
                    access: 'ADMIN',
                },
                {
                    text: 'RMS Master',
                    value: 'RMS_MASTER',
                    scope: 'RMS',
                    access: 'MASTER',
                },
            ],

            scopeWeightsDictionary: {
                'FREE': 100,
                'CMP': 200,
                'MSP': 300,
                'RMS': 400,
            },
            accessWeightsDictionary: {
                'READONLY': 100,
                'HELPDESK': 200,
                'ADMIN': 300,
                'MASTER': 400,
            },
        }
    },
    watch: {},
    computed: {
        userHasRoleForUnexpectedBlocks: function() {
            // console.log('userHasRoleForUnexpectedBlocks', this.unexpectedBlocksRoles)
            return this.unexpectedBlocksRoles.includes(this.userRole)
        },

        roleSelectItems: function() {
            console.log('roleSelectItems')
            let roles = this.roles
            roles = roles.map(role => {
                let weight = `${ this.scopeWeightsDictionary[role.scope] }${ this.accessWeightsDictionary[role.access] }`
                return {
                    ...role,
                    weight: Number(weight),
                }
            })
            return roles
        },
        roleObject: function() {
            console.log('roleObject')
            return keyBy(this.roleSelectItems, 'value')
        },
        roleDictionary: function() {
            console.log('roleDictionary')
            let retVal
            const roleObject = this.roleObject

            retVal = mapValues(roleObject, object => {
                return object.text
            })
            return retVal
        },
        roleArray: function() {
            console.log('roleArray')
            const roleObject = this.roleObject

            let retVal
            retVal = Object.keys(roleObject)
            return retVal
        },

        userCanEditSubscriptions: function() {
            // console.log('userCanEditSubscriptions')
            const permittedRoles = [
                // 'FREE_MASTER',
                // 'CMP_READONLY',
                // 'CMP_HELPDESK',
                'CMP_ADMIN',
                'CMP_MASTER',
                // 'MSP_READONLY',
                // 'MSP_HELPDESK',
                'MSP_ADMIN',
                'MSP_MASTER',
                // 'RMS_READONLY',
                // 'RMS_HELPDESK',
                // 'RMS_ADMIN',
                // 'RMS_MASTER',
            ]
            return (this.userCompanyIsManaged === false) && (this.userHasRequiredRole(permittedRoles) === true)
        },
        userCanWrite: function() {
            // console.log('userCanWrite')
            return this.writePermAccess.includes(this.userAccess)
        },
        computedHeadersForAccess: function() {
            // console.log('HeadersForAccess ==========================================')
            let headers = this[this.headerVar]
            headers = headers.filter(object => {
                // console.log('0 HeadersForAccess:', object.value)

                let retVal = true

                let hasRole = Object.hasOwn(object, 'role')
                let hasScope = Object.hasOwn(object, 'scope')
                let hasAccess = Object.hasOwn(object, 'access')

                if (hasRole || hasScope || hasAccess) {
                    if (hasRole) {
                        // console.log('1 HeadersForAccess role specified:', object.value, object.role, hasRole)
                        retVal = this.userHasRequiredRole(object.role)
                    } else if (hasScope || hasAccess) {
                        // console.log('2 HeadersForAccess scope specified:', object.value, object.scope, hasScope)
                        // console.log('2 HeadersForAccess access specified:', object.value, object.access, hasAccess)
                        if (hasScope && hasAccess) {
                            retVal = this.userHasRequiredScopeAndAccess(object.scope, object.access)
                        } else if (hasScope) {
                            retVal = this.userHasRequiredScope(object.scope)
                        } else if (hasAccess) {
                            retVal = this.userHasRequiredAccess(object.access)
                        }
                    }
                } else {
                    // header does not specify perms
                }
                return retVal
            })
            // console.log('========================================== HeadersForAccess')

            return headers
        },

        userIsFreemiumOrFreemiumPlus: function() {
            return this.userIsFreemium === true || this.userIsFreemiumPlus === true
        },
        userIsFreemium: function() {
            const mspUuid = this.userMspUuid

            const FREEMIUM_MSP_UUID = '00000000-0000-4000-8111-000000000000'
            // const FREEMIUM_PLUS_MSP_UUID = '00000000-0000-4000-8112-000000000000'
            // const FREEMIUM_MSP_UUID = '9e5ddc00-91ac-4930-a1f9-7e961931c1b8'
            // const FREEMIUM_PLUS_MSP_UUID = '04bec8b1-9e60-4615-8b43-41a0258f5b8d'

            return mspUuid === FREEMIUM_MSP_UUID
        },
        userIsFreemiumPlus: function() {
            const mspUuid = this.userMspUuid

            // const FREEMIUM_MSP_UUID = '00000000-0000-4000-8111-000000000000'
            const FREEMIUM_PLUS_MSP_UUID = '00000000-0000-4000-8112-000000000000'
            // const FREEMIUM_MSP_UUID = '9e5ddc00-91ac-4930-a1f9-7e961931c1b8'
            // const FREEMIUM_PLUS_MSP_UUID = '04bec8b1-9e60-4615-8b43-41a0258f5b8d'

            return mspUuid === FREEMIUM_PLUS_MSP_UUID
        },

        userMspUuid: function() {
            return this.userMsp.uuid
        },
        userMsp: function() {
            return this.computedJwt.claims.msp
        },

        // this.computedJwt.claims.company stuff
        // these shouldnt be user prefixed
        // should be prefixing stuff with claims
        userCompanyHasUnexpectedBlocks: function() {
            return this.userCompany.hasUnexpectedBlocks
            // return this.computedJwt.claims.company.hasUnexpectedBlocks
        },
        userCompanyHasExpandedPolicySettings: function() {
            return this.userCompany.hasExpandedPolicySettings
            // return this.computedJwt.claims.company.hasExpandedPolicySettings
        },
        userCompanyIsManaged: function() {
            return this.userCompany.isManaged
            // return this.computedJwt.claims.company.isManaged
        },
        userCompanyName: function() {
            return this.userCompany.name
        },
        userCompanyUuid: function() {
            return this.userCompany.uuid
        },
        userCompany: function() {
            return this.computedJwt.claims.company
        },

        userEmail: function() {
            return this.userName
        },
        userName: function() {
            return this.computedJwt.claims.user.name
        },
        userRole: function() {
            return this.computedJwt.claims.user.role
        },
        userScopeWeight: function() {
            return this.scopeWeightsDictionary[this.userScope]
        },
        userScope: function() {
            return this.computedJwt.claims.user.scope
        },
        userAccess: function() {
            return this.computedJwt.claims.user.access
        },
        computedJwt: function() {
            return this.$store.getters['auth/jwt']
        },
    },
    methods: {

        roleSelectItemsForScope: function(scope) {
            console.log('roleSelectItemsForScope scope', scope)
            return this.roleSelectItems.filter(role => role.scope === scope)
        },
        userHasRequiredRoleOrHigher: function(requiredRole) {
            // console.log('userHasRequiredAccessOrHigher requiredAccess', requiredAccess)
            const userRole = this.userRole
            const roleObject = this.roleObject
            return roleObject[userRole].weight >= roleObject[requiredRole].weight
        },
        userHasRequiredAccessOrHigher: function(requiredAccess) {
            // console.log('userHasRequiredAccessOrHigher requiredAccess', requiredAccess)
            const userAccess = this.userAccess
            const lookupDictionary = this.accessWeightsDictionary
            return lookupDictionary[userAccess] >= lookupDictionary[requiredAccess]
        },
        userHasRequiredScopeOrHigher: function(requiredScope) {
            const userScope = this.userScope
            const lookupDictionary = this.scopeWeightsDictionary
            return lookupDictionary[userScope] >= lookupDictionary[requiredScope]
        },
        userHasRequiredScopeForRole: function(role) {
            const requiredScope = this.scopeForRole(role)
            const userScope = this.userScope
            const lookupDictionary = this.scopeWeightsDictionary
            return lookupDictionary[userScope] > lookupDictionary[requiredScope]
        },

        userHasRequiredRole: function(requiredRolesArray) {
            return requiredRolesArray.includes(this.userRole)
        },
        userHasRequiredScope: function(requiredScopesArray) {
            return requiredScopesArray.includes(this.userScope)
        },
        userHasRequiredAccess: function(requiredAccessArray) {
            return requiredAccessArray.includes(this.userAccess)
        },
        userHasRequiredScopeAndAccess: function(requiredScopesArray, requiredAccessArray) {
            return this.userHasRequiredScope(requiredScopesArray) && this.userHasRequiredAccess(requiredAccessArray)
        },

        weightForScope: function(scope) {
            return this.scopeWeightsDictionary[scope]
        },

        scopeForRole: function(role) {
            // console.log('scopeForRole role', role)
            return role.split('_')[0]
        },
        accessForRole: function(role) {
            return role.split('_')[1]
        },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        // this.getRoles()
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},

}
