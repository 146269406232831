<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {}
  }
}
</script>

<style lang="scss">
  @import './sass/app';
</style>
